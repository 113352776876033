<template>
  <b-popover :show="show"
             ref="popover"
             :target="target"
             triggers="click"
             placement="top"
             :custom-class="`ta-popover-confirmation ${customClass}`"
             :container="container"
             @show="onShow"
             @hide="onHide">
    <template v-slot:title>
      {{ $t('Please Confirm') }}
      <button class="btn btn-sm btn-link ta-btn-close" @click="onClose">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </template>
    <p class="ta-confirmation-message">
      {{ $t('Are you sure you want to place a bid') }}?
    </p>

    <div class="ta-confirmation-lot-details">
      <div v-if="lot" class="row mb-3 align-items-center">
        <div class="col-sm-3">
          <b-img v-if="lot.image_urls.length" :src="lot.image_urls[0]"
                 :alt="$options.filters.stripTags($options.filters.currentLocale(lot.title))"
                 fluid
                 thumbnail/>
          <b-img v-else src="https://cdn.artisio.co/no_image.jpg" fluid thumbnail/>
        </div>
        <h5 class="col-sm-9" v-html="$options.filters.stripTags($options.filters.currentLocale(lot.title))">
        </h5>
      </div>
    </div>

    <tax-rate-and-premiums :lot="lot"/>

    <template v-if="mainConfig.showBidAmountsInConfirmation">
      <BidAmount :lot="lot" :bid-amount="bidAmount"/>
      <CalculatedPremium :lot="lot" :bid-amount="bidAmount"/>
      <BidTotalAmount :lot="lot" :bid-amount="bidAmount"/>
    </template>

    <p class="ta-bid-dont-ask-me-checkbox">
      <b-form-checkbox v-model="checked" :value="true" :unchecked-value="false">
        {{ $t("Understood! Don't ask me this again on this item") }}
      </b-form-checkbox>
    </p>
    <div class="ta-bid-confirmation-popover-footer">
      <button class="btn btn-sm btn-primary" @click="confirm">
        {{ $t('Yes') }}
      </button>
      <button class="btn btn-sm btn-danger ml-2" @click="onNoClick">
        {{ $t('No') }}
      </button>
    </div>
  </b-popover>
</template>

<script>
import TaxRateAndPremiums from "@/components/TaxRateAndPremiums";
import CalculatedPremium from "@/components/lot-view/CalculatedPremium.vue";
import BidAmount from "@/components/lot-view/BidAmount.vue";
import BidTotalAmount from "@/components/lot-view/BidTotalAmount.vue";
import {mapState} from "vuex";

export default {
  name: "BidConfirmation",
  components: {BidTotalAmount, BidAmount, CalculatedPremium, TaxRateAndPremiums},
  props: {
    show: Boolean,
    target: String,
    customClass: String,
    bidAmount: Number,
    lot: Object,
    forMobile: {
      type: Boolean,
      default() {
        return false
      }
    },
    container: {
      type: String,
      default() {
        return 'lotViewBidding'
      }
    }
  },
  emits: [],
  data() {
    return {
      clicked: false,
      checked: false,
      parentEl: null,
      targetEl: null,
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    confirm() {
      if (this.clicked) {
        return;
      }
      this.clicked = true;
      setTimeout(() => {
        this.clicked = false;
      }, 500)
      this.hide();
      this.$emit('confirm', this.checked);
    },
    onClose() {
      this.hide();
      this.$emit('close')
    },
    onNoClick() {
      this.hide()
      this.$emit('no')
    },
    hide() {
      this.deleteOverlay();
      this.$emit('update:show', false)
    },
    // calculatePopoverBottom() {
    //   if (!this.parentEl) {
    //     return 'auto';
    //   }
    //   const parentHeight = this.parentEl.offsetHeight;
    //   const parentPos = this.parentEl.getBoundingClientRect();
    //   const targetPos = this.targetEl.getBoundingClientRect();
    //   return `${parentHeight - (targetPos.top - parentPos.top) + 5}px`
    // },
    onShow() {
      this.$emit('update:show', true)
      this.deleteOverlay();
      this.createOverlay();
      // this.$nextTick(() => {
      //   this.updatePopoverPosition()
      // })
    },
    onHide() {
      this.deleteOverlay();
    },
    createOverlay() {
      const div = document.createElement('div');
      div.className = 'ta-popover-overlay';
      div.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      div.style.position = 'fixed';
      div.style.left = '0';
      div.style.top = '0';
      div.style.right = '0';
      div.style.bottom = '0';
      div.style.zIndex = 1100;
      if (this.forMobile) {
        document.querySelector('#placeBidModal').appendChild(div)
      } else {
        window.document.body.appendChild(div);
      }
      window.document.body.classList.add('artisio-webapp-has-popover-backdrop')
    },
    deleteOverlay() {
      const div = document.querySelector('.ta-popover-overlay');
      if (div) {
        div.remove()
      }

      window.document.body.classList.remove('artisio-webapp-has-popover-backdrop')
    },
    // updatePopoverPosition() {
    //   const popover = document.querySelector('.ta-popover-confirmation')
    //   if (!popover) return;
    //   popover.style.bottom = this.calculatePopoverBottom()
    // }
  },
  // mounted() {
  //   this.targetEl = document.getElementById(this.target);
  //   this.parentEl = document.getElementById(this.container);
  //   window.addEventListener('scroll', this.updatePopoverPosition)
  //   window.addEventListener('resize', this.updatePopoverPosition)
  // },
  // beforeDestroy() {
  //   window.removeEventListener('scroll', this.updatePopoverPosition)
  //   window.removeEventListener('resize', this.updatePopoverPosition)
  // }
}
</script>

<style scoped lang="scss">
@import "../../bootstrap/import";
@import "../../scss/mixins";

#artisioTimedAuctions {
  .ta-bid-confirmation-popover-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    //padding: 1rem;
  }

  .ta-popover-confirmation {
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    max-width: 420px;
    //top: auto !important;
    //transform: none !important;
    z-index: 1150;

    /deep/ .popover-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    /deep/ .popover-body {
      padding: 0.75rem;
    }

    .ta-confirmation-message {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }

    .ta-buyer-premium-terms-wrapper {
      padding: 0.25rem 0.5rem;
      border-radius: 0;
    }

    .ta-btn-close {
      @include btn-close();

      position: absolute;
      right: 1rem;
    }
  }

  .ta-bid-dont-ask-me-checkbox {
    margin-top: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    .popover.b-popover.ta-popover-confirmation {
      display: flex;
      flex-direction: column;
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      position: fixed !important;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      transform: none !important;

      /deep/ .popover-body {
        flex: 1;
        padding: 1rem;
        overflow: auto;
      }
    }
  }
}
</style>

<style lang="scss">
  //body.artisio-webapp-has-popover-backdrop {
  //  overflow: hidden;
  //}

  //.artisio-webapp-page-lot-view {
  //  #artisioTimedAuctions {
  //    .ta-popover-confirmation {
  //      top: auto !important;
  //      transform: none !important;
  //    }
  //  }
  //}
</style>
