<template>
  <small class="ta-auction-no">
    {{ auction.auction_no }}
  </small>
</template>

<script>
export default {
  name: "AuctionNo",
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import.scss";

#artisioTimedAuctions {
  small.ta-auction-no {
    display: block;
    white-space: nowrap;
    font-weight: bold;
    color: $primary;
    margin-bottom: 0.25rem;
  }
}
</style>
