<template>
  <div
    v-if="!mainConfig.descriptionDynamicFields"
    class="ta-lot-description"
  >
    <div v-if="mainConfig.showMoreInDescription">
      <b-collapse id="description-collapse" class="mt-2">
        <div v-html="$options.filters.currentLocale(lot.description)"></div>
      </b-collapse>
      <div class="text-right">
        <a href="javascript:void(0)" v-b-toggle.description-collapse>
          <span class="ta-when-collapse-open">{{ $t('Read less') }}</span>
          <span class="ta-when-collapse-closed">{{ $t('Read more') }}</span>
        </a>
      </div>
    </div>
    <div v-else v-html="$options.filters.currentLocale(lot.description)"></div>
  </div>
  <div v-else>
    <template v-for="f of dynamicFields">
      <div v-if="f.value" :key="f.field" class="dynamic-field-item">
        <h5 class="dynamic-field-label">
          {{ $t(`dynamic_field.${f.field}`) }}
        </h5>
        <div class="dynamic-field-value" v-html="getFormattedValueForDynamicField(f.field, f.value)">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import i18n from '../../../i18n';
import CONFIG from "../../../user-config";
import {mapState} from "vuex";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";

export default {
  name: 'LotViewDescription',
  mixins: [dynamicFieldsMixin],
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    dynamicFields() {
      let dynamicFields = CONFIG.descriptionDynamicFields;
      if (dynamicFields === false) {
        return [];
      }
      if (!this.lot.dynamic_fields) {
        return [];
      }
      const fieldsObj = this.lot.dynamic_fields[i18n.locale] || this.lot.dynamic_fields[i18n.fallbackLocale];
      if (dynamicFields === true) {
        dynamicFields = Object.keys(fieldsObj);
      }
      const returnFields = [];
      dynamicFields.forEach((field) => {
        if (fieldsObj[field] !== undefined) {
          returnFields.push({
            field,
            value: fieldsObj[field]
          })
        }
      })

      return returnFields;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../bootstrap/import";

/deep/ .dynamic-field-item {
  margin-bottom: 1.5rem;
}

#artisioTimedAuctions {
  .ta-lot-description {
    /deep/ {
      h1, h2, h3, h4 {
        margin-top: 2rem;
        color: $primary
      }

      table {
        width: 100%;
        background-color: $gray-100;

        > tbody {
          > tr {
            > td {
              padding: 0.25rem 0.5rem;
              border: 1px solid $gray-300
            }
          }
        }
      }

      .artisio-youtube-video {
        position: relative;
        width: 100%;
        margin-top: 2rem;
        padding-top: 54%;

        iframe {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
        }
      }
    }
  }

  #description-collapse {
    display: block !important;
    overflow: hidden;
    min-height: 100px;
    &.collapse {
      height: 100px;
    }
    &.show {
      height: auto;
    }
  }

  .collapsed > .ta-when-collapse-open,
  .not-collapsed > .ta-when-collapse-closed {
    display: none;
  }
}
</style>
