<template>
  <div class="ta-auction-bidding-open" v-if="mainConfig.showAuctionBiddingOpenBadge && started && auction.status !== 'completed'">
    <span class="ta-auction-bidding-open-badge badge badge-danger">
      {{ label }}
    </span>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import moment from "moment";
  import {getCurrentServerTime} from "@/helpers";

  export default {
    name: "AuctionBiddingOpen",
    data() {
      return {
        interval: null,
        finished: false,
        started: false,
      }
    },
    props: {
      auction: {
        required: true,
        type: Object
      },
      label: {
        type: String,
        default() {
          return this.$t('Bidding is Open')
        }
      },
    },
    computed: {
      ...mapState(['timeDiff', 'mainConfig']),
      startOfEventTime() {
        let startDate = this.auction.start_date;

        if (!startDate) {
          return null
        }

        let startEventTime;
        if (typeof startDate === "string") {
          startEventTime = moment(startDate).toDate().getTime()
        } else {
          startEventTime = startDate.getTime();
        }

        return moment(startEventTime).utc();
      },
      endOfEventTime() {
        let endDate = this.auction.end_date

        if (!endDate) {
          return null;
        }

        let endEventTime;
        if (typeof endDate === "string") {
          endEventTime = moment(endDate).toDate().getTime()
        } else {
          endEventTime = endDate.getTime();
        }

        return moment(endEventTime).utc();
      },
    },
    methods: {
      runner() {
        const currentTime = getCurrentServerTime()
        this.started = this.startOfEventTime <= currentTime;
        if (this.endOfEventTime && currentTime > this.endOfEventTime) {
          clearInterval(this.interval);
          this.finished = true;
        }
      },
      init() {
        if (!this.auction.start_date || !this.auction.end_date) {
          return;
        }

        this.runner();
        this.interval = setInterval(() => {
          this.runner();
        }, 1000);
      }
    },
    mounted() {
      this.init()
    },
    destroyed() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
  }
</script>

<style lang="scss" scoped>
.ta-auction-bidding-open {
  margin-bottom: 0.5rem;
}
</style>
