<template>
  <div v-if="mainConfig.enableSorting" class="ta-sorting-dropdown">
    <label>{{ $t('Sort By') }} </label>
    <b-form-select
      :options="sortOptions"
      v-model="sort"
      @change="updateSorting"
    ></b-form-select>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import lotListFilterMixin from "../../../mixins/lotListFilterMixin";
import {lotSortingChange} from "@/triggers";

export default {
  name: "SortingDropdown",
  mixins: [lotListFilterMixin],
  data: () => ({
    sort: 'end_date=desc',
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['dynamicFields']),
    sortOptions() {
      return [
        {text: this.$t('Most Visited/Viewed'), value: 'visited=desc'},
        {text: this.$t('Newest (Published)'), value: 'publish_date=desc'},
        {text: this.$t('Lowest Price'), value: 'price=asc'},
        {text: this.$t('Highest Price'), value: 'price=desc'},
        {text: this.$t('Highest Number of Bids'), value: 'bid_count=desc'},
        {text: this.$t('Least time Left'), value: 'end_date=asc'},
        {text: this.$t('Lowest Lot Number'), value: 'lot_no=asc'},
        {text: this.$t('Highest Lot Number'), value: 'lot_no=desc'},
        ...this.dynamicFields.filter(f => f.sortable).map(f => ({
          text: `${f.label} ASC`,
          value: `dynamic_fields-${f.key}=asc`
        })),
        ...this.dynamicFields.filter(f => f.sortable).map(f => ({
          text: `${f.label} DESC`,
          value: `dynamic_fields-${f.key}=desc`
        }))
      ].sort((a, b) => a.text < b.text ? -1 : 1)
    }
  },
  methods: {
    updateSorting() {
      let [sort, sortBy] = this.sort.split('=')
      this.fields.setFilters({
        sort,
        'sort-by': sortBy
      });
      this.fields.updateRoute();
      lotSortingChange(`${sort} ${sortBy}`)
    },
  },

  mounted() {
    const sort = this.fields.queryParams.sort;
    const sortBy = this.fields.queryParams['sort-by'];
    if (sort && sortBy) {
      this.sort = `${sort}=${sortBy}`;
    }
  }
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions {
  .ta-sorting-dropdown {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    label {
      margin-right: 0.5rem;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}
</style>
