import lotBiddingMixin from "./lotBiddingMixin";
import {mapState, mapMutations} from "vuex";
import {setMaxBidClick} from "@/triggers";
import lotHelperMixin from "@/mixins/lotHelperMixin";
import lotListItemBiddingMixin from "@/mixins/lotListItemBiddingMixin";
import i18n from "@/i18n";

export default {
  mixins: [lotListItemBiddingMixin, lotHelperMixin],
  computed: {
    ...mapState({
      upcomingMaxBidPopover: state => state.lotObject.upcomingMaxBidPopover,
    }),
  },
  methods: {
    ...mapMutations(['setShowMaxBidModal', 'showModalMsgBox']),
    showMaxBidModal() {
      if (this.lot.auction.is_paused) {
        this.showModalMsgBox({
          title: i18n.t('The auction is paused!'),
          content: i18n.t('The auction is paused. Please try again in a few minutes.')
        })
        return
      }

      setMaxBidClick(this.title)
      if (this.biddingFinished) {
        return;
      }
      if (this.is_authorized) {
        this.showModal = true
      } else {
        this.show401Notification();
      }
    },
  }
}
