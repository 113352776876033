<template>
  <div v-if="lot && lot.auction && mainConfig.enableBidding && (!(isLotSold || isLotUnsold) || isAfterSale)" class="ta-lot-bid-input-wrapper">
    <div v-if="lot.auction.type === 'timed'">
      <offline-message />
      <div class="ta-lot-bid-input">
        <div v-if="biddingFinished && lot.status === 'published'" class="ta-bidding-please-wait">
          <b-spinner small class="mb-2" label="Spinning"></b-spinner>
          <div>{{ $t('Please wait...') }}</div>
        </div>
        <offline-message-overlay />
        <register-as-bidder-button :auction="lot.auction" :block="true" />
        <slot></slot>
      </div>
    </div>
    <div v-else-if="lot.auction.type === 'live'">
      <register-as-bidder-button :auction="lot.auction" :block="true" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";
import RegisterAsBidderButton from "@/views/AuctionViewPage/RegisterAsBidderButton.vue";
import OfflineMessage from "@/components/OfflineMessage.vue";
import OfflineMessageOverlay from "@/components/OfflineMessageOverlay.vue";

export default {
  name: "BiddingActions",
  components: {OfflineMessageOverlay, OfflineMessage, RegisterAsBidderButton},
  mixins: [lotBiddingMixin],
  computed: {
    ...mapState(['mainConfig', 'socket']),
  },

}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-bidding-please-wait {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;

  .spinner-border-sm {
    min-height: 20px;
    min-width: 20px;
  }
}

.ta-lot-bid-input-wrapper {
  margin-top: 0.5rem;
}

.ta-lot-bid-input {
  position: relative;
}

</style>
