<script>
import templatable from "@/mixins/templatable";
import {mapMutations, mapState} from "vuex";
import userConfig from "@/user-config";
import {updateLotsRoute} from "@/helpers";

export default {
  name: "LotFilterList",
  mixins: [templatable('lotFiltersTemplate')],
  data: () => ({
    template: `
        <div>
            [[template]]
        </div>
    `
  }),
  computed: {
    ...mapState(['lotQueryParams', 'departments', 'mainConfig'])
  },
  watch: {
    'departments.data'() {
      this.selectFirstDepartment();
    }
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    selectFirstDepartment() {
      const model = this.lotQueryParams.department || [];

      if (!model.length) {
        if (this.mainConfig.alwaysSelectFirstDepartment && this.departments.data.length > 0) {
          this.setLotFilters({
            department: this.departments.data[0].key,
          });
          if (userConfig.autoApplyFilters) {
            updateLotsRoute();
          }
        }
      }
    }
  },
  mounted() {
    this.selectFirstDepartment()
  }
}
</script>

<style scoped>

</style>
