<template>
  <div v-if="mainConfig.enableWatchlist" :id="`add-to-watchlist-${lot.uuid}`" v-b-tooltip.hover class="ta-add-to-watchlist">
    <button
      :disabled="buttonDisabled"
      @click="_toggleWatchlist"
      class="btn btn-link ta-btn-watchlist">
      <b-icon v-if="!lot.is_favored" icon="heart"></b-icon>
      <b-icon v-else icon="heart-fill"></b-icon>
    </button>
    <b-tooltip v-if="!is_authorized" container="artisioTimedAuctions" :target="`add-to-watchlist-${lot.uuid}`">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "WatchlistButton",
  props: ['lot'],
  data: () => ({
    buttonDisabled: false
  }),
  computed: {
    ...mapState(['is_authorized', 'mainConfig'])
  },
  methods: {
    ...mapActions(['addToFavourites']),
    async _toggleWatchlist() {
      this.buttonDisabled = true
      await this.addToFavourites()
      this.buttonDisabled = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .ta-add-to-watchlist {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
</style>
