<script>
import {mapGetters} from "vuex";
import auctionViewMixin from "@/mixins/auctionViewMixin";
import CountDownTimer from "@/components/CountDownTimer";
import AuctionCountdownTimer from "@/views/AuctionListPage/list-item/AuctionCountdownTimer.vue";
import AuctionCover from "@/views/AuctionViewPage/AuctionCover.vue";
import AuctionTitle from "@/views/AuctionViewPage/AuctionTitle.vue";
import AuctionNo from "@/views/AuctionViewPage/AuctionNo.vue";
import AuctionEndDate from "@/views/AuctionViewPage/AuctionEndDate.vue";
import AuctionDescription from "@/views/AuctionViewPage/AuctionDescription.vue";
import AuctionViewingDates from "@/views/AuctionViewPage/AuctionViewingDates.vue";
import AuctionContactDetails from "@/views/AuctionViewPage/AuctionContactDetails.vue";
import AuctionCatalogLink from "@/views/AuctionViewPage/AuctionCatalogLink.vue";
import AuctionCurrency from "@/views/AuctionViewPage/AuctionCurrency.vue";
import AuctionNumberOfLots from "@/views/AuctionViewPage/AuctionNumberOfLots.vue";
import AuctionCountDown from "@/views/AuctionViewPage/AuctionCountDown.vue";
import AuctionTermsLink from "@/views/AuctionViewPage/AuctionTermsLink.vue";
import AuctionViewType from "@/views/AuctionViewPage/AuctionViewType.vue";
import AuctionDates from "@/views/AuctionViewPage/AuctionDates.vue";
import AuctionStartDate from "@/views/AuctionViewPage/AuctionStartDate.vue";
import AuctionLocation from "@/views/AuctionViewPage/AuctionLocation.vue";
import AuctionIncrementsLink from "@/views/AuctionViewPage/AuctionIncrementsLink.vue";
import AuctionBuyersPremiumLink from "@/views/AuctionViewPage/AuctionBuyersPremiumLink.vue";
import AuctionViewingInfo from "@/views/AuctionViewPage/AuctionViewingInfo.vue";
import AuctionRegisterAsBidder from "@/views/AuctionViewPage/AuctionRegisterAsBidder.vue";
import AuctionActiveDate from "@/views/AuctionViewPage/AuctionActiveDate.vue";
import AuctionStreamBid from "@/views/AuctionViewPage/AuctionStreamBid.vue";
import templatable from "@/mixins/templatable";
import LoginSignupButtons from "@/components/lot-view/sidebar/LoginSignupButtons.vue";
import LoginButton from "@/components/lot-view/sidebar/LoginButton.vue";
import SignupButton from "@/components/lot-view/sidebar/SignupButton.vue";
import AuctionPremium from "@/views/AuctionViewPage/AuctionPremium.vue";
import BuyersPremiumTaxRate from "@/views/AuctionViewPage/BuyersPremiumTaxRate.vue";
import HammerTaxRate from "@/views/AuctionViewPage/HammerTaxRate.vue";
import AuctionImportantInformationLink from "@/views/AuctionViewPage/AuctionImportantInformationLink.vue";
import AuctionLocationInformationLink from "@/views/AuctionViewPage/AuctionLocationInformationLink.vue";
import AuctionPaymentInformationLink from "@/views/AuctionViewPage/AuctionPaymentInformationLink.vue";
import AuctionViewingInformationLink from "@/views/AuctionViewPage/AuctionViewingInformationLink.vue";
import AuctionCollectionInformationLink from "@/views/AuctionViewPage/AuctionCollectionInformationLink.vue";

export default {
  name: "AuctionInfo",
  components: {
    AuctionStreamBid,
    AuctionActiveDate,
    AuctionRegisterAsBidder,
    AuctionViewingInfo,
    AuctionBuyersPremiumLink,
    AuctionIncrementsLink,
    AuctionLocation,
    AuctionStartDate,
    AuctionDates,
    AuctionTermsLink,
    AuctionImportantInformationLink,
    AuctionLocationInformationLink,
    AuctionPaymentInformationLink,
    AuctionViewingInformationLink,
    AuctionCollectionInformationLink,
    AuctionViewType,
    AuctionNumberOfLots,
    AuctionCountDown,
    AuctionCurrency,
    AuctionCatalogLink,
    AuctionContactDetails,
    AuctionViewingDates,
    AuctionEndDate,
    AuctionTitle,
    AuctionNo,
    AuctionCover,
    AuctionCountdownTimer, // @Deprecated
    AuctionDescription,
    CountDownTimer, // @Deprecated
    LoginSignupButtons,
    LoginButton,
    SignupButton,
    AuctionPremium,
    BuyersPremiumTaxRate,
    HammerTaxRate
  },
  mixins: [auctionViewMixin, templatable('auctionDetailsTemplate')],
  data: () => ({
    template: `
      [[template]]
    `,
  }),
  computed: {
    ...mapGetters(["is_authorized"]),
  }
}
</script>

<style scoped>

</style>
