<template>
  <div v-if="lot.currency && lot.start_price" class="ta-lot-start-price">
    <label class="ta-lot-start-price-label">{{ $t('Start Price') }}</label>
    <span class="ta-lot-start-price-value">
      {{ lot.start_price | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BiddingStartPrice",
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style lang="scss" scoped>
  .ta-lot-start-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
