import { render, staticRenderFns } from "./IsCompany.vue?vue&type=template&id=6ce15071&scoped=true&"
import script from "./IsCompany.vue?vue&type=script&lang=js&"
export * from "./IsCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce15071",
  null
  
)

export default component.exports