<template>
  <lot-filter v-if="mainConfig.enableFilters"
              @menuClose="onClose" @clearFilters="clearFilters" @applyFilters="applyFilters"/>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import LotFilter from "../../lot-list/LotFilter";
import {removeOverlay} from "@/helpers";

export default {
  name: "LotListFilters",
  components: {LotFilter},
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    ...mapMutations(['setFiltersMenuOpenMobile']),
    onClose() {
      removeOverlay('.ta-filters-overlay', 'artisio-webapp-has-filters-backdrop')
      this.setFiltersMenuOpenMobile(false)
    },
    clearFilters() {
      removeOverlay('.ta-filters-overlay', 'artisio-webapp-has-filters-backdrop')
    },
    applyFilters() {
      removeOverlay('.ta-filters-overlay', 'artisio-webapp-has-filters-backdrop')
    }
  }
}
</script>

<style scoped>

</style>
