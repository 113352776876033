<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      id="input-phone"
      v-model="model"
      :error="errors"
      type="phone"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="required ? 'required|min:10' : 'min:10'"
    />
  </div>
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "Phone",
  components: {InputWidget, AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseInputMixin('phone')],
  props: {
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultLabel: this.$t("Phone")
    }
  }
}
</script>

<style scoped>

</style>
