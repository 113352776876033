<template>
  <div class="ta-auction-view-countdown">
    <AuctionCountdownTimer :auction="auction" :label="label" />
  </div>
</template>

<script>
import CountDownTimer from "@/components/CountDownTimer.vue";
import auctionViewMixin from "@/mixins/auctionViewMixin";
import AuctionCountdownTimer from "@/views/AuctionListPage/list-item/AuctionCountdownTimer.vue";

export default {
  name: "AuctionCountDown",
  mixins: [auctionViewMixin],
  props: {
    label: {
      type: [Boolean],
      default: true
    }
  },
  components: {AuctionCountdownTimer, CountDownTimer}
}
</script>

<style lang="scss" scoped>
.ta-auction-view-countdown {
  margin-bottom: 1rem;
}
</style>
