<template>
  <div v-if="locationInfo" class="ta-auction-location-information" v-html="locationInfo"></div>
</template>

<script>
import auctionBaseMixin from "@/mixins/auctionBaseMixin";

export default {
  name: "AuctionLocationInformation",
  mixins: [auctionBaseMixin],
  props: {
    auction: Object
  }
}
</script>

<style lang="scss" scoped>

</style>
