<template>
  <ModalLinkButton :title="$t('Location Information')" :content="locationInfo"
                   class="ta-auction-location-information-link"/>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";
import ModalLinkButton from "@/components/ModalLinkButton.vue";

export default {
  name: "AuctionLocationInformationLink",
  components: {ModalLinkButton},
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
</style>
