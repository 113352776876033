<template>
  <div class="row" v-if="category_uuid">
    <template v-for="dynamicField in dynamicFields">
      <div
        :class="
          dynamicField.css_class
            ? dynamicField.css_class
            : {
                'col-6': dynamicField.type !== 'ckeditor',
                'col-12': dynamicField.type === 'ckeditor',
              }
        "
        :key="dynamicField.name"
      >
        <input-widget
          :hint="dynamicField.hint"
          :type="typeMap[dynamicField.type]"
          :label="$t(dynamicField.label)"
          :id="'idSellAnItem' + dynamicField.name"
          :rules="getRules(dynamicField)"
          :options="getOptions(dynamicField)"
          :error="getErrors(dynamicField)"
          :step="getStep(dynamicField)"
          :config="typeMap[dynamicField.type] === 'number' ? getNumberConfig(dynamicField) : {}"
          v-model="model[dynamicField.name]"
          @change="onChange(dynamicField, $event)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import InputWidget from "@/components/core/InputWidget.vue";
import {component as VueNumber} from '@coders-tm/vue-number-format'

export default {
  name: "DynamicFieldInputs",
  components: {InputWidget, VueNumber},
  data: () => ({
    model: {},
    typeMap: {
      text: "text",
      date: "date",
      number: "number",
      vuenumberformat: "number",
      select2: "select",
      ckeditor: "textarea",
      checkbox: "checkbox",
    },
  }),
  computed: {
    ...mapState({
      category_uuid: (state) => state.sellAnItemModal.model.category_uuid,
      department_uuid: (state) => state.sellAnItemModal.model.department_uuid,
      sellAnItemModal: (state) => state.sellAnItemModal,
      dynamicFieldsMap: (state) => state.dynamicFieldsMap,
    }),

    dynamicFields() {
      return (this.dynamicFieldsMap[this.category_uuid] || []).filter(
        (f) => !f.hide_on_website
      );
    },
  },
  watch: {
    "sellAnItemModal.flag": {
      handler() {
        this.model = {
          ...this.sellAnItemModal.model.dynamicFields,
        };
        // for (let key in this.sellAnItemModal.model) {
        //   if (key.indexOf('dynamic_fields-') === 0) {
        //     this.model[key] = this.sellAnItemModal.model[key] || "";
        //   }
        // }
      },
    },
  },
  methods: {
    ...mapMutations(["setSellAnItemModalFieldInput"]),
    getRules(dynamicField) {
      let rules = [];
      for (let i in dynamicField.rules) {
        if (i === "required" && dynamicField.rules[i]) {
          rules.push("required");
        } else if (i === "min_value" || i === "max_value") {
          rules.push(`${i}:${dynamicField.rules[i]}`);
        } else if (i === "min_length" || i === "max_length") {
          rules.push(
            `${i === "min_length" ? "min" : "max"}:${dynamicField.rules[i]}`
          );
        } else if (i === "max_digits") {
          const maxNumber = Math.pow(10, dynamicField.rules[i] - (dynamicField.rules.decimal_places ? dynamicField.rules.decimal_places : 0))
          rules.push(
            `max:${maxNumber}`
          );
        } else if (i === "choices") {
          rules.push(
            "oneOf:" + dynamicField.rules[i].map(({value}) => value).join(",")
          );
        }
      }
      if (dynamicField.field_class_name === 'IntegerField') {
        rules.push('integer');
      }
      return rules.join("|");
    },
    getOptions(dynamicField) {
      if (dynamicField.type !== "select2") {
        return [];
      }
      return [
        {value: "", text: this.$t("Please Select")},
        ...dynamicField.rules.choices.map((choice) => {
          return {
            value: choice.value,
            text:
              typeof choice.key === "object"
                ? this.$options.filters.currentLocale(choice.key)
                : choice.key,
          };
        }),
      ];
    },
    getNumberConfig(dynamicField){
      return {
        prefix: dynamicField.prefix || '',
        suffix : dynamicField.suffix || '',
      }
    },
    getStep(dynamicField) {
      if (dynamicField.field_class_name === 'IntegerField') {
        return 1;
      } else if (dynamicField.field_class_name === 'DecimalField') {
        return Math.pow(dynamicField.rules.decimal_places ? dynamicField.rules.decimal_places : 0);
      }
      return null;
    },
    getErrors(dynamicField) {
      return this.sellAnItemModal.errors &&
      this.sellAnItemModal.errors.dynamic_fields &&
      this.sellAnItemModal.errors.dynamic_fields[dynamicField.name]
        ? this.sellAnItemModal.errors.dynamic_fields[dynamicField.name]
        : null;
    },
    onChange(field, val) {
      this.setSellAnItemModalFieldInput({
        field: "dynamicFields",
        value: this.model,
      });
    },
  },
};
</script>

<style scoped>
</style>
