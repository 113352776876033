<template>
  <div>
    <div v-if="mainConfig.enableWatchlist" :id="auction.uuid" v-b-tooltip.hover>
      <b-button
        :disabled="!is_authorized || buttonDisabled"
        variant="link"
        @click="_toggleWatchlist" class="ta-btn-watchlist"
      >
        <b-icon v-if="!auction.is_favored" icon="heart"></b-icon>
        <b-icon v-else icon="heart-fill"></b-icon>
      </b-button>
    </div>
    <b-tooltip v-if="!is_authorized" :target="auction.uuid" container="artisioTimedAuctions">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "AuctionAddToWatchlist",
  props: {
    auction: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    buttonDisabled: false
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized']),
  },
  methods: {
    ...mapActions(["toggleWatchlist"]),
    async _toggleWatchlist() {
      this.buttonDisabled = true
      await this.toggleWatchlist({
        entity_name: 'auction',
        uuid: this.auction.uuid,
        is_favored: this.auction.is_favored,
      })
      this.buttonDisabled = false;
    }
  }
}
</script>

<style scoped>

</style>
