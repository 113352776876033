import moment from "moment";
import {
  AUCTION_DEFAULT_SORT_BY,
  AUCTION_DEFAULT_SORT_FIELD,
  AUCTION_DEFAULT_STATUS,
  AUCTION_LIST_DEFAULT_LIMIT,
  AUCTION_LIST_DEFAULT_PAGE,
  FAVORITE_AUCTION_DEFAULT_SORT_BY,
  FAVORITE_AUCTION_DEFAULT_SORT_FIELD,
  FAVORITE_AUCTION_DEFAULT_STATUS,
  FAVORITE_AUCTION_LIST_DEFAULT_LIMIT,
  FAVORITE_AUCTION_LIST_DEFAULT_PAGE,
  FAVORITE_LOT_DEFAULT_SORT_BY,
  FAVORITE_LOT_DEFAULT_SORT_FIELD,
  FAVORITE_LOT_LIST_DEFAULT_LIMIT,
  FAVORITE_LOT_LIST_DEFAULT_PAGE, INCREMENT_CALCULATION_SOURCE_BID, INCREMENT_CALCULATION_SOURCE_RESERVE,
  LOT_DEFAULT_SORT_BY,
  LOT_DEFAULT_SORT_FIELD,
  LOT_DEFAULT_STATUS,
  LOT_LIST_DEFAULT_LIMIT,
  LOT_LIST_DEFAULT_PAGE,
  REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY
} from "./constants";
import router from "./router";
import store from "./store";
import {auctionChangeFilters, lotChangeFilters} from "@/triggers";
import CONFIG from "@/user-config";
import logger from "@/logger";

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function assemble_filter(params) {
  params.filters["start_date_from"] = params.startDateRange.startDate
    ? moment(params.startDateRange.startDate).format("YYYY-MM-DD")
    : null;
  params.filters["start_date_to"] = params.startDateRange.endDate
    ? moment(params.startDateRange.endDate).format("YYYY-MM-DD")
    : null;

  params.filters["end_date_from"] = params.endDateRange.startDate
    ? moment(params.endDateRange.startDate).format("YYYY-MM-DD")
    : null;
  params.filters["end_date_to"] = params.endDateRange.endDate
    ? moment(params.endDateRange.endDate).format("YYYY-MM-DD")
    : null;

  return params.filters;
}

export function getVuexLotListFilters() {
  const state = store.state;
  const params = {};
  for (let key in state.lotQueryParams) {
    if (!state.lotQueryParams[key]) {
      continue;
    }
    if (
      Array.isArray(state.lotQueryParams[key]) &&
      !state.lotQueryParams[key].length
    ) {
      continue;
    }
    params[key] = state.lotQueryParams[key];
  }
  return params;
}

export function getVuexAuctionListFilters() {
  const state = store.state;
  const params = {};
  for (let key in state.auctionQueryParams) {
    if (!state.auctionQueryParams[key]) {
      continue;
    }
    if (
      Array.isArray(state.auctionQueryParams[key]) &&
      !state.auctionQueryParams[key].length
    ) {
      continue;
    }
    params[key] = state.auctionQueryParams[key];
  }
  return params;
}

export function getVuexFavoriteAuctionListFilters() {
  const state = store.state;
  const params = {};
  for (let key in state.favoriteAuctionQueryParams) {
    if (!state.favoriteAuctionQueryParams[key]) {
      continue;
    }
    if (
      Array.isArray(state.favoriteAuctionQueryParams[key]) &&
      !state.favoriteAuctionQueryParams[key].length
    ) {
      continue;
    }
    params[key] = state.favoriteAuctionQueryParams[key];
  }
  return params;
}

export function getLotsQuery() {
  const filters = getVuexLotListFilters();
  const query = {
    ...filters
  }

  if (query.sort == LOT_DEFAULT_SORT_FIELD) {
    delete query.sort;
  }
  if (query['sort-by'] == LOT_DEFAULT_SORT_BY) {
    delete query['sort-by'];
  }
  if (query.lot_status == LOT_DEFAULT_STATUS) {
    delete query.lot_status;
  }

  return query;
}

export function updateLotsRoute() {
  const filters = getVuexLotListFilters();
  const query = {
    ...filters
  }
  let params = {};
  if (query.auction_uuid) {
    params.auction_uuid = query.auction_uuid;
    // delete query.auction_uuid;
  }

  if (query.page == LOT_LIST_DEFAULT_PAGE) {
    delete query.page;
  }
  if (query.limit == LOT_LIST_DEFAULT_LIMIT) {
    delete query.limit;
  }
  if (query.sort == LOT_DEFAULT_SORT_FIELD) {
    delete query.sort;
  }
  if (query['sort-by'] == LOT_DEFAULT_SORT_BY) {
    delete query['sort-by'];
  }
  if (!store.getters.isPagingTypePagination) {
    delete query.page
  }
  if (query.lot_status == LOT_DEFAULT_STATUS) {
    delete query.lot_status;
  }
  router.push({
    path: router.app.$route.path,
    params,
    query
  });

  lotChangeFilters();
}


export function updateAuctionsRoute() {
  const filters = getVuexAuctionListFilters();
  const query = {
    ...filters
  }
  let params = {};
  if (query.auction_uuid) {
    params.auction_uuid = query.auction_uuid;
    delete query.auction_uuid;
  }

  if (query.page == AUCTION_LIST_DEFAULT_PAGE) {
    delete query.page;
  }
  if (query.limit == AUCTION_LIST_DEFAULT_LIMIT) {
    delete query.limit;
  }
  if (query.sort == AUCTION_DEFAULT_SORT_FIELD) {
    delete query.sort;
  }
  if (query['sort-by'] == AUCTION_DEFAULT_SORT_BY) {
    delete query['sort-by'];
  }
  if (!store.getters.isPagingTypePagination) {
    delete query.page
  }
  if (query.status == AUCTION_DEFAULT_STATUS) {
    delete query.status;
  }
  router.push({
    path: router.app.$route.path,
    params,
    query
  });

  auctionChangeFilters();
}

export function updateFavoriteAuctionsRoute() {
  const filters = getVuexFavoriteAuctionListFilters();
  const query = {
    ...filters
  }
  let params = {};
  if (query.auction_uuid) {
    params.auction_uuid = query.auction_uuid;
    delete query.auction_uuid;
  }

  if (query.page == FAVORITE_AUCTION_LIST_DEFAULT_PAGE) {
    delete query.page;
  }
  if (query.limit == FAVORITE_AUCTION_LIST_DEFAULT_LIMIT) {
    delete query.limit;
  }
  if (query.sort == FAVORITE_AUCTION_DEFAULT_SORT_FIELD) {
    delete query.sort;
  }
  if (query['sort-by'] == FAVORITE_AUCTION_DEFAULT_SORT_BY) {
    delete query['sort-by'];
  }
  if (!store.getters.isPagingTypePagination) {
    delete query.page
  }
  if (query.status == FAVORITE_AUCTION_DEFAULT_STATUS) {
    delete query.status;
  }
  router.push({
    path: router.app.$route.path,
    params,
    query
  });
  auctionChangeFilters()
}

export function afterAuthFinish(isAuhorized) {
  if (!isAuhorized) {
    afterLogout();
  } else {
    afterLogin()
  }
}

export function afterLogin() {
  showHTMLElements(CONFIG.visibleForAuthSelector);
  hideHTMLElements(CONFIG.visibleForGuestsSelector);

  // Find logout element and Add event listener
  const logoutEls = document.querySelectorAll('[data-webapp-action=logout]')
  logoutEls.forEach(el => {
    el.addEventListener('click', onLogoutClick)
  })
}

export function afterLogout() {
  hideHTMLElements(CONFIG.visibleForAuthSelector);
  showHTMLElements(CONFIG.visibleForGuestsSelector);
  const logoutEls = document.querySelectorAll('[data-webapp-actions=logout]')
  logoutEls.forEach(el => {
    el.removeEventListener('click', onLogoutClick)
  })
}

export function hideHTMLElements(cssSelector) {
  const elements = document.querySelectorAll(cssSelector);
  elements.forEach(el => el.style.display = 'none')
}

export function showHTMLElements(cssSelector) {
  const elements = document.querySelectorAll(cssSelector);
  elements.forEach(el => el.style.display = '')
}

export function deepClone(object) {
  if (typeof object === 'object')
    return JSON.parse(JSON.stringify(object));

  throw "Variable must be of 'object' type";
}

export function capitalizeWord(word) {
  if (typeof word === "string")
    return word[0].toUpperCase() + word.slice(1);

  throw "Variable must be of 'String' type";
}

export class PreciseNum {
  constructor(num) {
    this.setNum(num);
  }

  get precision() {
    const split = String(this.num).split(".")
    return split.length === 1 ? 0 : split[1].length
  }

  setNum(num) {
    const type = typeof num;
    switch (type) {
      case "string":
        this.num = parseFloat(num);
        break;
      case "number":
        this.num = num;
        break;
      case "object":
        if (num instanceof PreciseNum) {
          this.num = num.num;
          break;
        }
        throw TypeError("Invalid type for PreciseNum");
      default:
        throw TypeError("Invalid type for PreciseNum");
    }
  }

  setCalcNums(preciseNum) {
    const precision = Math.max(this.precision, preciseNum.precision);
    this.calcNums = {
      a: parseFloat(this.num.toFixed(precision)),
      b: parseFloat(preciseNum.num.toFixed(precision)),
    };
  }

  lt(preciseNum) {
    this.setCalcNums(preciseNum);
    return this.calcNums.a < this.calcNums.b;
  }

  lte(preciseNum) {
    this.setCalcNums(preciseNum);
    return this.calcNums.a <= this.calcNums.b;
  }

  gt(preciseNum) {
    this.setCalcNums(preciseNum);
    return this.calcNums.a > this.calcNums.b;
  }

  gte(preciseNum) {
    this.setCalcNums(preciseNum);
    return this.calcNums.a >= this.calcNums.b;
  }

  eq(preciseNum) {
    this.setCalcNums(preciseNum);
    return this.calcNums.a === this.calcNums.b;
  }

  plus(preciseNum) {
    this.setCalcNums(preciseNum);
    this.num = this.calcNums.a + this.calcNums.b;
  }

  minus(preciseNum) {
    this.setCalcNums(preciseNum);
    this.num = this.calcNums.a - this.calcNums.b;
  }

  toString() {
    return this.num.toFixed(this.precision);
  }
}

export async function checkOnlineStatus() {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = "https://cdn.artisio.co/1px.png?t" + Date.now();
  })
}

export function goBackToList(query) {
  const params = {};
  const lot = store.state.lotObject.data
  if (query.auction_uuid) {
    params.uuid = query.auction_uuid;
    delete query.auction_uuid;
    return router.push({name: 'AuctionViewTimed', params, query});
  }

  if (query.i_made_bid) {
    delete query.i_made_bid
    return router.push({name: 'IMadeBidLots', query});
  }

  if (lot && lot.uuid) {
    const is_continious_auction = parseInt(store.state.settings.is_continious_auction)
    if (!is_continious_auction) {
      params.uuid = lot.auction_uuid;
      return router.push({name: 'AuctionViewTimed', params, query});
    }
  }
  return router.push({name: 'Lots', query});
}

export function openLogin(ev, preserveUrl = false) {
  let currentUrl;
  try {
    currentUrl = window.location.href
  } catch (e) {
    logger.error("Unable to get current.fullPath", e)
  }
  if (store.state.mainConfig.loginUrl || store.state.mainConfig.loginHandler) {
    if (store.state.mainConfig.loginUrl) {
      // If we plan to do the same thing we need to uncomment this, but there will be other improvements
      // necessary as well.
      if (preserveUrl && currentUrl) {
        localStorage.setItem(REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY, currentUrl);
      }
      const a = document.createElement('a');
      a.href = store.state.mainConfig.loginUrl
      a.click();
    } else {
      store.dispatch('loginHandler', {event: ev})
    }
  } else {
    router.push({name: 'Login'})
    if (preserveUrl && currentUrl) {
      localStorage.setItem(REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY, currentUrl);
    }
  }
}

export function openLoginAndPreserveUrl(ev) {
  openLogin(ev, true)
}

export function openSignup(ev, preserveUrl = false) {
  let currentUrl;
  try {
    currentUrl = window.location.href
  } catch (e) {
    logger.error("Unable to get current.fullPath", e)
  }
  if (store.state.mainConfig.signupUrl || store.state.mainConfig.signupHandler) {
    if (store.state.mainConfig.signupUrl) {
      // If we plan to do the same thing we need to uncomment this, but there will be other improvements
      // necessary as well.
      if (preserveUrl && currentUrl) {
        localStorage.setItem(REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY, currentUrl);
      }
      const a = document.createElement('a');
      a.href = store.state.mainConfig.signupUrl
      a.click();
    } else {
      store.dispatch('signupHandler', {event: ev})
    }
  } else {
    router.push({name: 'Signup'})
    if (preserveUrl && currentUrl) {
      localStorage.setItem(REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY, currentUrl);
    }
  }
}

export function openSignupAndPreserveUrl(ev) {
  openSignup(ev, true)
}

function onLogoutClick(ev) {
  ev.preventDefault();
  store.dispatch('logout')
}


export function createOverlay(cls, bodyCls) {
  const div = document.createElement('div');
  div.className = cls;
  div.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  div.style.position = 'fixed';
  div.style.left = '0';
  div.style.top = '0';
  div.style.right = '0';
  div.style.bottom = '0';
  div.style.zIndex = 1100;
  window.document.body.appendChild(div);
  window.document.body.classList.add(bodyCls)
}

export function removeOverlay(cls, bodyCls) {
  const div = document.querySelector(cls);
  if (div) {
    div.remove()
  }

  window.document.body.classList.remove(bodyCls)
}

export function getIncrements(lot) {
  if (lot.increments && lot.increments.length) {
    return lot.increments;
  }
  if (!lot.auction.increment_calculation_source || lot.auction.increment_calculation_source === INCREMENT_CALCULATION_SOURCE_BID) {
    return lot.auction.increments;
  }
  const checkAgainst = lot.reserve || lot.start_price;
  if (!checkAgainst) {
    const increment = lot.auction.increments[0]
    return [{
      'increment': parseFloat(increment.increment),
      'up_to': Number.MAX_VALUE,
      'increment_range': increment.increment_range
    }];
  }
  if (lot.auction.increment_calculation_source === INCREMENT_CALCULATION_SOURCE_RESERVE) {
    for (let i in lot.auction.increments) {
      const increment = lot.auction.increments[i];

      if (!increment.up_to || increment.up_to > checkAgainst) {
        return [{
          'increment': parseFloat(increment.increment),
          'up_to': Number.MAX_VALUE,
          'increment_range': increment.increment_range
        }];
      }
    }
  }
  return null;
}

export function getCurrentServerTime() {
  const state = store.state;
  const timeDiff = Date.now() - state.clientTimeWhenServerResponded;
  return moment(state.serverTime + timeDiff)
}
