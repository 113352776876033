<template>
  <div v-if="auction.catalog && auction.catalog.url" class="ta-auction-catalog-link">
    <link-button :href="auction.catalog && auction.catalog.url" :target="target">
      <b-icon-collection/>
      <span class="ml-1">{{ $t('View Auction Catalog') }}</span>
    </link-button>
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";
import LinkButton from "@/components/LinkButton";

export default {
  name: "AuctionCatalogLink",
  components: {LinkButton},
  props: {
    target: {
      type: String,
      default: '_blank'
    }
  },
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
.ta-auction-catalog-link {
  margin-bottom: 1rem;
}
</style>
