<template>
  <image-picker v-model="model" @change="onChange" :label="$t('SELL_AN_ITEM.Images')"/>
</template>

<script>
import ImagePicker from "@/components/core/ImagePicker.vue";
import {mapMutations, mapState} from "vuex";
import baseInputMixin from "@/components/sell-an-item-modal/baseInputMixin";

export default {
  name: "ImageInput",
  components: {ImagePicker},
  mixins: [baseInputMixin([], 'images')],
  computed: {
    ...mapState(['sellAnItemModal'])
  },
  methods: {
    ...mapMutations(['setSellAnItemModalFieldInput']),
    onChange() {
      this.setSellAnItemModalFieldInput({field: 'images', value: this.model})
    }
  }
}
</script>

<style scoped>

</style>
