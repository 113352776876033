<template>
  <div class="ta-bid-total-amount">
    <strong class="ta-bid-total-amount-label">
      {{$t('Total Amount')}}:
    </strong>
    <span class="ta-bid-total-amount-value">
      {{(amount) | asCurrency(lot.auction.currency.code)}}
    </span>
  </div>
</template>

<script>
import CalculatedPremium from "@/components/lot-view/CalculatedPremium.vue";

export default {
  extends: CalculatedPremium,
  name: "BidTotalAmount",
  props: {
    bidAmount: Number,
    lot: {
      type: Object,
      required: true
    }
  },
  computed: {
    amount() {
      if (!this.bidAmount) {
        return 0;
      }
      return Math.round((this.calculatedPremium + this.bidAmount) * 100) / 100;
    }
  }
}
</script>

<style scoped>

</style>
