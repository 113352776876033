<template>
  <div class="ta-lot-bidding-input" v-if="lot.currency && !(isLotSold || isLotUnsold)">
    <div :id="`auction-list-bid-button-${lot.uuid}`"  v-b-tooltip.hover>
      <b-button :id="`lot-list-place-bid-button-${lot.uuid}`"
                @click="onBidButtonClick($event)"
                variant="primary"
                :disabled="biddingDisabled"
                size="sm" block>
        {{$t('Bid {amount}', {amount: formattedBidAmount})}}
      </b-button>
    </div>
    <b-tooltip v-if="biddingDisabled" container="artisioTimedAuctions" :target="`auction-list-bid-button-${lot.uuid}`">
      <template v-if="!is_authorized">
        {{ $t('Please login to make a bid') }}
      </template>
      <template v-else-if="!biddingStarted">
        {{ $t('Lot is not open yet') }}
      </template>
      <template v-else-if="biddingFinished">
        {{ $t('Lot has ended') }}
      </template>
      <template v-else-if="!lot.auction.is_bidder">
        {{ $t('Please first register as bidder') }}
      </template>
      <template v-else-if="isCurrentUserLastBidder">
        {{ $t('You are the latest bidder') }}
      </template>
    </b-tooltip>


    <BidConfirmation :id="`bid-confirmation-popover-${lot.uuid}`"
                     v-if="isBidder && !doNotShowConfirmOnBid[lot.uuid]"
                     :show.sync="showBidConfirmation"
                     :target="`lot-list-place-bid-button-${lot.uuid}`"
                     :for-mobile="forMobile"
                     :lot="lot"
                     :bid-amount="parseFloat(newBidAmount)"
                     :container="`artisioTimedAuctions`"
                     custom-class="ta-bid-popover-lot-listing"
                     @confirm="bidConfirmed($event)"
                     @no="onBidConfirmationNo"
                     @close="onBidConfirmationClose" />
  </div>
</template>

<script>
import BidConfirmation from "../../lot-view/BidConfirmation";
import {mapMutations, mapState} from "vuex";
import UpcomingAmountPopover from "../../lot-view/UpcomingAmountPopover";
import lotHelperMixin from "@/mixins/lotHelperMixin";
import {
  placeBid,
  placeBidConfirmClose,
  placeBidConfirmNo,
  placeBidConfirmYes,
} from "@/triggers";
import i18n from "@/i18n";
import lotListItemBiddingMixin from "@/mixins/lotListItemBiddingMixin";

export default {
  name: "BidButton",
  components: {UpcomingAmountPopover, BidConfirmation},
  mixins: [lotListItemBiddingMixin, lotHelperMixin],
  props: {
    label: {
      type: [String, Boolean],
      default: false
    },
    forMobile: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    showBidConfirmation: false,
    disableBidding: false,
  }),
  computed: {
    ...mapState(['doNotShowConfirmOnBid']),
    idPrefix() {
      return this.forMobile ? 'mobile_' : ''
    },
    isBidder() {
      return !((this.lot.auction.manual_bidder_approval || this.lot.auction.initial_deposit_amount || this.lot.auction.require_credit_card_verification) && !this.lot.auction.is_bidder)
    }
  },
  methods: {
    ...mapMutations(['markDoNotShowConfirmOnBid', 'showModalMsgBox']),
    onBidButtonClick(ev) {
      placeBid(this.title, this.newBidAmount)
      if (!this.isBidder) {
        this.showModalMsgBox({
          title: i18n.t('Error'),
          content: i18n.t('You are not registered as bidder.')
        });
        return;
      }
      if (this.doNotShowConfirmOnBid[this.lot.uuid]) {
        this.hideUpcomingAmountPopover('upcomingBidPopover');
        this.tempDisableButton();
        this.place_bid();
      } else {
        this.showBidConfirmation = !this.showBidConfirmation;
      }
    },
    onMouseEnter() {
      // placeBidMouseEnter(this.title)
    },
    onMouseLeave() {
      // placeBidMouseLeave(this.title)
    },
    bidConfirmed(dontAsk) {
      placeBidConfirmYes(this.title, this.newBidAmount)
      if (dontAsk) {
        this.markDoNotShowConfirmOnBid(this.lot.uuid);
      }
      this.tempDisableButton();
      this.place_bid();
    },
    tempDisableButton() {
      this.disableBidding = true;
      setTimeout(() => {
        this.disableBidding = false;
      }, 500);
    },
    bidUpcomingAmount(amount) {
      this.newBidAmount = amount;
      this.onBidButtonClick();
    },
    onBidConfirmationNo() {
      placeBidConfirmNo(this.title, this.newBidAmount)
    },
    onBidConfirmationClose() {
      placeBidConfirmClose(this.title)
    }
  },
}
</script>

<style lang="scss" scoped>

.ta-lot-bid-btn {
  align-items: center;
  display: inline-flex;
  justify-content: center;

  svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 0.5rem;
    width: 16px;
  }
}

.ta-lot-bid-input {
  position: relative;
}

</style>

<style lang="scss">
#artisioTimedAuctions {
  .ta-bid-popover-lot-listing {
    bottom: auto !important;
  }
}
</style>
