<template>
  <div class="ta-calculated-premium">
    <strong class="ta-calculated-premium-label">
      {{ $t('Calculated Premium') }}:
    </strong>
    <span class="ta-calculated-premium-value">
      {{ calculatedPremium | asCurrency(lot.auction.currency.code) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "CalculatedPremium",
  data: () => ({
    calculatedPremium: null,
  }),
  props: {
    bidAmount: Number,
    lot: {
      type: Object,
      required: true
    }
  },
  watch: {
    bidAmount: {
      immediate: true,
      handler() {
        if (!this.bidAmount) {
          this.calculatedPremium = 0;
          return;
        }
        this.calculatedPremium = Math.round(this.calculate_premium(this.lot.auction.premium_terms, this.bidAmount) * 100) / 100;
        const minimum = parseFloat(this.lot.auction.minimum_premium || 0);
        if (this.calculatedPremium < minimum) {
          this.calculatedPremium = minimum;
        }
      }
    }
  },
  methods: {
    calculate_premium(premiums, amount) {
      if (!amount || !premiums)
        return 0

      premiums = premiums.sort((p1, p2) => p1.amount_over - p2.amount_over);
      const premium_ranges = []
      for (let i = 1; i < premiums.length; i++) {
        premium_ranges.push({
          'amount': premiums[i].amount_over - premiums[i - 1].amount_over,
          'percent': premiums[i - 1].percent
        })
      }
      premium_ranges.push({
        'amount': 9999999999,
        'percent': premiums[premiums.length - 1].percent
      })

      return this.calculate_tiers(amount, premium_ranges)
    },
    calculate_tiers(price, ranges) {
      let amount = 0
      for (let r of ranges) {
        if (price <= 0)
          continue

        if (r.amount > price) {
          amount += price * (r.percent / 100)
          price = 0
        } else {
          amount += r.amount * (r.percent / 100)
          price -= r.amount
        }
      }
      return amount
    }
  },
}
</script>

<style scoped>

</style>
