<template>
  <auction-stream-bid-button :block="false" :auction="auction"/>
</template>

<script>
import AuctionStreamBidButton from "@/views/AuctionViewPage/AuctionStreamBidButton.vue";
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionStreamBid",
  mixins: [auctionViewMixin],
  components: {AuctionStreamBidButton}
}
</script>

<style scoped>

</style>
