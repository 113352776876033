<template>
 <div v-if="!isOnline" class="ta-bidding-offline-overlay"></div>
</template>

<script>
import { mapGetters} from "vuex";

export default {
  name: "OfflineMessageOverlay",
  computed: {
    ...mapGetters(['isOnline'])
  },
}
</script>
<style scoped lang="scss">
.ta-bidding-offline-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.4);
  color: white
}
</style>
