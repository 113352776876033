<template>
  <form v-if="lot.currency" class="ta-lot-bidding-max-bid-input" ref="maxBidForm" @submit.stop.prevent>
    <b-form-group
      label-for="max-bid-amount"
      :invalid-feedback="errorMessage"
      :state="maxBidState"
    >
      <label v-if="label" class="ta-lot-bidding-max-bid-input-label">
        <template v-if="label === true">{{ $t('Set max bid (Optional)') }}</template>
        <template v-else-if="label">{{ label }}</template>
      </label>
      <b-input-group v-if="lot.currency" :prepend="lot.currency.symbol">
        <b-form-input id="max-bid-amount"
                      type="number"
                      :state="maxBidState"
                      v-model="maxBidAmount"
                      :placeholder="$t('Your Max Bid')"
                      required
        />
      </b-input-group>
    </b-form-group>
    <upcoming-amount-popover
      target="max-bid-amount"
      :state-object="upcomingMaxBidPopover"
      :text="$t('Current Max bid amount is invalid. Please submit one of the following amounts') + ':'"
      :currency-code="lot.currency.code"
      :as-popover="false"
      @hidePopover="hideUpcomingAmountPopover('upcomingMaxBidPopover')"
      @setNewAmount="saveUpcomingMaxBidAmount"
    />
    <div class="row">
      <div class="col-6 mb-3">
        <b-button :disabled="!lot.max_bid" variant="danger" @click="deleteMaxBidAmount" block>
          {{ $t('Delete max bid') }}
        </b-button>
      </div>
      <div class="col-6 mb-3">
        <div id="timedBiddingMaxBidInputSaveButton" v-b-tooltip.hover>
          <b-button variant="primary" @click="saveMaxBidAmount" block :disabled="biddingDisabled">
            {{ lot.max_bid ? $t('Change max bid') : $t('Save max bid') }}
          </b-button>
        </div>
        <b-tooltip container="artisioTimedAuctions" target="timedBiddingMaxBidInputSaveButton">
          <template v-if="!is_authorized">
            {{ $t('Please login to leave max bid') }}
          </template>
        </b-tooltip>
      </div>
    </div>
  </form>
</template>

<script>
import maxBidMixin from "../../../mixins/maxBidMixin";
import {mapActions, mapState} from "vuex";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";
import UpcomingAmountPopover from "../../lot-view/UpcomingAmountPopover";
import {MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT} from "../../../constants";
import {getIncrements} from "@/helpers";

export default {
  name: "BiddingMaxBid",
  components: {UpcomingAmountPopover},
  mixins: [maxBidMixin, lotBiddingMixin],
  props: {
    label: {
      type: [String, Boolean],
      default: true
    }
  },
  data: () => ({
    maxBidAmount: null,
    maxBidState: null,
    errorMessage: null,
  }),
  watch: {
    'lot.max_bid': {
      immediate: true,
      handler() {
        this.maxBidAmount = this.lot.max_bid;
      }
    }
  },
  methods: {
    ...mapActions(['createMaxBid', 'deleteMaxBid']),

    saveUpcomingMaxBidAmount(amount) {
      this.errorMessage = "";
      this.maxBidState = null;
      this.maxBidAmount = amount;
      this.saveMaxBidAmount();
    },

    async saveMaxBidAmount() {
      this.hideUpcomingAmountPopover('upcomingMaxBidPopover');
      let maxBid = Number(this.maxBidAmount);
      let valid = true;

      if (maxBid !== parseInt(maxBid)) {
        valid = false;
        this.errorMessage = this.$t('Max bid must be whole number. Decimal values are not allowed.')
      }

      if (!maxBid > Number(this.lot.max_bid)) {
        valid = false;
        this.errorMessage = this.$t('Please enter valid max bid')
      } else if (maxBid < this.next) {
        valid = false;
        this.errorMessage = this.$t('Max bid amount must NOT be less than {amount}', {amount: this.$options.filters.asCurrency(this.next, this.lot.currency.code)})
      }

      if (this.settings.default_max_bidding_type === MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT && valid) {
        valid = await this.validateUpcomingAmount({
          newAmount: this.maxBidAmount,
          currentAmount: this.lot.winning_bid?.amount,
          startPrice: this.lot.start_price,
          increments: getIncrements(this.lot),
          stateObjectName: "upcomingMaxBidPopover",
        });
      }

      this.maxBidState = valid;

      if (valid) {
        const res = await this.createMaxBid({
          lot_uuid: this.lot.uuid,
          amount: maxBid
        });
        if (!res.success) {
          this.maxBidState = false;
          this.errorMessage = res.message
        }
      }
    },
    async deleteMaxBidAmount() {
      const status = await this.deleteMaxBid({uuid: this.lot.uuid})
    }
  }
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  #max-bid-modal {
    .modal-footer {
      justify-content: space-between;
    }
  }
}
</style>
