<template>
  <div v-if="shouldShowRegisterAsBidderButton(auction)"
       class="ta-register-as-bidder-wrapper"
       :class="{'button-disabled': buttonDisabled, 'w-100': block}"
       >
    <div class="ta-register-as-bidder-tooltip-handle" :id="`register-as-bidder-wrapper-${auction.uuid}`"
         v-b-tooltip.hover>
      <b-button class="ta-register-as-bidder-button"
                @click="onClick"
                :variant="buttonVariant"
                :size="buttonSize"
                :block="block"
                :disabled="buttonDisabled">
        <b-icon-person-plus-fill v-if="icon" />
        {{ label }}
      </b-button>
      <b-tooltip v-if="buttonDisabled" container="artisioTimedAuctions" :target="`register-as-bidder-wrapper-${auction.uuid}`">
        {{ $t('Please login to register as bidder') }}
      </b-tooltip>
    </div>

    <b-modal id="confirmationDialog"
             @hide="showModal = false"
             :visible="showModal"
             size="lg"
             static
             lazy>
      <template v-slot:modal-header>
        <h5 class="modal-title" v-html="title">
        </h5>
        <button type="button" @click="showModal = false" aria-label="Close" class="close">×
        </button>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="cancel">
          {{ $t('Cancel') }}
        </b-button>
        <b-button variant="primary" @click="onSubmitClick" :disabled="mainConfig.termsAndConditionsUrl && !termsAccepted">
          {{ $t('Register') }}
        </b-button>
      </template>
      <div v-html="message"></div>

      <b-form-checkbox v-if="mainConfig.termsAndConditionsUrl" v-model="termsAccepted">
        <span v-html="$t('I have read the {link} of this sale and accept it.', {link: `<a href='${mainConfig.termsAndConditionsUrl}' target='_blank'>${$t('Terms and Conditions')}</a>`})"></span>
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import i18n from "@/i18n";
import auctionBaseMixin from "@/mixins/auctionBaseMixin";
import {registerAsBidderClick, registerAsBidderDialogNo, registerAsBidderDialogYes} from "@/triggers";

export default {
  name: "RegisterAsBidderButton",
  mixins: [auctionBaseMixin],
  props: {
    auction: {
      type: Object
    },
    block: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: () => true
    },
    buttonVariant: {
      type: String,
      default: () => 'primary' // All valid bootstrap 4 button variants
    },
    buttonSize: {
      type: String,
      default: () => 'md' // sm, md, lg
    },
    label: {
      type: String,
      default() {
        return this.$t('Register as Bidder')
      }
    }
  },
  data: () => ({
    termsAccepted: false,
    showModal: false,
    title: null,
    message: null,
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized', 'customerMeta']),
    buttonDisabled() {
      return !this.is_authorized
    }
  },
  methods: {
    ...mapActions(['registerAsBidder']),
    ...mapMutations(['showNotification', 'showModalMsgBox']),
    async onClick() {
      registerAsBidderClick(this.auction)
      try {
        let termsAndConditions = this.$options.filters.currentLocale(this.auction.terms_and_conditions)
        if (!termsAndConditions) {
          termsAndConditions = this.mainConfig.termsAndConditionsHtml || this.$t('Are you sure you want to register on this auction as a bidder?')
        }
        let auctionTitle = this.$options.filters.currentLocale(this.auction.title);
        // template variables
        const templateVariables = {
          bidder: `${this.customerMeta.first_name} ${this.customerMeta.last_name}`,
          auctionTitle: auctionTitle,
          auctionNo: this.auction.auction_no,
        }
        for (let key in templateVariables) {
          termsAndConditions = termsAndConditions.replace(new RegExp(`\{\{${key}\}\}`, 'g'), `<b>${templateVariables[key]}</b>`)
        }

        this.showConfirmation(
          termsAndConditions,
          this.$t('Register for auction - {title}', {title: '<b>' + auctionTitle + '</b>'}),
        )
      } catch (e) {
        console.error(e)
      }
    },
    showConfirmation(message, title) {
      this.title = title;
      this.message = message;
      this.showModal = true
    },
    async onSubmitClick() {
      registerAsBidderDialogYes(this.auction)
      const res = await this.registerAsBidder({auction: this.auction})
      if (!res.success) {
        if (res.error_code === 'DEPOSIT_REQUIRED') {
          const result = await this.$confirm(this.$t(res.error, {
            ...res.params,
            deposit_amount: this.$options.filters.asCurrency(res.params.deposit_amount, this.auction.currency.code)
          }))
          if (result) {
            const res = await this.registerAsBidder({auction: this.auction, force: true})
            if (res.success) {
              this.showWarning(
                i18n.t('Your request to register as a bidder will be approved once you have paid the deposit. Please check your email or go to My Profile/My Invoices section to pay the deposit invoice.'),
              )
            } else {
              this.showError(i18n.t(res.message || 'Something went wrong. Contact website support.'))
            }
          }
        } else if (res.error_code === 'CREDIT_CARD_VERIFICATION_REQUIRED') {
          const result = await this.$confirm(`<p>${this.$t("Credit/Debit Card verification is required to participate on this auction.")}</p>
                <p>${this.$t("If you would like to proceed, please click the 'Proceed' button below, which will redirect you to the card verification page.")}</p>
                <div class="alert alert-danger"><strong>
                  ${this.$t("Please note:")}
                  </strong><br>
                  ${this.$t("During the verification process, nominal amount of {amount} will be deducted from your card and immediately refunded.", {
                ...res.params,
                amount: this.$options.filters.asCurrency(
                  1.0,
                  this.auction.currency.code
                ),
              }
            )}</div>`, null, null, null, i18n.t('Please Confirm'), i18n.t('Proceed'), i18n.t('Cancel')
          )
          if (result) {
            return window.location.href = res.payment_url;
          }
        } else {
          this.showError(i18n.t(res.error || 'Something went wrong. Contact website support.'))
        }
      } else {
        if (res.status === 'approved') {
          this.showSuccess(i18n.t('You have successfully registered as bidder.'))
          return
        }
        if (res.status === 'pending') {
          this.showWarning(i18n.t('Your bidder registration status is pending, you will be notified once you have been approved.'))
        } else if (res.status === 'rejected') {
          this.showError(i18n.t('You have been rejected from participating on this auction. Please contact our support if you have any questions.'))
        }
        this.showModal = false
      }
    },
    showSuccess(message) {
      // show notification
      this.showNotification({
        title: i18n.t("Success!"),
        text: message,
        variant: "success",
        duration: 10000
      });
    },
    showWarning(message) {
      // show notification
      this.showModalMsgBox({
        title: i18n.t('Your status is pending'),
        content: i18n.t(message),
        headerBgVariant: 'warning'
      });
    },
    showError(content) {
      this.showModalMsgBox({
        title: i18n.t('You have been rejected'),
        content: i18n.t(content)
      });
    },
    cancel() {
      this.showModal = false
      registerAsBidderDialogNo(this.auction)
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-register-as-bidder-wrapper {
  display: inline-block;
  margin-bottom: 1rem;
}
.ta-register-as-bidder-tooltip-handle {
  display: inline-block;
  width: 100%;
}
</style>
