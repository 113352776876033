<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-bank_account_sort_code"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="`${required || bankAccountFilledInProfile ? 'required' : ''}`"
    />
  </div>
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseInputMixin from "@/mixins/profileInputMixin";
import {mapGetters} from "vuex";

export default {
  name: "BankAccountSortCode",
  components: {InputWidget},
  mixins: [BaseInputMixin('bank_account.sort_code')],

  data() {
    return {
      name: 'sort_code',
      defaultLabel: this.$t("Sort code")
    }
  },
  computed: {
    ...mapGetters(['bankAccountFilledInProfile'])
  }
}
</script>

<style scoped>

</style>
