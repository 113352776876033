<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      id="input-title"
      type="select"
      v-model="model"
      :error="errors"
      @change="inputChange"
      @input="onInput"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      :rules="required ? 'required' : ''"
      :options="options"
    />
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "IdentificationType",
  components: {InputWidget},
  mixins: [BaseInputMixin('identification.document_type')],

  data() {
    return {
      options: [
        {text: this.$t('Select Type...'), value: ''},
        {text: this.$t('Passport'), value: 'passport'},
        {text: this.$t('ID Card'), value: 'id_card'},
        {text: this.$t('Driver\'s License'), value: 'drivers_license'},
      ],
      defaultLabel: this.$t("Identification Type")
    }
  },
}
</script>

<style scoped>

</style>
