<template>
  <AuctionStreamBidButton :auction="lot.auction"/>
</template>

<script>

import {mapState} from "vuex";
import AuctionStreamBidButton from "@/views/AuctionViewPage/AuctionStreamBidButton.vue";

export default {
  name: "StreamBidButton",
  components: {AuctionStreamBidButton},
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    }),
  },
}
</script>

<style lang="scss" scoped>

</style>
