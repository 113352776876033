<template>
  <ModalLinkButton :title="$t('Payment Information')" :content="paymentInfo"
                   class="ta-auction-payment-information-link"/>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";
import ModalLinkButton from "@/components/ModalLinkButton.vue";

export default {
  name: "AuctionPaymentInformationLink",
  components: {ModalLinkButton},
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
</style>
