<template>
  <div class="ta-auction-num-of-lots">
    <b class="ta-auction-num-of-lots-label">{{ $t("Number of Lots") }}:</b>
    <span class="ta-auction-num-of-lots-value">{{ auction.number_of_lots }}</span>
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionNumberOfLots",
  mixins: [auctionViewMixin],
}
</script>

<style scoped>
 .ta-auction-num-of-lots-label {
   margin-right: 0.25rem;
 }
</style>
