<template>
  <div v-if="auction.auction_dates && auction.auction_dates.length > 1 " class="ta-auction-sessions">
    <h3>Sessions</h3>
    <div class="ta-auction-session-items">
      <router-link :to="{query: {...$route.query, session: s.uuid, page: 1}}" v-for="s of auction.auction_dates" :key="s.uuid"
                   class="ta-auction-session-item text-dark"
                   :class="s.uuid === $route.query.session ? 'ta-auction-session-item-active' : ''">
        <h4>{{ s.title }}</h4>
        <div>
          <span>{{ $t('End Date') }}:</span> {{ s.end_date | datetime }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionSessions",
  components: {},
  mixins: [auctionViewMixin],
  computed: {
    ...mapGetters(["is_authorized"]),
  }
}
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";

#artisioTimedAuctions {
  .ta-auction-sessions {
    //display: flex;
    //justify-content: center;
    //flex-wrap: wrap;
  }

  .ta-auction-session-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .ta-auction-session-item {
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    border: 1px solid $gray-300;
    background-color: $gray-100;
    border-radius: $border-radius;
    width: 250px;

    &.ta-auction-session-item-active,
    &:hover {
      text-decoration: none;
      background-color: fade-out($primary, 0.95);
      border: 1px solid fade-out($primary, 0.65);
    }
  }

}
</style>
