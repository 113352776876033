<template>
  <div v-if="mainConfig.jumpToLot" class="ta-jump-to-lot">
    <b-input-group>
      <b-form-input v-model="lot_no" :placeholder="$t('Jump to lot')" @keyup.enter="jumpToLot"></b-form-input>
      <b-input-group-append>
        <b-button @click="jumpToLot" variant="primary" :disabled="!lot_no || searching"
                  class="ta-lot-bid-btn d-flex align-items-center">
          <b-spinner v-if="searching" small label="Spinning"></b-spinner>
          <svg v-else xmlns="http://www.w3.org/2000/svg"
               style="width: 20px;"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
          </svg>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from "vuex";

export default {
  name: "JumpToLot",
  data: () => ({
    lot_no: null,
    searching: false,
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data,
      auction: state => state.auctionObject.data,
    }),
  },
  methods: {
    ...mapActions(['jumpIntoLot']),
    ...mapMutations(['showNotification']),
    async jumpToLot() {
      let auctionUuid = null;
      if (['LotView', 'PrivateTreatyLotView'].includes(this.$route.name)) {
        auctionUuid = this.lot.auction_uuid;
      } else if (["PrivateTreatyAuctionView", "AuctionViewTimed", "AuctionViewCatalog"].includes(this.$route.name)) {
        auctionUuid = this.auction.uuid
      }
      this.searching = true;
      try {
        const uuid = await this.jumpIntoLot({
          auction_uuid: auctionUuid,
          no: this.lot_no
        });
        this.searching = false;

        if (uuid) {
          this.$router.push({
            name: 'LotView', params: {lotno: uuid}, query: {
              ...this.$route.query,
              auction_uuid: auctionUuid
            }
          });
        } else {
          this.showNotification({
            title: this.$t('Warning'),
            text: this.$t("Lot hasn't been found."),
            variant: 'warning'
          });
        }
      } catch (e) {
        this.searching = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import.scss";

.ta-jump-to-lot {
  width: 200px;
  margin-left: 1rem;
  //margin-bottom: 1rem;
}


@include media-breakpoint-down(xs) {
  .ta-jump-to-lot {
    width: 100%;
    margin: 0 0 1rem;
  }
}
</style>
