<template>
  <div class="ta-auction-view-no">
    {{ auction.auction_no }}
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionNo",
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
.ta-auction-view-no {
  margin: 0 0.5rem;
}
</style>
