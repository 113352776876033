<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Vue from "vue";
import {TEMPLATE_KEYWORD_COMPONENT_MAP} from '../../constants'

// Components available in templates.
// Don't delete
import AuctionListFilters from './AuctionListFilters.vue'
import AuctionKeywordSearch from './AuctionKeywordSearch.vue'
import SortingDropdown from './SortingDropdown.vue'
import PerPageSelect from './PerPageSelect.vue'
import AuctionListItems from './AuctionListItems.vue'
import PaginationButtons from './PaginationButtons.vue'
import PaginationSummary from './PaginationSummary.vue'
import SwitchView from './SwitchView.vue'
import templatable from "@/mixins/templatable";
import {getDepartmentsForAuctions} from "@/store/actions";
import {emptyAuctionFilters} from "@/store/mutations";

export default {
  name: "AuctionListPage",
  components: {
    AuctionListFilters,
    SortingDropdown,
    AuctionKeywordSearch,
    AuctionListItems,
    PaginationButtons,
    PaginationSummary,
    PerPageSelect,
    SwitchView
  },
  mixins: [templatable('auctionListPageTemplate')],
  props: {
    auctionUuid: {
      type: String,
      default: null
    }
  },
  data: () => ({
    template: `
      <div class="ta-auction-list" >
        [[template]]
      </div>
    `,
  }),
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.emptyAuctionFilters();
        this.setAuctionFilters({
          ...this.$route.query,
          auction_uuid: this.auctionUuid
        });
        this.getAuctions();
        this.getDepartmentsForAuctions();
      },
    },
  },
  computed: {
    ...mapState(['auctionListViewType']),
  },
  methods: {
    ...mapActions(['getAuctions', 'getDepartmentsForAuctions']),
    ...mapMutations(['setAuctionFilters', 'emptyAuctionFilters', 'setDepartments', 'setCategories', 'setDynamicFields']),
  },
  destroyed() {
    this.setDepartments([])
    this.setCategories([])
    this.setDynamicFields([])
  }
}
</script>

<style scoped lang="scss">
@import "../../bootstrap/import";

.ta-auction-list {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.ta-auction-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.ta-auction-search-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.ta-auction-list-header-top {
  display: flex;
}

.ta-auction-list-header,
.ta-auction-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-auction-list-footer {
  margin-top: 1rem;
}

.btn-switch-view {
  outline: none;

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid gray
  }
}

@include media-breakpoint-down(xs) {
  .ta-auction-list {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .ta-auction-list-header-top,
  .ta-auction-list-header {
    flex-direction: column;
  }
}

</style>
