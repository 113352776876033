<template>
  <filter-group :title='$t("Lot with images")' id="collapse-lot-with-images" :collapsed="collapsed">
    <b-form-checkbox
      id="lots-with-images"
      v-model="has_images"
      @change="filtersChanged"
      :unchecked-value="null"
    >
      {{ $t("Lot with images") }}
    </b-form-checkbox>
  </filter-group>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import FilterGroup from "../../FilterGroup";
import userConfig from "@/user-config";

export default {
  name: "FilterWithImage",
  components: {FilterGroup},
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    has_images: null
  }),
  computed: {
    ...mapState(['lotQueryParams']),
    ...mapGetters(['selectedDepartment', 'categoriesAsOptions'])
  },
  watch: {
    lotQueryParams() {
      this.has_images = this.lotQueryParams.has_images
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        has_images: this.has_images,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.has_images = this.lotQueryParams.has_images
  },
  destroyed() {
    this.setLotFilters({
      has_images: false,
    });
  }
}
</script>

<style scoped>

</style>
