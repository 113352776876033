<template>
  <ModalLinkButton :title="$t('Collection Information')" :content="collectionInfo"
                   class="ta-auction-collection-information-link"/>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";
import ModalLinkButton from "@/components/ModalLinkButton.vue";

export default {
  name: "AuctionCollectionInformationLink",
  components: {ModalLinkButton},
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
</style>
