import lotBiddingBaseMixin from "@/mixins/lotBiddingBaseMixin";

export default {
  mixins: [lotBiddingBaseMixin],
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    newBidAmount: null,
  }),
  computed: {
    biddingStarted() {
      return this.lot.biddingStarted
    },
    biddingFinished() {
      return this.lot.biddingFinished
    },
    formattedBidAmount() {
      return this.$options.filters.asCurrency(this.newBidAmount, this.lot.currency.code)
    }
  },
  watch: {
    next: {
      immediate: true,
      handler(newVal) {
        this.newBidAmount = newVal;
      }
    }
  },
}
