<template>
  <a v-if="mainConfig.enableAuth && !is_authorized"
     @click="onLogin($event)"
     class="btn btn-secondary ta-btn-login btn-block justify-content-center d-flex align-items-center">
    <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
    </svg>
    {{ $t('Log in') }}
  </a>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {openLoginAndPreserveUrl} from "@/helpers";

export default {
  name: "LoginButton",
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized'])
  },
  methods: {
    onLogin(ev) {
      openLoginAndPreserveUrl(ev)
    }
  }
}
</script>

<style scoped>

</style>
