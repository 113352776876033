<template>
  <div v-if="mainConfig.socialShares || mainConfig.printButton">
    <hr>
    <div class="d-flex justify-content-between align-items-center">
      <!-- Social Shares -->
      <div v-if="mainConfig.socialShares" class="d-flex align-items-center">
        <span class="mr-2 font-weight-bold">{{ $t('Share') }}</span>
        <a :href="mailToHref" class="ta-social-share">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
               class="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
          </svg>
        </a>
        <a href="javascript:void(0)" @click="onFbShareClick" class="ta-social-share ta-social-share-facebook">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
               class="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 320 512">
            <path fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
          </svg>
        </a>
        <a href="javascript:void(0)" @click="onTwitterShareClick" class="ta-social-share">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
               class="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
          </svg>
        </a>
        <a href="javascript:void(0)" @click="onLinkedinShareClick" class="ta-social-share  ta-social-share-linkedin">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
               class="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 448 512">
            <path fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
          </svg>
        </a>
      </div>
      <!--/ Social Shares -->

      <!-- Print Button -->
      <div v-if="mainConfig.printButton">
        <a href="javascript:void(0)" @click="printClick" class="d-flex ta-lot-print-button">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                  clip-rule="evenodd"/>
          </svg>
          {{ $t('Print') }}
        </a>
      </div>
      <!--/ Print Button -->
    </div>
    <hr>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "SocialShareIcons",
  props: {
    lot: Object
  },
  computed: {
    ...mapState(['mainConfig', 'settings']),
    ...mapGetters(['currentLocale'])
  },
  methods: {
    mailToHref() {
      return `mailto:?subject=${this.getLotTitle()}&body=${window.location.href}`;
    },
    getLotTitle() {
      return this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.title))
    },
    getShareLink() {
      const params = new URLSearchParams();
      params.set('client_id', this.mainConfig.clientId)
      params.set('lot_uuid', this.lot.uuid)
      params.set('lang', this.currentLocale);
      params.set('url', window.location.href)
      return `${this.settings.social_share_host}/?hash=` + btoa(params.toString())
    },
    onFbShareClick() {
      const params = new URLSearchParams();
      params.set('u', this.getShareLink());
      // params.set('quote', 'Some cool text for the post')
      const newURL = `https://www.facebook.com/sharer/sharer.php?` + params.toString();

      window.open(newURL, 'sharer', 'toolbar=0,status=0,width=580,height=600');
    },
    onTwitterShareClick() {
      const params = new URLSearchParams();
      params.set('url', this.getShareLink());
      const url = `https://twitter.com/intent/tweet?` + params.toString();

      window.open(url, 'sharer', 'toolbar=0,status=0,width=800,height=600');
    },
    onLinkedinShareClick() {
      const params = new URLSearchParams();
      params.set('url', this.getShareLink());
      params.set('mini', true);
      params.set('title', this.getLotTitle())
      params.set('summary', this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.description)))
      params.set('source', 'article');

      const url = `https://www.linkedin.com/shareArticle?` + params.toString()

      window.open(url, 'sharer', 'toolbar=0,status=0,width=580,height=600');
    },
    printClick(ev) {
      if (typeof this.mainConfig.printButton === 'function') {
        this.mainConfig.printButton(ev);
      } else {
        // TODO Implement better print functionality.
        window.print();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";

#artisioTimedAuctions {
  .ta-social-share {
    width: 32px;
    height: 32px;
    padding: 0.5rem;
    margin-right: 0.5rem;
    background-color: $gray-600;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    &:hover {
      background-color: $primary;
    }

    &.ta-social-share-facebook {
      padding: 0.6rem;
    }
  }

  .ta-lot-print-button {
    color: $gray-700;

    svg {
      width: 20px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: $gray-900;
      text-decoration: none;
    }
  }
}

</style>
