<template>
  <div class="ta-auction-description" v-if="$options.filters.currentLocale(auction.description)">
    <div v-html="$options.filters.currentLocale(auction.description)">
    </div>
  </div>
</template>

<script>
import auctionViewRoute from "@/mixins/auctionHelperMixin.js";

export default {
  name: "AuctionDescription",
  mixins: [auctionViewRoute],
  props: {
    auction: {
      required: true,
      type: Object
    },
  }
}
</script>

<style scoped>

</style>
