<template>
  <a :href="href"
     class="ta-link-button"
     :target="target"
     @click="$emit('click', $event)">
    <b-icon v-if="icon" :icon="icon" aria-hidden="true"/>
    <span style="flex: 1">
      <slot></slot>
    </span>
    <b-icon icon="chevron-right" aria-hidden="true"/>
  </a>
</template>

<script>
export default {
  name: "LinkButton",
  props: {
    href: {
      type: [String, Array],
      default: 'javascript:void(0)'
    },
    icon: String,
    target: {
      type: String,
      default: '_self'
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-link-button {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  width: 240px;
  background-color: #dae0e5;
}
</style>
