<template>
  <div v-if="taxRate" class="ta-lot-hammer-tax-rate">
    <label v-if="label === true" class="ta-lot-hammer-tax-rate-label">{{ $t('VAT On Hammer') }} &nbsp;</label>
    <label v-else-if="label"  class="ta-lot-hammer-tax-rate-label">{{ label }}</label>
    <span class="ta-lot-hammer-tax-rate-value">{{ taxRate }}%</span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotHammerTaxRate",
  props: {
    label: {
      type: [Boolean, String],
      default: true
    },
    lot: {
      required: false,
      type: [Object, Boolean],
      default: () => false
    },
  },
  computed: {
    ...mapState({lotVuex: state => state.lotObject.data}),
    lotObj() {
      return this.lot || this.lotVuex
    },
    taxRate() {
      let rate = parseFloat(this.lotObj.hammer_tax_rate)
      if (parseInt(this.lotObj.hammer_tax_rate) < rate) {
        return rate.toFixed(1)
      }
      return rate;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
