import {mapState} from 'vuex';
import auctionBaseMixin from "@/mixins/auctionBaseMixin";

export default {
  mixins: [auctionBaseMixin],
  computed: {
    ...mapState(["auctionObject", "mainConfig"]),
    auction() {
      return this.auctionObject.data;
    },
  }
}
