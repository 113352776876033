<template>
  <small class="ta-auction-lot-count" v-if="show">
    {{ auction.number_of_lots || 0 }} {{ auction.number_of_lots == 1 ? $t('lot') : $t('lots') }}
  </small>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "NumberOfLot",
    props: {
      auction: {
        required: true,
        type: Object
      },
    },
    computed: {
      ...mapState(['mainConfig']),
      show() {
        if (this.mainConfig.showZeroLotCount) {
          return true
        }

        return this.auction.number_of_lots && this.auction.number_of_lots > 0
      }
    }
  }
</script>

<style scoped>
  .ta-auction-lot-count {
    color: #6c757d
  }
</style>
