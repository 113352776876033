<template>
  <h4 @click="onClick" class="ta-lot-list-item-title font-weight-bold">
    <router-link :to="auctionViewRoute" v-html="title">
    </router-link>
  </h4>
</template>

<script>
import auctionViewRoute from "@/mixins/auctionHelperMixin.js";
import {auctionView} from "@/triggers";

export default {
  name: "AuctionTitle",
  mixins: [auctionViewRoute],
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
  methods: {
    onClick() {
      auctionView(this.auction)
    }
  }
}
</script>

<style scoped>

</style>
