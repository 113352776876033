<template>
  <div v-if="availableLanguages.length > 1" class="col-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-preferred-language"
      type="select"
      @change="inputChange"
      @input="onInput"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      :rules="required ? 'required' : ''"
      :options="availableLanguages"
    />
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "../../../core/InputWidget";
import {mapGetters} from "vuex";

export default {
  name: "PreferredLanguage",
  components: {InputWidget},
  mixins: [BaseInputMixin('preferred_language_code')],

  data() {
    return {
      defaultLabel: this.$t("Preferred Language",)
    }
  },

  computed: {
    ...mapGetters(["availableLanguages"])
  },
  watch: {
    model(val, oldVal) {
      if (val && oldVal) {
        this.signupFieldOnInput({field: 'title', value: null})
      }
    }
  },
}
</script>

<style scoped>

</style>
