<template>
  <div class="ta-auction-bp-tax-rate">
    <label v-if="label === true" class="ta-auction-bp-tax-rate-label">{{ $t('Buyer\'s Premium') }} &nbsp;</label>
    <label v-else-if="label"  class="ta-auction-bp-tax-rate-label">{{ label }}</label>
    <span v-if="auctionObj.premium_terms.length === 1">
      {{auctionObj.premium_terms[0].percent}}%
    </span>
    <div v-else v-for="(premium, ind) of auctionObj.premium_terms" :key="ind" class="ta-buyer-premium-tier">
      <div class="ta-premium-percent">{{ premium.percent }}% {{ $t('premium') }}</div>
      <div class="ta-premium-over">{{ $t('Over') }} {{ premium.amount_over | asCurrency(auctionObj.currency.code) }}</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AuctionPremium",
  props: {
    label: {
      type: [Boolean, String],
      default: true
    },
    auction: {
      required: false,
      type: [Object, Boolean],
      default: () => false
    },
  },
  computed: {
    ...mapState({auctionVuex: state => state.auctionObject.data}),
    auctionObj() {
      return this.auction || this.auctionVuex
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
