<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-title"
      type="select"
      @change="inputChange"
      @input="onInput"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      :rules="required ? 'required' : ''"
      :options="options"
    />
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "Gender",
  components: {InputWidget},
  mixins: [BaseInputMixin('gender')],
  props: {
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [
        {value: 'male', text: this.$i18n.t('Male')},
        {value: 'female', text: this.$i18n.t('Female')},
        {value: 'other', text: this.$i18n.t('Other')},
      ],
      defaultLabel: this.$t("Gender",)
    }
  },
}
</script>

<style scoped>

</style>
