<template>
  <div class="ta-modal-link-button">
    <b-modal
      ref="modal"
      :title="title"
      :visible="displayModal"
      @hidden="displayModal = false"
      static
      lazy
    >
      <div v-html="content"></div>
    </b-modal>
    <link-button v-if="content" @click="displayModal = true" :icon="icon">
      <span class="ml-1 pr-5">{{ title }}</span>
    </link-button>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton.vue";

export default {
  name: "ModalLinkButton",
  components: {LinkButton},
  props: {
    content: String,
    title: String,
    icon: {
      type: String,
      default: 'file-earmark'
    }
  },
  data: () => ({
    displayModal: false,
  }),
}
</script>

<style lang="scss" scoped>
.ta-modal-link-button {
  margin-bottom: 1rem;
}
</style>
