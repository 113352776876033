<template>
  <div class="ta-bid-amount">
    <strong class="ta-bid-amount-label">
      {{$t('Your Bid')}}:
    </strong>
    <span class="ta-bid-amount-value">
      {{bidAmount | asCurrency(lot.auction.currency.code)}}
    </span>
  </div>
</template>

<script>
export default {
  name: "BidAmount",
  props: {
    bidAmount: Number,
    lot: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
