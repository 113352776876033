<template>
  <div v-if="auction.end_date" class="ta-auction-end_date">
    <b v-if="label" class="ta-auction-end_date-label">{{label}}: </b>
    <span v-if="datetime" class="ta-auction-end_date-value">{{ auction.end_date | datetime }}</span>
    <span v-else class="ta-auction-end_date-value">{{ auction.end_date | date }}</span>
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionEndDate",
  props: {
    label: {
      type: [Boolean, String],
      default() {
        return this.$t('End Date')
      }
    },
    datetime: {
      type: Boolean,
      default: true
    }
  },
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
  .ta-auction-view-value {
    margin: 0 0.5rem;
  }
</style>
