import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import {BIDDING_TYPE_EXACT_UPCOMING_AMOUNT} from "../constants";
import moment from "moment";
import lotBaseMixin from "@/mixins/lotBaseMixin";
import {getIncrements} from "@/helpers";
import i18n from "@/i18n";

export default {
  mixins: [lotBaseMixin],
  data: () => ({
    showBuyNowConfirmation: false,
  }),
  computed: {
    ...mapGetters(['customerMeta', 'is_authorized', 'settings']),
    ...mapState({
      upcomingBidPopover: state => state.lotObject.upcomingBidPopover,
    }),
    currentBid() {
      return this.lot.winning_bid ? this.lot.winning_bid.amount : 0;
    },
    currentBidOrStartPrice() {
      let currentBid = this.currentBid

      if (!currentBid) {
        if (this.lot.start_price) {
          return parseFloat(this.lot.start_price);
        }
        currentBid = this.lot.start_price || 0;
      }
      return parseFloat(parseFloat(currentBid).toFixed(2));
    },
    next() {
      if (!this.lot || !this.lot.auction || !getIncrements(this.lot)) return '';

      const currentBid = this.currentBidOrStartPrice

      if (!this.currentBid && currentBid) {
        return currentBid
      }

      const increment = this.getIncrementByCurrentBid(currentBid)
      if (!increment) return '';

      return currentBid + increment;
    },
    showLatestBidNotification() {
      return this.biddingFinished || this.isLotUnsold || this.isLotSold || this.lot.has_my_bid
    },
    iWonTheItem() {
      return this.isCurrentUserLastBidder && (this.biddingFinished || this.lot.status === 'sold')
    },
    isCurrentUserLastBidder() {
      if (this.customerMeta?.uuid && this.lot.winning_bid?.customer_uuid) {
        return this.customerMeta.uuid === this.lot.winning_bid.customer_uuid;
      }
      return false;
    },
    biddingDisabled() {
      return !this.is_authorized || !this.biddingStarted || this.biddingFinished || !this.lot.auction.is_bidder
    },
    reserveMet() {
      if (!this.lot) return false;
      return this.lot.winning_bid && (!this.lot.reserve || parseFloat(this.lot.reserve) <= parseFloat(this.lot.winning_bid.amount))
    },
    /**
     * When reserve and winning_bid exists, but reserve is more than winning_bid.amount
     * @returns {null|*|boolean}
     */
    reserveNotMet() {
      return this.lot.reserve && this.lot.winning_bid && this.lot.reserve > this.lot.winning_bid.amount
    },
  },
  methods: {
    ...mapActions(['show401Notification', 'sendBidInSocket', 'validateUpcomingAmount', 'sendBuyNowAfterSale']),
    ...mapMutations(['hideUpcomingAmountPopover', 'showModalMsgBox']),
    getLot() {
      return this.lot;
    },
    async place_bid(buy_now = false) {
      if (!this.is_authorized) {
        this.show401Notification();
        return;
      }

      if (this.lot.auction.is_paused) {
        this.showModalMsgBox({
          title: i18n.t('The auction is paused!'),
          content: i18n.t('The auction is paused. Please try again in a few minutes.')
        })
        return
      }

      if (buy_now) {
        this.showBuyNowConfirmation = false;
      }

      if (this.settings.default_bidding_type === BIDDING_TYPE_EXACT_UPCOMING_AMOUNT && !buy_now) {
        const valid = await this.validateUpcomingAmount({
          newAmount: this.newBidAmount,
          currentAmount: this.lot.winning_bid?.amount,
          startPrice: this.lot.start_price,
          increments: getIncrements(this.lot),
          stateObjectName: "upcomingBidPopover",
        });

        if (!valid) {
          this.showModalMsgBox({
            title: i18n.t("Invalid amount"),
            content: i18n.t("The provided amount is invalid"),
            variant: 'danger'
          });
          return;
        }
      }

      const payload = buy_now ? {
        'action': 'bid',
        'data': {
          'lot_uuid': this.lot.uuid,
          'amount': this.lot.buy_now_price,
          'buy_now': 1
        }
      } : {
        'action': 'bid',
        'data': {
          'lot_uuid': this.lot.uuid,
          'amount': this.newBidAmount,
        },
      }
      if (buy_now && (this.isAfterSale || this.lot.auction.type === 'live')) {
        this.sendBuyNowAfterSale(this.lot.uuid);
      } else {
        this.sendBidInSocket(payload);
      }
    },
    getIncrementByCurrentBid(currentBid) {
      let increments = [...getIncrements(this.getLot())];

      if (!increments) {
        return '';
      }
      increments = increments.sort((a, b) => parseFloat(a.up_to) - parseInt(b.up_to));
      let increment = increments.find(inc => inc.up_to && parseFloat(inc.up_to) > currentBid);
      if (!increment) {
        // Find last increment without up_to
        increment = increments.find(inc => !inc.up_to);
      }
      return parseFloat(increment.increment);
    }
  }
}
