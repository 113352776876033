<template>
  <div class="ta-auction-terms-link">
    <template v-if="mainConfig.termsAndConditionsUrl">
      <link-button :href="mainConfig.termsAndConditionsUrl" :target="target">
        <b-icon-link/>
        <span class="ml-1">{{ $t(`Terms and Conditions`) }}</span>
      </link-button>
    </template>
    <template v-else>
      <b-modal
        id="auction-terms-modal"
        ref="auctionTermsModal"
        :title="$t('Terms and Conditions')"
        :visible="displayModal"
        @hidden="displayModal = false"
        static
        lazy
      >

        <div v-html="termsAndConditions"></div>
      </b-modal>
      <link-button v-if="termsAndConditions" @click="displayModal = true" icon="file-earmark">
        <span class="ml-1 pr-5">{{ $t(`Terms and Conditions`) }}</span>
      </link-button>
    </template>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";
import auctionViewMixin from "@/mixins/auctionViewMixin";
import {mainConfig} from "@/store/mutations";

export default {
  name: "AuctionTermsLink",
  components: {LinkButton},
  mixins: [auctionViewMixin],
  props: {
    target: {
      type: String,
      default: '_blank'
    }
  },
  data: () => ({
    displayModal: false,
  }),
  methods: {
    showModal() {
      this.displayModal = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-auction-terms-link {
  margin-bottom: 1rem;
}
</style>
