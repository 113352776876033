<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-password"
      type="password"
      :show-password-toggle="showPasswordToggle"
      :label="computedLabel"
      :rules="(required ? 'required|' : '') + 'min:8|at_least_lowercase_alpha|at_least_uppercase_alpha|at_least_digit|at_least_special'"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      autocomplete="new-password"
    >
      <template v-if="showPasswordPolicy" #append>
        <b-input-group-text id="input-password-append">
                <span class="d-inline-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="width: 1.25rem"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
        </b-input-group-text>
      </template>
    </InputWidget>
    <b-popover
      v-if="showPasswordPolicy"
      target="input-password-append"
      triggers="hover"
      placement="bottomleft"
      container="artisioTimedAuctions"
    >
      <template #title>
        {{ $t("Password Policy") }}
      </template>
      <div>
        <p class="font-weight-bold">
          {{
            $t("Your password must be at least {x} characters long and contain", { x: 8 })
          }}
        </p>
        <ul class="pl-4">
          <li class="">
            {{ $t("At least one digit") }}
          </li>
          <li class="">
            {{ $t("At least one lowercase letter") }}
          </li>
          <li class="">
            {{ $t("At least one uppercase letter") }}
          </li>
          <li class="">
            {{ $t("At least one special character") }}
          </li>
        </ul>
      </div>
    </b-popover>
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "Password",
  components: {InputWidget},
  mixins: [BaseInputMixin('password')],
  data() {
    return {
      defaultLabel: this.$t('Password')
    }
  },
  props: {
    required: {
      type: Boolean,
      default: true
    },
    showPasswordPolicy: {
      required: false,
      type: Boolean,
      default: true
    },
    showPasswordToggle: {
      required: false,
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
