<template>
  <div class="ta-auction-description" v-if="$options.filters.currentLocale(auction.description)">
    <div v-html="$options.filters.currentLocale(auction.description)">
    </div>
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionDescription",
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
 .ta-auction-view-description {
   margin-bottom: 1.5rem;
 }
</style>
