<template>
  <div v-if="shouldBeHidden" class="ta-commission-bids-closed">
    {{$t('Commission Bids are no longer accepted')}}
  </div>
  <form v-else-if="lot.currency && is_authorized && lot.auction.is_bidder" class="ta-lot-bidding-max-bid-input" ref="maxBidForm"
        @submit.stop.prevent>
    <div v-if="lot.max_bid_status === 'rejected'" class="alert alert-danger">
      {{$t('Your commission bid was rejected')}}
    </div>
    <b-form-group
      label-for="max-bid-amount"
      :invalid-feedback="errorMessage"
      :state="maxBidState"
    >
      <label v-if="label" class="ta-lot-bidding-max-bid-input-label">
        <template v-if="label === true">{{ $t('Set Commission Bid (Optional)') }}</template>
        <template v-else-if="label">{{ label }}</template>
      </label>
      <b-input-group v-if="lot.currency" :prepend="lot.currency.symbol">
        <b-form-input id="max-bid-amount"
                      type="number"
                      :state="maxBidState"
                      v-model="maxBidAmount"
                      :placeholder="$t('Your Commission Bid')"
                      required
        />
      </b-input-group>
    </b-form-group>
    <upcoming-amount-popover
      target="max-bid-amount"
      :state-object="upcomingMaxBidPopover"
      :text="$t('Current Commission bid amount is invalid. Please submit one of the following amounts') + ':'"
      :currency-code="lot.currency.code"
      @hidePopover="hideUpcomingAmountPopover('upcomingMaxBidPopover')"
      @setNewAmount="saveUpcomingMaxBidAmount"
    />
    <div class="row">
      <div class="col-6 mb-3">
        <div id="biddingMaxBidInputDeleteButton" v-b-tooltip.hover>
          <b-button :disabled="!lot.max_bid || biddingDisabled" variant="danger" @click="deleteMaxBidAmount" block >
            {{ $t('Delete commission bid') }}
          </b-button>
        </div>
        <b-tooltip container="artisioTimedAuctions" target="biddingMaxBidInputDeleteButton">
          <template v-if="lot.max_bid && auctionStarted">
            {{ $t('Commission bids cannot be deleted after auction is started.') }}
          </template>
        </b-tooltip>
      </div>
      <div class="col-6 mb-3">
        <div id="biddingMaxBidInputSaveButton" v-b-tooltip.hover>
          <b-button variant="primary" @click="saveMaxBidAmount" block :disabled="biddingDisabled">
            {{ lot.max_bid ? $t('Change commission bid') : $t('Save commission bid') }}
          </b-button>
        </div>
        <b-tooltip container="artisioTimedAuctions" target="biddingMaxBidInputSaveButton">
          <template v-if="!is_authorized">
            {{ $t('Please login to leave  a bid') }}
          </template>
          <template v-if="auctionStarted">
            {{ $t('Commission bids are not accepted after auction is started.') }}
          </template>
        </b-tooltip>
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapState} from "vuex";
import UpcomingAmountPopover from "../../lot-view/UpcomingAmountPopover";
import {createCommissionBid} from "@/store/actions";
import moment from "moment";
import {MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT} from "@/constants";
import maxBidMixin from "@/mixins/maxBidMixin";
import lotBiddingMixin from "@/mixins/lotBiddingMixin";
import {getIncrements} from "@/helpers";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: "BiddingCommissionBidInput",
  components: {UpcomingAmountPopover},
  mixins: [maxBidMixin, lotBiddingMixin],
  props: {
    label: {
      type: [String, Boolean],
      default: true
    }
  },
  data: () => ({
    maxBidAmount: null,
    maxBidState: null,
    errorMessage: null,
    interval: null,
    difference: null,
    shouldBeHidden: false
  }),
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
      mainConfig: state => state.mainConfig
    }),
    ...mapState(['is_authorized']),
    biddingDisabled() {
      return this.auctionStarted || !this.is_authorized
    },
    auctionStarted() {
      const currentTime = getCurrentServerTime();
      return this.lot.auction.actual_start_date < currentTime;
    }
  },
  watch: {
    'lot.max_bid': {
      immediate: true,
      handler() {
        this.maxBidAmount = this.lot.max_bid;
      }
    }
  },
  methods: {
    ...mapActions(['createCommissionBid', 'deleteCommissionBid']),

    saveUpcomingMaxBidAmount(amount) {
      this.errorMessage = "";
      this.maxBidState = null;
      this.maxBidAmount = amount;
      this.saveMaxBidAmount();
    },

    async saveMaxBidAmount() {
      this.hideUpcomingAmountPopover('upcomingMaxBidPopover');
      let maxBid = Number(this.maxBidAmount);
      let valid = true;

      if (!maxBid > Number(this.lot.max_bid)) {
        valid = false;
        this.errorMessage = this.$t('Please enter valid commission bid')
      } else if (maxBid < this.next) {
        valid = false;
        this.errorMessage = this.$t('Commission bid amount must NOT be less than {amount}', {amount: this.$options.filters.asCurrency(this.next, this.lot.currency.code)})
      }

      if (this.settings.default_max_bidding_type === MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT && valid) {
        valid = await this.validateUpcomingAmount({
          newAmount: this.maxBidAmount,
          // currentAmount: this.lot.last_bid_amount,
          startPrice: this.lot.start_price,
          increments: getIncrements(this.lot),
          stateObjectName: "upcomingMaxBidPopover",
        });
      }

      this.maxBidState = valid;

      if (valid) {
        this.createCommissionBid({
          lot_uuid: this.lot.uuid,
          amount: maxBid
        });
      }
    },
    async deleteMaxBidAmount() {
      await this.deleteCommissionBid({uuid: this.lot.uuid})
    },

    updateTimeDifference() {
      let startDate = this.lot.auction.start_date;

      let eventDate = typeof startDate === "string" ? moment(startDate).toDate().getTime() : startDate.getTime();
      startDate = moment(eventDate).utc();
      const currentTime = getCurrentServerTime()

      this.difference = startDate - currentTime

      this.shouldBeHidden = this.difference <= this.mainConfig.liveCommissionButtonHideTime * 60 * 1000
    },

  },
  mounted() {
    this.updateTimeDifference();
    const interval = 1000;

    this.interval = setInterval(() => {
      this.updateTimeDifference();
    }, interval);
  },
  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss">
@import "../../../bootstrap/import";

#artisioTimedAuctions {
  #max-bid-modal {
    .modal-footer {
      justify-content: space-between;
    }
  }

  .ta-lot-bidding-max-bid-auction-started {
    font-size: 16px;
    padding: 1rem 0;
    color: $gray-600;
    text-align: center;
  }

  .ta-commission-bids-closed {
    padding: 2rem 0;
    color: $gray-600;
    text-align: center;
  }
}
</style>
