<template>
  <div v-if="lot.currency && !(isLotSold || isLotUnsold)" class="ta-lot-view-max-bid">
    <b-modal
      :id="`max-bid-modal-${lot.uuid}`"
      ref="maxBidModal"
      :title="$t('Set your desired max bid amount')"
      :visible="showModal"
      @show="onModalShow"
      @hide="onModalHide"
      @close="onModalClose"
      @cancel="onModalClose"
      static
      lazy
    >

      <max-bid-form v-model="maxBidAmount"
                    :error-message="errorMessage"
                    :max-bid-amount="maxBidAmount"
                    :max-bid-state="maxBidState"
                    :lot="lot"
                    @submit="saveMaxBidAmount"
                    :save-upcoming-max-bid-amount="saveUpcomingMaxBidAmount"/>

      <template #modal-footer>
        <div>
          <b-button v-if="lot.max_bid" variant="danger" size="sm" @click="deleteMaxBidAmount">
            {{ $t('Delete') }}
          </b-button>
        </div>
        <div>
          <b-button variant="primary" size="sm" class="mx-2" @click="saveMaxBidAmount">
            {{ $t('Save') }}
          </b-button>
          <b-button variant="secondary" size="sm" @click="onModalClose">
            {{ $t('Close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="ta-lot-list-max-bid-inner">
      <div class="ta-lot-list-max-bid-wrapper" :id="`change-max-bid-button-${lot.uuid}`" v-b-tooltip:hover>
        <b-button variant="outline-primary"
                  :disabled="biddingDisabled"
                  block
                  @click="showMaxBidModal" size="sm">
          {{ lot.max_bid ? changeMaxBidLabel : setMaxBidLabel }}
          <template v-if="lot.max_bid">
            ({{ lot.max_bid | asCurrency(lot.currency.code) }})
          </template>
        </b-button>
      </div>
      <b-tooltip v-if="biddingDisabled" :target="`change-max-bid-button-${lot.uuid}`" container="artisioTimedAuctions">
        <template v-if="!is_authorized">
          {{ $t('Please login to place auto bid') }}
        </template>
        <template v-else-if="!biddingStarted">
          {{ $t('Lot is not open yet') }}
        </template>
        <template v-else-if="biddingFinished">
          {{ $t('Lot has ended') }}
        </template>
        <template v-else-if="!lot.auction.is_bidder">
          {{ $t('Please first register as bidder') }}
        </template>
        <template v-else-if="isCurrentUserLastBidder">
          {{ $t('You are the latest bidder') }}
        </template>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT} from "../../../constants";
import {maxBidDelete, maxBidModalClose, maxBidSave} from "@/triggers";
import lotListItemBiddingMixin from "@/mixins/lotListItemBiddingMixin";
import lotListingMaxBidMixin from "@/mixins/lotListingMaxBidMixin";
import MaxBidForm from "@/components/configuration/lot-list-item/MaxBidForm.vue";
import {getIncrements} from "@/helpers";

export default {
  name: "MaxBidButton",
  components: {MaxBidForm},
  mixins: [lotListingMaxBidMixin, lotListItemBiddingMixin],
  props: {
    setMaxBidLabel: {
      type: String,
      default() {
        return this.$t('Set max bid')
      }
    },
    changeMaxBidLabel: {
      type: String,
      default() {
        return this.$t('Change max bid')
      }
    }
  },
  data: () => ({
    maxBidAmount: null,
    maxBidState: null,
    errorMessage: null,
    showModal: false
  }),
  computed: {
    ...mapState(['mainConfig']),
  },
  methods: {
    ...mapActions(['createMaxBid', 'deleteMaxBid']),
    onModalShow() {
      this.errorMessage = '';
      this.maxBidState = null;
      this.maxBidAmount = this.lot.max_bid;
    },
    onModalHide() {
      // this.setShowMaxBidModal(false)
      this.showModal = false
    },
    onModalClose() {
      this.onModalHide()
      maxBidModalClose(this.title)
    },

    saveUpcomingMaxBidAmount(amount) {
      this.errorMessage = "";
      this.maxBidState = null;
      this.maxBidAmount = amount;
      this.saveMaxBidAmount();
    },

    async saveMaxBidAmount() {
      maxBidSave(this.title)
      this.hideUpcomingAmountPopover('upcomingMaxBidPopover');
      let maxBid = Number(this.maxBidAmount);
      let valid = true;

      if (maxBid !== parseInt(maxBid)) {
        valid = false;
        this.errorMessage = this.$t('Max bid must be whole number. Decimal values are not allowed.')
      }

      if (maxBid < this.next) {
        valid = false;
        this.errorMessage = this.$t('Max bid amount must NOT be less than {amount}', {amount: this.$options.filters.asCurrency(this.next, this.lot.currency.code)})
      }

      if (this.settings.default_max_bidding_type === MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT && valid) {
        valid = await this.validateUpcomingAmount({
          newAmount: this.maxBidAmount,
          currentAmount: this.lot.winning_bid?.amount,
          startPrice: this.lot.start_price,
          increments: getIncrements(this.lot),
          stateObjectName: "upcomingMaxBidPopover",
        });
      }

      this.maxBidState = valid;

      if (valid) {
        const res = await this.createMaxBid({
          lot_uuid: this.lot.uuid,
          amount: maxBid,
          dontShowErrorDialog: true
        });
        if (res.success) {
          this.maxBidState = true;
          this.$refs.maxBidModal.hide();
        } else {
          this.maxBidState = false;
          this.errorMessage = res.message
        }
      }
    },
    async deleteMaxBidAmount() {
      maxBidDelete(this.title)
      const status = await this.deleteMaxBid({uuid: this.lot.uuid})
      if (status === 200) {
        this.$refs.maxBidModal.hide();
      }
    }
  }
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  #max-bid-modal {
    .modal-footer {
      justify-content: space-between;
    }
  }
}
</style>
