<template>
  <div v-if="mainConfig.enableWatchlist" class="ta-btn-watchlist-wrapper">
    <div :id="lot.uuid" v-b-tooltip.hover>
      <b-button
        :disabled="!is_authorized || buttonDisabled"
        variant="link"
        @click="addRemoveWatchList" class="ta-btn-watchlist"
      >
        <b-icon v-if="!lot.is_favored" icon="heart"></b-icon>
        <b-icon v-else icon="heart-fill"></b-icon>
      </b-button>
    </div>
    <b-tooltip v-if="!is_authorized" :target="lot.uuid" container="artisioTimedAuctions">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {lotAddToWatchlist, lotRemoveFromWatchlist} from "@/triggers";
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  name: "LotAddToWatchlist",
  mixins: [lotHelperMixin],
  data: () => ({
    buttonDisabled: false
  }),
  props: {
    lot: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized']),
  },
  methods: {
    ...mapActions(["toggleWatchlist"]),
    async addRemoveWatchList() {
      if (!this.lot.is_favored) {
        lotAddToWatchlist(this.title)
      } else {
        lotRemoveFromWatchlist(this.title)
      }

      this.buttonDisabled = true;
      await this.toggleWatchlist({
        entity_name: 'lot',
        uuid: this.lot.uuid,
        is_favored: this.lot.is_favored,
      });
      this.buttonDisabled = false;
    }
  }
}
</script>

<style scoped>

</style>
