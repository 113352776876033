<template>
  <div v-if="mainConfig.enableAuth && !is_authorized" class="ta-lot-view-login-signup-buttons">
    <hr>
    <div class="row">
      <div class="col">
        <SignupButton />
      </div>
      <div class="col">
        <LoginButton />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import LoginButton from "@/components/lot-view/sidebar/LoginButton.vue";
import SignupButton from "@/components/lot-view/sidebar/SignupButton.vue";

export default {
  name: "LoginSignupButtons",
  components: {SignupButton, LoginButton},
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized'])
  }
}
</script>

<style lang="scss">
.artisio-webapp-page-auction-view-timed .ta-lot-view-login-signup-buttons {
  width: 320px;
  hr {
    display: none;
  }
}
</style>
