<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import lotBiddingMixin from '../../../mixins/lotBiddingMixin.js'
import CountDownTimer from "../../CountDownTimer";
import LatestBids from "../../lot-view/LatestBids";
import BidConfirmation from "../../lot-view/BidConfirmation";
import BuyNowConfirmation from "../../lot-view/BuyNowConfirmation";
import SocialShareIcons from "../../lot-view/SocialShareIcons.vue";
import LoginSignupButtons from "../../lot-view/sidebar/LoginSignupButtons";
import WatchlistButton from "../../lot-view/sidebar/WatchlistButton";
import WatchlistCount from "../../lot-view/sidebar/WatchlistCount";
import SocialSharePopup from "../../lot-view/sidebar/SocialSharePopup";
import BuyNowButton from "../../lot-view/sidebar/BuyNowButton";
import StreamBidButton from "../../lot-view/sidebar/StreamBidButton.vue";
import ToastNotification from "../../core/ToastNotification";
import LotViewDescription from "./LotViewDescription";
import MakeAnOfferButton from "./MakeAnOfferButton";
import {setLotBiddingStartedFinishedFlags} from "../../../store/mutations";
import BiddingLatestNotification from "../lot-bidding/BiddingLatestNotification";
import BiddingMaxBid from "../lot-bidding/BiddingMaxBid";
import BiddingMaxBidInput from "../lot-bidding/BiddingMaxBidInput";
import BiddingStartPrice from "../lot-bidding/BiddingStartPrice";
import BiddingEstimate from "../lot-bidding/BiddingEstimate";
import BiddingReserveNotMet from "../lot-bidding/BiddingReserveNotMet";
import BiddingInput from "../lot-bidding/BiddingInput";
import BiddingCurrentBid from "../lot-bidding/BiddingCurrentBid";
import BiddingLimit from "../lot-bidding/BiddingLimit.vue";
import BiddingBuyNowButton from "../lot-bidding/BiddingBuyNowButton";
import BiddingActions from "../lot-bidding/BiddingActions";
import PrivateTreatyActions from "./PrivateTreatyActions";
import BiddingAdditionalLinks from "../lot-bidding/BiddingAdditionalLinks";
import templatable from "@/mixins/templatable";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";
import LoginButton from "@/components/lot-view/sidebar/LoginButton.vue";
import SignupButton from "@/components/lot-view/sidebar/SignupButton.vue";
import LotStockNo from "@/components/configuration/lot-list-item/LotStockNo.vue";
import LotCurrentPrice from "@/components/configuration/lot-list-item/LotCurrentPrice.vue";
import LotCurrentIncrement from "@/components/configuration/lot-list-item/LotCurrentIncrement.vue";
import LotHammerTaxRate from "@/components/configuration/lot-list-item/LotHammerTaxRate.vue";
import LotBuyersPremiumTaxRate from "@/components/configuration/lot-list-item/LotBuyersPremiumTaxRate.vue";
import LotAuctionPremium from "@/components/configuration/lot-list-item/LotAuctionPremium.vue";
import auctionViewMixin from "@/mixins/auctionViewMixin";
import AuctionRegisterAsBidder from "@/views/AuctionViewPage/AuctionRegisterAsBidder.vue";
import AuctionTermsLink from "@/views/AuctionViewPage/AuctionTermsLink.vue";
import AuctionViewingInformationLink from "@/views/AuctionViewPage/AuctionViewingInformationLink.vue";
import AuctionCollectionInformationLink from "@/views/AuctionViewPage/AuctionCollectionInformationLink.vue";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: "LotViewBiddingTimed",
  mixins: [auctionViewMixin, lotBiddingMixin, templatable('lotBiddingTemplate'), dynamicFieldsMixin],
  components: {
    BiddingAdditionalLinks,
    PrivateTreatyActions,
    BiddingActions,
    BiddingBuyNowButton,
    BiddingCurrentBid,
    BiddingLimit,
    BiddingInput,
    BiddingReserveNotMet,
    BiddingEstimate,
    BiddingStartPrice,
    BiddingMaxBid,
    BiddingMaxBidInput,
    BiddingLatestNotification,
    MakeAnOfferButton,
    LotViewDescription,
    ToastNotification,
    BuyNowButton,
    StreamBidButton,
    WatchlistButton,
    WatchlistCount,
    SocialSharePopup,
    LoginSignupButtons,
    LoginButton,
    SignupButton,
    BuyNowConfirmation,
    BidConfirmation,
    LatestBids,
    CountDownTimer,
    SocialShareIcons,
    LotStockNo,
    LotCurrentPrice,
    LotCurrentIncrement,
    LotHammerTaxRate,
    LotBuyersPremiumTaxRate,
    LotAuctionPremium,
    AuctionRegisterAsBidder,
    AuctionTermsLink,
    AuctionViewingInformationLink,
    AuctionCollectionInformationLink
  },
  data() {
    return {
      newBidAmount: null,
      interval: null,
      template: `<div id="lotViewBidding" class="ta-lot-bidding">
                    <lot-third-party-url-button v-if="lot.third_party_url" />
                    <div v-else>
                        [[template]]
                    </div>
                  </div>
    `
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
    ...mapState(['timeDiff', 'signal', 'mainConfig']),
  },

  methods: {
    ...mapActions(['toggleWatchlist', 'loginHandler', 'signupHandler']),
    ...mapMutations(['setLotBiddingStartedFinishedFlags']),

    updateBiddingFlags() {
      const currentTime = getCurrentServerTime()

      let started = this.lot.actual_start_date < currentTime;
      let finished = this.lot.actual_end_date < currentTime && !this.lot.auction.is_paused;
      // console.log(this.lotStartDate.toString(), this.lotEndDate.toString());
      if (this.lot.status === 'unsold' || this.lot.status === 'sold' || this.lot.winning_bid && this.lot.winning_bid.buy_now) {
        finished = true;
      }

      this.setLotBiddingStartedFinishedFlags({started, finished})
    }
  },
  mounted() {
    this.newBidAmount = this.next;
    this.updateBiddingFlags();
    if (!this.biddingStarted || !this.biddingFinished) {
      this.interval = setInterval(() => {
        this.updateBiddingFlags();
        // if (this.biddingFinished) {
        //   clearInterval(this.interval);
        // }
      }, 1000);
    }
  },

  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  .ta-lot-bidding {

    hr {
      border-top: 3px solid white;
    }
  }

  .ta-btn-watchlist {
    position: static;
  }
}

</style>

<style scoped lang="scss">
@import "../../../bootstrap/import";

#lotViewBidding {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ta-lot-bidding-content {
  position: relative;
  background-color: $gray-100;
  padding: 1rem;
}

.ta-lot-bidding-timer {
  height: 40px;
}

.ta-lot-bidding {
  position: relative;
}

</style>
