<template>
  <a v-if="mainConfig.enableAuth && !is_authorized"
     @click="onSignup($event)"
     class="btn btn-primary ta-btn-signup btn-block justify-content-center d-flex align-items-center">
    <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
    </svg>
    {{ $t('Sign up') }}
  </a>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {openSignupAndPreserveUrl} from "@/helpers";

export default {
  name: "SignupButton",
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized'])
  },
  methods: {
    onSignup(ev) {
      openSignupAndPreserveUrl(ev)
    },
  }
}
</script>

<style scoped>

</style>
