<template>
  <div class="ta-auction-buyers-premium-link">
    <b-modal
      id="buyers-premium-modal"
      ref="buyersPremiumModal"
      :title="$t('Buyers Premium')"
      :visible="displayModal"
      @hidden="displayModal = false"
      static
      lazy
    >
      <div v-for="(premium, ind) of auction.premium_terms" :key="ind" class="d-flex justify-content-between">
        <div>{{ premium.percent }}% {{ $t('premium') }}</div>
        <div>{{ $t('Over') }} {{ premium.amount_over | asCurrency(auction.currency.code) }}</div>
      </div>
    </b-modal>
    <link-button @click="showModal()" icon="percent">
      <span class="ml-1 pr-5">{{ $t(`Buyers Premiums`) }}</span>
    </link-button>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionBuyersPremiumLink",
  components: {LinkButton},
  mixins: [auctionViewMixin],
  data: () => ({
    displayModal: false,
  }),
  methods: {
    showModal() {
      this.displayModal = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-auction-buyers-premium-link {
  margin-bottom: 1rem;
}
</style>
