<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      id="input-initials"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="required ? 'required' : ''"
    />
  </div>
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "Initials",
  components: {InputWidget, AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseInputMixin('initials')],
  props: {
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultLabel: this.$t("Initials")
    }
  }
}
</script>

<style scoped>

</style>
