<template>
  <div class="ta-lot-bidding-input" v-if="lot.currency && !(isLotSold || isLotUnsold)">
    <label v-if="label" class="ta-lot-bidding-input-label">
      <template v-if="label === true">{{ $t('Place bid') }}</template>
      <template v-else-if="label">{{ label }}</template>
    </label>
    <b-input-group class="ta-lot-bidding-input-group" :prepend="lot.currency.symbol">
      <b-form-input :id="`${idPrefix}new-bid-amount-input`" v-model="newBidAmount" :placeholder="$t('Your Bid')"></b-form-input>
      <b-input-group-append :id="`${idPrefix}place-bid-button-wrapper`" v-b-tooltip.hover>
        <b-button :id="`${idPrefix}place-bid-button`"
                  @click="onBidButtonClick($event)"
                  @mouseenter="onMouseEnter"
                  @mouseleave="onMouseLeave"
                  :disabled="biddingDisabled || disableBidding"
                  variant="primary"
                  class="ta-lot-bid-btn">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gavel"
               class="svg-inline--fa fa-gavel fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"></path>
          </svg>
          {{ $t('Place a Bid') }}
        </b-button>
        <upcoming-amount-popover
          :target="`${idPrefix}new-bid-amount-input`"
          :state-object="upcomingBidPopover"
          :text="$t('Current bid amount is invalid. Please bid one of the following amounts') + ':'"
          :currency-code="lot.currency.code"
          @hidePopover="hideUpcomingAmountPopover('upcomingBidPopover')"
          @setNewAmount="bidUpcomingAmount"
        />
        <b-tooltip v-if="biddingDisabled" container="artisioTimedAuctions" :target="`${idPrefix}place-bid-button-wrapper`">
          <template v-if="!is_authorized">
            {{ $t('Please login to make a bid') }}
          </template>
          <template v-else-if="!biddingStarted">
            {{ $t('Lot is not open yet') }}
          </template>
          <template v-else-if="biddingFinished">
            {{ $t('Lot has ended') }}
          </template>
          <template v-else-if="!lot.auction.is_bidder">
            {{ $t('Please first register as bidder') }}
          </template>
          <template v-else-if="isCurrentUserLastBidder">
            {{ $t('You are the latest bidder') }}
          </template>
        </b-tooltip>
      </b-input-group-append>
    </b-input-group>

    <BidConfirmation :id="`${idPrefix}bid-confirmation-popover`"
                     v-if="isBidder && !doNotShowConfirmOnBid[lot.uuid]"
                     :show.sync="showBidConfirmation"
                     :target="`${idPrefix}place-bid-button`"
                     :for-mobile="forMobile"
                     :lot="lot"
                     :bid-amount="parseFloat(newBidAmount)"
                     :container="`${idPrefix}lotViewBidding`"
                     @confirm="bidConfirmed($event)"
                     @no="onBidConfirmationNo"
                     @close="onBidConfirmationClose" />
  </div>
</template>

<script>
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";
import BidConfirmation from "../../lot-view/BidConfirmation";
import {mapMutations, mapState} from "vuex";
import UpcomingAmountPopover from "../../lot-view/UpcomingAmountPopover";
import lotHelperMixin from "@/mixins/lotHelperMixin";
import {
  placeBid,
  placeBidConfirmClose,
  placeBidConfirmNo,
  placeBidConfirmYes,
} from "@/triggers";
import i18n from "@/i18n";

export default {
  name: "BiddingInput",
  components: {UpcomingAmountPopover, BidConfirmation},
  mixins: [lotBiddingMixin, lotHelperMixin],
  props: {
    label: {
      type: [String, Boolean],
      default: false
    },
    forMobile: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    newBidAmount: null,
    showBidConfirmation: false,
    disableBidding: false,
  }),
  computed: {
    ...mapState(['doNotShowConfirmOnBid']),
    idPrefix() {
      return this.forMobile ? 'mobile_' : ''
    },
    isBidder() {
      return !((this.lot.auction.manual_bidder_approval || this.lot.auction.initial_deposit_amount || this.lot.auction.require_credit_card_verification) && !this.lot.auction.is_bidder)
    }
  },
  watch: {
    next: {
      immediate: true,
      handler(newVal) {
        this.newBidAmount = newVal;
      }
    }
  },
  methods: {
    ...mapMutations(['markDoNotShowConfirmOnBid', 'showModalMsgBox']),
    onBidButtonClick(ev) {
      placeBid(this.title, this.newBidAmount)
      if (!this.isBidder) {
        this.showModalMsgBox({
          title: i18n.t('Error'),
          content: i18n.t('You are not registered as bidder.')
        });
        return;
      }
      if (this.doNotShowConfirmOnBid[this.lot.uuid]) {
        this.hideUpcomingAmountPopover('upcomingBidPopover');
        this.tempDisableButton();
        this.place_bid();
      } else {
        this.showBidConfirmation = !this.showBidConfirmation;
      }
    },
    onMouseEnter() {
      // placeBidMouseEnter(this.title)
    },
    onMouseLeave() {
      // placeBidMouseLeave(this.title)
    },
    bidConfirmed(dontAsk) {
      placeBidConfirmYes(this.title, this.newBidAmount)
      if (dontAsk) {
        console.log("Don't ask ", dontAsk);
        this.markDoNotShowConfirmOnBid(this.lot.uuid);
      }
      this.tempDisableButton();
      this.place_bid();
    },
    tempDisableButton() {
      this.disableBidding = true;
      setTimeout(() => {
        this.disableBidding = false;
      }, 500);
    },
    bidUpcomingAmount(amount) {
      this.newBidAmount = amount;
      this.onBidButtonClick();
    },
    onBidConfirmationNo() {
      placeBidConfirmNo(this.title, this.newBidAmount)
    },
    onBidConfirmationClose() {
      placeBidConfirmClose(this.title)
    }
  },
}
</script>

<style lang="scss" scoped>

.ta-lot-bid-btn {
  align-items: center;
  display: inline-flex;
  justify-content: center;

  svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 0.5rem;
    width: 16px;
  }
}

.ta-lot-bid-input {
  position: relative;
}

</style>
