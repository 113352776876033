<template>
  <div class="col-sm-12 col-md">
    <abstract-profile-text-input
      id="input-document-expiration-date"
      type="date"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="required ? 'required' : ''"
    />
  </div>
</template>

<script>
import AbstractProfileTextInput from "../core/AbstractProfileTextInput";
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import {mapState} from "vuex";

export default {
  name: "DocumentExpirationDate",
  components: {AbstractProfileTextInput},
  mixins: [BaseInputMixin('identification.document_expiry_date')],
  data() {
    return {
      defaultLabel: this.$t("Document Expiration Date")
    }
  },
}
</script>

<style scoped>

</style>
