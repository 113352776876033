import {mapState} from "vuex";
import lotBiddingBaseMixin from "@/mixins/lotBiddingBaseMixin";

export default {
  mixins: [lotBiddingBaseMixin],
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
      biddingStarted: state => state.lotObject.biddingStarted,
      biddingFinished: state => state.lotObject.biddingFinished,
    }),
  }
}
