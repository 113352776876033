import { render, staticRenderFns } from "./AuctionPremium.vue?vue&type=template&id=3318668c&scoped=true&"
import script from "./AuctionPremium.vue?vue&type=script&lang=js&"
export * from "./AuctionPremium.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3318668c",
  null
  
)

export default component.exports