<template>
  <div class="ta-lot-list-item-thumb">
    <router-link :to="lotInnerPageRoute">
      <b-carousel
          v-if="thumbnailSrc"
          controls
          :interval="0"
      >
        <b-carousel-slide v-for="url of lot.image_urls" :key="url"
            :img-src="url"
        ></b-carousel-slide>
      </b-carousel>
      <img v-else src="https://cdn.artisio.co/no_image.jpg">
    </router-link>
  </div>
</template>

<script>
import lotHelperMixin from "@/mixins/lotHelperMixin";
import thumbnailMixin from "@/mixins/thumbnailMixin";

export default {
  name: "LotListItemCarousel",
  mixins: [lotHelperMixin, thumbnailMixin],
  data: () => ({
    slide: 0,
  }),
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
