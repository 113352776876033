<template>
  <b-modal
    v-model="lots.lotInquiryModal.show"
    id="lots_inquiry_modal"
    :title="$t('Send your inquiry')"
    modal-class="modal fade show block"
    @hidden="hideModal"
    role="dialog"
    size="lg"
    :ok-title="$t('Send inquiry')"
    @ok="sendInquiry"
    static
    lazy
  >
    <div v-if="lot" class="row mb-3 align-items-center">
      <div class="col-sm-3">
        <b-img v-if="lot.image_urls.length" :src="lot.image_urls[0]"
               :alt="$options.filters.stripTags($options.filters.currentLocale(lot.title))"
               fluid
               thumbnail/>
      </div>
      <h3 class="col-sm-9" v-html="$options.filters.stripTags($options.filters.currentLocale(lot.title))">
      </h3>
    </div>
    <b-form-textarea
      v-model="reply"
      :placeholder="$t('Add your inquiry')"
      rows="3"
      no-resize
    />
  </b-modal>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {sendInquiry} from "@/services/utils";
import logger from "@/logger";
import i18n from "@/i18n";
import {asCurrency} from "@/filters/currency";


export default {
  name: "LotInquiryModal",
  computed: {
    ...mapState(['lots']),
    lot() {
      return this.lots.lotInquiryModal.lot
    }
  },
  data() {
    return {
      reply: ''
    }
  },
  methods: {
    ...mapMutations(['hideLotInquiryModal', 'showNotification', 'showModalMsgBox']),
    hideModal() {
      this.reply = ''
      this.hideLotInquiryModal(this.lots)
    },

    async sendInquiry() {

      const requestData = {
        lot_uuid: this.lots.lotInquiryModal.lot.uuid,
        inquiry: this.reply
      }

      try {
        const response = await sendInquiry(requestData)

        if (response.status === 200) {
          this.showNotification({
            title: i18n.t("Your question was submitted"),
            text: i18n.t(response.data.message),
            variant: "success",
          });
          this.reply = ''
          this.hideLotInquiryModal(this.lots)
        } else {
          this.showModalMsgBox({
            title: i18n.t('Unable to submit a question'),
            content: i18n.t(response.data.messages || 'Due to technical issues, it was not possible to submit a question. Try again in a few minutes.'),
            variant: 'danger'
          });
        }

      } catch (e) {
        logger.error(e)
      }
    }
  },
}
</script>
