<template>
  <div>
    <router-view></router-view>

    <confirm-modal/>
  </div>
</template>

<script>
import uuid from 'uuid';
import ConfirmModal from "@/plugins/confirm-modal/ConfirmModal.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {setLotResults} from "@/store/mutations";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: 'DefaultLayout',
  components: {ConfirmModal},
  data() {
    return {
      lotCountDownInterval: null,
      serverTimeInterval: null
    }
  },
  computed: {
    ...mapState(['lots', 'socketLastInteractionTime']),
  },
  methods: {
    ...mapActions(['socketGetServerTime', 'socketOpen']),
    ...mapMutations(['setLotResults']),
    updateLotsBiddingStartedFinished() {
      const newLots = this.lots.results.map(lot => {
        const currentTime = getCurrentServerTime()

        let started = lot.actual_start_date < currentTime;
        let finished = lot.actual_end_date < currentTime;
        // console.log(this.lotStartDate.toString(), this.lotEndDate.toString());
        if (lot.status === 'unsold' || lot.status === 'sold' || lot.winning_bid && lot.winning_bid.buy_now) {
          finished = true;
        }

        if (lot.biddingStarted !== started || lot.biddingFinished !== finished) {
          lot.hash = uuid.v4()
        }
        lot.biddingStarted = started;
        lot.biddingFinished = finished

        return lot
      })

      this.setLotResults(newLots)
    }
  },
  mounted() {
    this.lotCountDownInterval = setInterval(this.updateLotsBiddingStartedFinished, 1000)
    this.serverTimeInterval = setInterval(this.socketGetServerTime, 15000);

    // Send get server time immediately on mounted
    this.socketOpen().then(this.socketGetServerTime)
  },
  destroyed() {
    clearInterval(this.lotCountDownInterval);
    clearInterval(this.serverTimeInterval)
  },
}
</script>

<style>

</style>
