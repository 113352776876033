export default {
  computed: {
    auctionViewRoute() {
      let routeObj = {};
      if (this.$route.name === 'PrivateTreatyAuctions') {
        routeObj = {name: 'PrivateTreatyAuctionView', params: {uuid: this.auction.uuid}}
      } else {
        let tmp = {}
        if (this.auction.status === 'completed') {
          tmp = {query: {lot_status: 'all'}}
        }
        routeObj = {name: 'AuctionViewTimed', params: {uuid: this.auction.uuid}, ...tmp}
      }

      const resolvedRoute = this.$router.resolve(routeObj);
      return resolvedRoute.route.fullPath;
    },
    imageSrc() {
      if (!this.auction.images || !this.auction.images.length) {
        return 'https://cdn.artisio.co/no_image.jpg';
      }
      return this.auction.images[0].sm.url;
    },
    title() {
      return this.$options.filters.stripTags(this.$options.filters.currentLocale(this.auction.title)).trim().replace(/^&nbsp;/, '')
    }
  },
}
