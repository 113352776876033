<template>
  <small class="ta-lot-stockno">
    <label v-if="label === true" class="ta-lot-view-stockno-label">{{ $t('Stock No') }}: &nbsp;</label>
    <label v-else-if="label"  class="ta-lot-view-stockno-label">{{ label }}</label>
    <span class="ta-lot-stockno-value">{{ lotObj.stock_no }}</span>
  </small>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotStockNo",
  props: {
    label: {
      type: [Boolean, String],
      default: true
    },
    lot: {
      required: false,
      type: [Object, Boolean],
      default: () => false
    },
  },
  computed: {
    ...mapState({lotVuex: state => state.lotObject.data}),
    lotObj() {
      return this.lot || this.lotVuex
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

  .ta-lot-stockno {
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: block;
  }

  .ta-lot-ID {
    color: $primary;
    font-weight: bold;
  }
</style>
