<template>
  <filter-group :title="$t('Auction End Date')"
                id="collapse-auction-dates"
                class="ta-filter-group"
  >
    <div class="d-flex justify-content-between align-items-center">
      <b-form-input type="date" placeholder="From Date" size="sm" class="ta-filter-auction-date-from"
                    v-model="dateFrom"
                    @change="filtersChanged"/>
      &ndash;
      <b-form-input type="date" placeholder="To Date" size="sm" class="ta-filter-auction-date-to"
                    v-model="dateTo"
                    @change="filtersChanged"/>
    </div>
  </filter-group>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {AUCTION_LIST_DEFAULT_PAGE} from "../../constants";
import {updateAuctionsRoute} from "../../helpers";
import FilterGroup from "@/components/FilterGroup";
import {auctionChangeFilters} from "@/triggers";

export default {
  name: "FilterAuctionDate",
  components: {FilterGroup},
  data: () => ({
    dateFrom: null,
    dateTo: null,
  }),
  computed: {
    ...mapState(['auctionQueryParams']),
    ...mapGetters(['is_authorized'])
  },
  watch: {
    auctionQueryParams() {
      this.dateFrom = this.auctionQueryParams.end_date_from;
      this.dateTo = this.auctionQueryParams.end_date_to;
    },
  },
  methods: {
    ...mapMutations(['setAuctionFilters']),
    filtersChanged() {
      this.setAuctionFilters({
        end_date_from: this.dateFrom,
        end_date_to: this.dateTo,
        page: AUCTION_LIST_DEFAULT_PAGE,
      });
      updateAuctionsRoute();
      auctionChangeFilters()
    }
  },
  mounted() {
    this.dateFrom = this.auctionQueryParams.end_date_from;
    this.dateTo = this.auctionQueryParams.end_date_to;
  }
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions .form-control.ta-filter-auction-date-from,
#artisioTimedAuctions .form-control.ta-filter-auction-date-to {
  width: 130px;
}
</style>
