export default function(componentName) {
  return {
    data: () => ({
      timeoutId: null,
    }),
    watch: {
      contentLoaded: {
        handler() {

          if (!this.contentLoaded) {
            return
          }

          this.scrollTo()
        },
        immediate: true
      }
    },
    methods: {
      onScrollChange() {
        if (!this.contentLoaded) {
          return;
        }
        clearTimeout(this.timeoutId);

        this.timeoutId = setTimeout(() => {
          window.localStorage.setItem(`${componentName}.scrollPosition`, window.scrollY)
        }, 200);
      },
      scrollTo() {
        const dontRecoverScroll = parseInt(localStorage.getItem('artisio.dontRecoverScroll'))
        if (dontRecoverScroll) {
          localStorage.removeItem('artisio.dontRecoverScroll')
          return;
        }
        // Retrieve the scroll position from local storage
        const scrollPosition = localStorage.getItem(`${componentName}.scrollPosition`);

        // Set the scroll position back to where it was
        if (scrollPosition) {
          this.$nextTick(() => {
            window.scrollTo(0, parseInt(scrollPosition));
          })
        } else {
          const html = document.querySelector('html')

          const el = document.querySelector('#artisioTimedAuctions')
          if (el.offsetTop < html.scrollTop || el.offsetTop > html.scrollTop + window.outerHeight) {
            html.scrollTop = el.offsetTop;
          }
        }
      }
    },
    beforeMount() {
      this.scrollTo()
      window.addEventListener('scroll', this.onScrollChange)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScrollChange)
    },
  }
}
