<script>
import {mapActions, mapState, mapGetters} from "vuex";
import auctionHelperMixin from "@/mixins/auctionHelperMixin.js";
import AuctionImage from "@/views/AuctionListPage/list-item/AuctionImage";
import AuctionNo from "@/views/AuctionListPage/list-item/AuctionNo";
import AuctionBiddingOpen from "@/views/AuctionListPage/list-item/AuctionBiddingOpen";
import NumberOfLots from "@/views/AuctionListPage/list-item/NumberOfLots";
import AuctionTitle from "@/views/AuctionListPage/list-item/AuctionTitle";
import AuctionType from "@/views/AuctionListPage/list-item/AuctionType";
import AuctionAddToWatchlist from "@/views/AuctionListPage/list-item/AuctionAddToWatchlist";
import AuctionCountdownTimer from "@/views/AuctionListPage/list-item/AuctionCountdownTimer.vue";
import templatable from "@/mixins/templatable";
import AuctionPauseMessage from "@/views/AuctionListPage/list-item/AuctionPauseMessage.vue";
import AuctionCarousel from "@/views/AuctionListPage/list-item/AuctionCarousel.vue";
import RegisterAsBidderButton from "@/views/AuctionViewPage/RegisterAsBidderButton.vue";
import AuctionDescription from "@/views/AuctionListPage/list-item/AuctionDescription.vue";
import AuctionLocationInformation from "@/views/AuctionViewPage/AuctionLocationInformation.vue";

export default {
  name: "AuctionListItem",
  components: {
    AuctionAddToWatchlist,
    AuctionTitle,
    AuctionType,
    NumberOfLots,
    AuctionNo,
    AuctionImage,
    AuctionCarousel,
    AuctionBiddingOpen,
    AuctionPauseMessage,
    AuctionCountdownTimer,
    RegisterAsBidderButton,
    AuctionDescription,
    AuctionLocationInformation
  },
  mixins: [auctionHelperMixin, templatable('auctionListItemTemplate')],
  props: {
    auction: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    template: `
      <div class="ta-auction-list-item">
        <div class="ta-auction-list-item-inner">
            [[template]]
        </div>
      </div>
    `
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized']),

    redirectBtnTxt() {
      return this.is_authorized ? this.$t('View Lots') : this.$t('Login to bid');
    }
  },
  methods: {
    ...mapActions(['toggleWatchlist']),

    redirect() {
      if (!this.is_authorized) {
        window.location.replace(this.mainConfig.loginUrl);
      } else {
        this.$router.push({
          name: 'AuctionView',
          params: {uuid: this.auction.uuid}
        });
      }
    }
  },
}
</script>


<style lang="scss">
@import "../../bootstrap/import.scss";

#artisioTimedAuctions {
  .ta-auction-list-item {
    width: 100%;
    border-top: 1px solid $gray-300;
    background-color: white;

    &:hover {
      .ta-auction-list-item-inner {
        background-color: #f1f7fc;
      }
    }
  }

  .ta-auction-list-item {
    .ta-auction-no-info {
      display: flex;
      flex-direction: column;
    }
  }

  .ta-auction-list-item-inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    height: 100%;
  }

  .ta-btn-watchlist {
    position: relative;
    top: -0.5rem;
    right: -0.5rem;
  }

  .ta-auction-list.view-type-grid {
    .ta-auction-list-item {
      //width: 280px;
      border: 1px solid $gray-300;
      .ta-auction-no-info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
    }

    .ta-auction-start-price {
      width: 100%;
    }

    .ta-auction-list-item-inner {
      flex-direction: column;
      align-items: initial;
      padding: 0;
    }

    .ta-auction-list-item-thumb {
      img,
      .b-skeleton-img {
        width: 100% !important;
      }
    }

    .ta-btn-watchlist {
      top: 0;
      right: 0;
      left: -0.5rem;
      bottom: -0.5rem;
    }

    .ta-auction-list-item-desc {
      padding-bottom: 0;
    }

    .ta-auction-list-item-buttons {
      width: auto;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .ta-auction-list-item-title {
    font-size: $font-size-base;

    a {
      color: $gray-700;
      text-decoration: none;

      &:hover {
        color: $gray-900;
      }
    }

    /deep/ p {
      display: inline;
    }
  }

  .ta-auction-list-item-thumb {
    position: relative;

    img {
      width: 240px;
      height: 200px;
      object-fit: cover;
    }
  }

  .ta-auction-list-item-price {
    font-size: $font-size-lg;
    color: #007bff;
    font-weight: bold;
    line-height: 1;
  }

  .ta-auction-list-item-or {
    position: relative;
    text-align: center;
    width: 160px;
    height: 26px;

    &:before,
    &:after {
      content: " ";
      height: 2px;
      width: 40px;
      position: absolute;
      top: 14px;
      background-color: $gray-200;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .ta-auction-list-item-desc {
    flex: 1;
    padding: 0.75rem 1rem;
  }

  .ta-auction-list-item-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    padding: 0.75rem 1rem;
  }

  .ta-lot-last-bid {
    position: absolute;
    left: -0.75rem;
    top: 0.75rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    background: $primary;
    color: $gray-100;
    padding: 0.25rem 0.75rem;
  }

  @include media-breakpoint-down(sm) {
    .view-type-list {
      .ta-auction-list-item-thumb {
        img {
          width: 180px;
          height: 120px;
        }
      }

      .ta-auction-list-item-desc {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      .ta-auction-list-item-title,
      .ta-auction-list-item-info {
        line-height: 1.25rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .view-type-list {
      .ta-auction-list-item {
        border: 1px solid $gray-300;
      }


      .ta-lot-start-price,
      .ta-lotno-bids-info {
        width: 100%;
      }

      .ta-auction-list-item-inner {
        flex-direction: column;
        align-items: initial;
        padding: 0;
      }

      .ta-auction-list-item-thumb {
        img {
          width: 100%;
          max-width: 100%;
          display: block;
          margin: 0 auto;
          height: 220px;
        }
      }

      .ta-auction-list-item-desc {
        padding: 0.75rem 0.75rem 0;
      }

      .ta-auction-list-item-title,
      .ta-auction-list-item-info,
      .ta-auction-list-item-price {
        line-height: $line-height-base;
      }


      .ta-auction-list-item-buttons {
        padding-top: 0;
        width: auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .ta-btn-watchlist {
        top: 0;
        right: 0;
        left: -0.5rem;
        bottom: -0.5rem;
      }
    }
  }
}
</style>
<style scoped lang="scss">
#artisioTimedAuctions {

  .ta-countdown {
    // position: absolute;
    bottom: 1rem;
    right: 0;
  }
}
</style>
