import Vue from 'vue';

import LotAddToWatchlist from './lot-list-item/LotAddToWatchlist.vue'
import LotCollectionInformation from './lot-list-item/LotCollectionInformation.vue'
import LotCountdownTimer from './lot-list-item/LotCountdownTimer.vue'
import LotActualPrice from './lot-list-item/LotActualPrice.vue'
import LotCurrentPrice from './lot-list-item/LotCurrentPrice.vue'
import LotLastBidAmount from './lot-list-item/LotLastBidAmount.vue'
import LotBuyNowPrice from './lot-list-item/LotBuyNowPrice.vue'
import LotListItemImage from './lot-list-item/LotListItemImage.vue'
import LotListItemTitle from './lot-list-item/LotListItemTitle.vue'
import LotNo from './lot-list-item/LotNo.vue'
import LotStartPrice from './lot-list-item/LotStartPrice.vue'
import LotWinningStatus from './lot-list-item/LotWinningStatus.vue'
import LotEstimates from './lot-list-item/LotEstimates.vue'
import LotNumberOfBids from './lot-list-item/LotNumberOfBids.vue'

// import AuctionAddToWatchlist from './auction-list-item/AuctionAddToWatchlist.vue'
// import AuctionCollectionInformation from './auction-list-item/AuctionCollectionInformation.vue'
// import AuctionCountdownTimer from './auction-list-item/AuctionCountdownTimer.vue'
// import AuctionActualPrice from './auction-list-item/AuctionActualPrice.vue'
// import AuctionBuyNowPrice from './auction-list-item/AuctionBuyNowPrice.vue'
// import AuctionListItemImage from './auction-list-item/AuctionImage.vue'
// import AuctionListItemTitle from './auction-list-item/AuctionTitle.vue'
// import AuctionNo from './auction-list-item/AuctionNo.vue'
// import AuctionStartPrice from './auction-list-item/AuctionStartPrice.vue'
// import AuctionNumberOfBids from './auction-list-item/AuctionNumberOfBids.vue'

import FilterCategoryList from './lot-filters/FilterCategoryList.vue'
import FilterLocationList from './lot-filters/FilterLocationList.vue'
import FilterDepartmentList from './lot-filters/FilterDepartmentList.vue'
import FilterDynamicFields from './lot-filters/FilterDynamicFields.vue'
import FilterEstimateRange from './lot-filters/FilterEstimateRange.vue'
import FilterLotRange from './lot-filters/FilterLotRange.vue'
import FilterReserveRange from './lot-filters/FilterReserveRange.vue'
import FilterPriceRange from './lot-filters/FilterPriceRange.vue'
import FilterWithImage from './lot-filters/FilterWithImage.vue'

import JumpToLot from './lot-list/JumpToLot.vue'
import LotListFilterMobileButton from './lot-list/LotListFilterMobileButton.vue'
import LotListFilters from './lot-list/LotListFilters.vue'
import LotListItems from './lot-list/LotListItems.vue'
import LotListPaginationButtons from './lot-list/LotListPaginationButtons.vue'
import LotListPaginationSummary from './lot-list/LotListPaginationSummary.vue'
import LotListPerPageSelect from './lot-list/LotListPerPageSelect.vue'
import LotListSwitchView from './lot-list/LotListSwitchView.vue'
import SortingDropdown from './lot-list/SortingDropdown.vue'
import LotKeywordSearch from './lot-list/LotKeywordSearch.vue'
import LotListItemsCount from './lot-list/LotListItemsCount.vue'

// import AuctionListFilters from './auction-list/AuctionListFilters.vue'
// import AuctionListFilterMobileButton from './auction-list/AuctionListFilterMobileButton.vue'
// import AuctionListItems from './auction-list/AuctionListItems.vue'
// import AuctionListPaginationButtons from './auction-list/AuctionListPaginationButtons.vue'
// import AuctionListPaginationSummary from './auction-list/PaginationSummary.vue'
// import AuctionListPerPageSelect from './auction-list/PerPageSelect.vue'
// import AuctionListSwitchView from './auction-list/SwitchView.vue'
// import AuctionSortingDropdown from './auction-list/SortingDropdown.vue'
// import AuctionKeywordSearch from './auction-list/AuctionKeywordSearch.vue'
import AuctionListItemsCount from './auction-list/AuctionListItemsCount.vue'

import LotViewBidding from './lot-view/LotViewBidding.vue';
import LotViewBreadcrumbs from './lot-view/LotViewBreadcrumbs.vue';
import LotViewImages from './lot-view/LotViewImages.vue';
import LotViewLotNo from './lot-view/LotViewLotNo.vue';
import LotViewPrevNextButtons from './lot-view/LotViewPrevNextButtons.vue';
import LotViewSocialShare from './lot-view/LotViewSocialShare.vue';
import LotViewTitle from './lot-view/LotViewTitle.vue';
import LotViewNft from './lot-view/LotViewNft.vue';
import LotViewHeader from './lot-view/LotViewHeader.vue';
import LotViewDescription from './lot-view/LotViewDescription.vue';
import MakeAnOfferButton from './lot-view/MakeAnOfferButton.vue';
import MakeAnEnquiryButton from './lot-view/MakeAnEnquiryButton.vue';
import ShowAllBidsLink from './lot-view/ShowAllBidsLink.vue';
import LotThirdPartyUrlButton from './lot-view/LotThirdPartyUrlButton.vue';
import FilterLotStatus from "./lot-filters/FilterLotStatus";
import FavoriteLotListItems from "./lot-list/FavoriteLotListItems";
import IMadeBidLotListItems from "./lot-list/IMadeBidLotListItems.vue";
import LotViewButton from "@/components/configuration/lot-list-item/LotViewButton";
import LotNotifyStartButton from "@/components/configuration/lot-list-item/LotNotifyStartButton";
import LotInquiryButton from "@/components/lot-view/sidebar/LotInquiryButton.vue";

Vue.component('LotAddToWatchlist', LotAddToWatchlist)
Vue.component('LotNotifyStartButton', LotNotifyStartButton)
Vue.component('LotCollectionInformation', LotCollectionInformation)
Vue.component('LotCountdownTimer', LotCountdownTimer)
Vue.component('LotActualPrice', LotActualPrice)
Vue.component('LotCurrentPrice', LotCurrentPrice)
Vue.component('LotLastBidAmount', LotLastBidAmount)
Vue.component('LotBuyNowPrice', LotBuyNowPrice)
Vue.component('LotListItemImage', LotListItemImage)
Vue.component('LotViewButton', LotViewButton)
Vue.component('LotListItemTitle', LotListItemTitle)
Vue.component('LotNo', LotNo)
Vue.component('LotStartPrice', LotStartPrice)
Vue.component('LotWinningStatus', LotWinningStatus)
Vue.component('LotEstimates', LotEstimates)
Vue.component('LotNumberOfBids', LotNumberOfBids)

Vue.component('FilterCategoryList', FilterCategoryList);
Vue.component('FilterLocationList', FilterLocationList);
Vue.component('FilterDepartmentList', FilterDepartmentList);
Vue.component('FilterDynamicFields', FilterDynamicFields);
Vue.component('FilterEstimateRange', FilterEstimateRange);
Vue.component('FilterLotRange', FilterLotRange);
Vue.component('FilterPriceRange', FilterPriceRange);
Vue.component('FilterReserveRange', FilterReserveRange);
Vue.component('FilterWithImage', FilterWithImage);
Vue.component('FilterLotStatus', FilterLotStatus);

Vue.component('JumpToLot', JumpToLot)
Vue.component('LotListFilterMobileButton', LotListFilterMobileButton)
Vue.component('LotListFilters', LotListFilters)
Vue.component('LotListItems', LotListItems)
Vue.component('FavoriteLotListItems', FavoriteLotListItems)
Vue.component('IMadeBidLotListItems', IMadeBidLotListItems)
Vue.component('LotListPaginationButtons', LotListPaginationButtons)
Vue.component('LotListPaginationSummary', LotListPaginationSummary)
Vue.component('LotListPerPageSelect', LotListPerPageSelect)
Vue.component('LotListSwitchView', LotListSwitchView)
Vue.component('SortingDropdown', SortingDropdown)
Vue.component('LotKeywordSearch', LotKeywordSearch)
Vue.component('LotListItemsCount', LotListItemsCount)

Vue.component('LotViewBidding', LotViewBidding);
Vue.component('LotViewBreadcrumbs', LotViewBreadcrumbs);
Vue.component('LotViewImages', LotViewImages);
Vue.component('LotViewLotNo', LotViewLotNo);
Vue.component('LotViewPrevNextButtons', LotViewPrevNextButtons);
Vue.component('LotViewSocialShare', LotViewSocialShare);
Vue.component('LotViewTitle', LotViewTitle);
Vue.component('LotViewNft', LotViewNft);
Vue.component('LotViewHeader', LotViewHeader);
Vue.component('LotViewDescription', LotViewDescription);
Vue.component('ShowAllBidsLink', ShowAllBidsLink);
Vue.component('LotThirdPartyUrlButton', LotThirdPartyUrlButton);
Vue.component('MakeAnOfferButton', MakeAnOfferButton);
Vue.component('MakeAnEnquiryButton', MakeAnEnquiryButton);

// Vue.component('AuctionListFilters', AuctionListFilters);
// Vue.component('AuctionListFilterMobileButton', AuctionListFilterMobileButton);
// Vue.component('AuctionListItems', AuctionListItems);
// Vue.component('AuctionListPaginationButtons', AuctionListPaginationButtons);
// Vue.component('AuctionListPaginationSummary', AuctionListPaginationSummary);
// Vue.component('AuctionListPerPageSelect', AuctionListPerPageSelect);
// Vue.component('AuctionListSwitchView', AuctionListSwitchView);
// Vue.component('AuctionSortingDropdown', AuctionSortingDropdown);
// Vue.component('AuctionKeywordSearch', AuctionKeywordSearch);
Vue.component('AuctionListItemsCount', AuctionListItemsCount);
Vue.component('LotInquiryButton', LotInquiryButton)