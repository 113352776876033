<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="ta-page-invoices">
      <PageHeader :title="$t('Invoices')"></PageHeader>
      <div class="d-flex justify-content-start">
        <div class="ta-invoice-list">
          <div class="ta-invoice-list-header">
            <div class="ta-per-page-wrapper">
              {{ $t("Show") }}
              <b-form-select
                class="mx-2"
                v-model="limit"
                @change="updatePagination"
                :options="perPageOptions"
              ></b-form-select>
              {{ $t("entries") }}
            </div>
            <div class="ta-invoice-search-wrapper d-flex align-items-center">
              {{ $t("Search") }}:
              <b-form-input
                :placeholder="$t('Search for invoices')"
                class="ml-2"
                v-model="filters.q"
                v-debounce:300ms="updateRoute"
              ></b-form-input>
            </div>
          </div>
          <div v-if="invoices.loading" class="ta-invoice-loader">
            <b-spinner label="Spinning"></b-spinner>
            <span class="mt-2">{{ $t("Loading...") }}</span>
          </div>
          <template v-if="!invoices.loading">
            <div class="ta-invoice-list-wrapper">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th scope="col">
                    {{ $t("Invoice No") }}
                  </th>
                  <th scope="col">
                    {{ $t("Sale") }}
                  </th>
                  <th scope="col">
                    {{ $t("Date") }}
                  </th>
                  <th scope="col">
                    {{ $t("Total Amount/Total Outstanding") }}
                  </th>
                  <th scope="col">
                    {{ $t("Status") }}
                  </th>
                  <th scope="col">
                    {{ $t("Shipping Status") }}
                  </th>
                  <th scope="col">
                    {{ $t("Actions") }}
                  </th>
                </tr>
                </thead>
                <tbody v-if="invoices.results.length">
                <tr v-for="invoice in invoices.results" :key="invoice.id">
                  <td>
                    <a href="javascript:void(0)" class="btn-invoice-view" @click="view(invoice)">
                      {{ invoice.invoice_no }}
                    </a>
                  </td>
                  <td>
                    {{ invoice.auction ? invoice.auction.sale_no : '' }}
                  </td>
                  <td>
                    {{ invoice.date | datetime }}
                  </td>
                  <td>
                    {{
                      invoice.total_amount | asCurrency(invoice.currency.code)
                    }}/{{ invoice.outstanding_amount | asCurrency(invoice.currency.code) }}
                  </td>
                  <td>
                    <span
                      class="badge ta-uc-first"
                      :class="{
                        'badge-warning': invoice.status === 'unpaid',
                        'badge-danger': invoice.status === 'cancelled',
                        'badge-success': invoice.status === 'paid',
                      }"
                    >{{ invoice.status }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="badge badge-info"
                      v-if="invoice.is_dispatched"
                    >{{ $t("Dispatched") }}</span
                    >
                    <span
                      class="badge badge-success ta-uc-first"
                      v-else-if="invoice.shipping_status === 'paid'"
                    >{{ invoice.shipping_status }}</span
                    >
                    <span
                      class="badge badge-danger"
                      v-else-if="invoice.shipping_status === 'unpaid'"
                    >{{ invoice.shipping_status }}</span
                    >
                    <span class="badge badge-secondary" v-else>N/A</span>
                  </td>
                  <td class="col-actions text-nowrap">
                    <template v-if="invoice.status === 'unpaid'">
                      <b-button v-if="invoice.payment_providers.length <= 1"
                                :disabled="!invoice.payment_providers.length"
                                @click="getInvoiceUrl(invoice.uuid, invoice.payment_providers.length ? invoice.payment_providers[0].name : null)"
                                variant="primary" size="sm" class="btn-invoice-pay mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px;" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                        </svg>
                        {{ $t('Checkout') }}
                      </b-button>

                      <b-dropdown v-else variant="primary" size="sm"
                                  class="btn-invoice-pay mr-2">
                        <template #button-content>
                          <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px;" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                          </svg>
                          <span class="ta-button-text">{{ $t('Checkout') }}</span>
                        </template>
                        <b-dropdown-item v-for="provider in invoice.payment_providers" :key="provider.name"
                                         @click="getInvoiceUrl(invoice.uuid, provider.name)">
                          {{ $t('with') }} {{ provider.name[0].toUpperCase() + provider.name.slice(1) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <button class="btn btn-sm btn-secondary btn-pdf-view mr-2" @click="view(invoice)">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px; display: inline-block" fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                      </svg>
                      <span class="ta-button-text">{{ $t("View") }}</span>
                    </button>
                    <button class="btn btn-sm btn-secondary btn-pdf-download" @click="download(invoice)">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px; display: inline-block" fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                      </svg>
                      <span class="ta-button-text">{{ $t("Download") }}</span>
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="15">
                    <p class="text-muted text-center py-3">
                      {{ $t(`You don't have data`) }}
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="ta-invoice-list-footer">
              <div class="ta-invoice-pagination-summary">
                {{
                  $t("Showing {start} to {finish} of {total} entries", {
                    start:
                      (invoices.pagination.page - 1) * invoices.pagination.limit +
                      1,
                    finish:
                      invoices.pagination.page * invoices.pagination.limit >
                      invoices.totalRows
                        ? invoices.totalRows
                        : invoices.pagination.page * invoices.pagination.limit,
                    total: invoices.totalRows,
                  })
                }}
              </div>
              <b-pagination
                v-model="page"
                :total-rows="invoices.totalRows"
                :per-page="invoices.pagination.limit"
                @input="updatePagination"
                class="ta-invoice-list-pagination"
              ></b-pagination>
            </div>
          </template>
        </div>
      </div>
      <b-modal
        :title="modal.title"
        v-model="modal.show"
        size="xl"
        static
        :ok-only="true"
        lazy
      >
        <iframe class="ta-base64-viewer-iframe" :src="modal.base64"></iframe>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import i18n from '@/i18n';
import {mapState, mapGetters, mapActions} from "vuex";
import {
  INVOICE_PAGINATION_PAGE,
  INVOICE_PAGINATION_LIMIT,
} from "../constants";
import logger from "../logger";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Invoices",
  components: {PageHeader},
  data: () => {
    return {
      limit: INVOICE_PAGINATION_LIMIT,
      page: INVOICE_PAGINATION_PAGE,
      filters: {
        q: null,
      },
      modal: {
        show: false,
        title: '',
        base64: ''
      },
      delayedRequest: null,
      query: null,
      normalizedQuery: null,
      loading: false,
    };
  },

  computed: {
    ...mapState(["perPageOptions", "invoices"]),
    ...mapGetters(["currentLocale"]),
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        // make actions with newVal.page
        if (newVal.page) this.page = newVal.page;
        if (newVal.limit) this.limit = newVal.limit;

        for (const key in this.filters) {
          if (newVal[key]) this.filters[key] = newVal[key];
        }

        this.getUpdatedInvoices();
      },
    },
  },

  methods: {
    ...mapActions(["getInvoices", "setInvoicePagination", "getInvoicePdf", "requestPaymentUrl"]),

    filtersChanged(filters) {
      this.filters = filters;
      this.updateRoute();
    },

    updatePagination() {
      this.updateRoute();
    },

    updateRoute() {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: this.page,
            limit: this.limit,
            ...this.filters,
          },
        })
        .catch((err) => {
          logger.error("Failed to update route for invoices", err);
        });
    },

    getUpdatedInvoices() {
      this.getInvoices({
        pagination: {page: this.page, limit: this.limit},
        filters: this.filters,
      });
    },
    async download(invoice) {
      const result = await this.getInvoicePdf({uuid: invoice.uuid});
      if (result) {
        const link = document.createElement('a');
        link.href = `data:application/pdf;name=${invoice.invoice_no}.pdf;base64,` + result;
        link.download = `${invoice.invoice_no}.pdf`;
        link.style.display = 'none';
        document.body.appendChild(link);
        console.log(link);
        link.click();
      }
    },
    async view(invoice) {
      const result = await this.getInvoicePdf({uuid: invoice.uuid});
      if (result) {
        this.modal.base64 = `data:application/pdf;name=${invoice.invoice_no}.pdf;base64,` + result;
        this.modal.title = `${invoice.invoice_no}.pdf`;
        this.modal.show = true;
      }
    },
    async getInvoiceUrl(invoiceUuid, providerName) {
      this.loading = true;
      this.requestPaymentUrl({invoiceUuid, providerName}).then((url) => {
        window.location.href = url;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style scoped lang="scss">
.ta-invoice-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ta-invoice-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-invoice-list {
  flex: 1;
}

.ta-invoice-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-uc-first {
  text-transform: capitalize;
}

.pagination.ta-invoice-list-pagination {
  margin-bottom: 0 !important;
}

.ta-base64-viewer-iframe {
  width: 100%;
  min-height: 600px;
}
</style>
