<template>
  <div class="ta-lot-countdown-timer d-inline-block" v-if="lot.status !== 'sold' && lot.status !== 'unsold'">
    <count-down-timer v-if="mainConfig.showCountdown && (lot.start_date || lot.auction && lot.auction.start_date)"
                      :start_date="lot.start_date || lot.auction.start_date"
                      :end_date="lot.end_date"
                      :label="label"
                      :paused="lot.auction.is_paused"/>
  </div>
  <div v-else class="ta-lot-countdown-sold-unsold">
    <span v-if="lot.status === 'sold'" class="badge badge-success ta-lot-status-sold">{{ $t('Sold') }}</span>
    <span v-else class="badge badge-secondary ta-lot-status-unsold">{{ $t('Unsold') }}</span>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CountDownTimer from "../../CountDownTimer";

export default {
  name: "LotCollectionInformation",
  components: {CountDownTimer},
  props: {
    lot: {
      required: true,
      type: Object
    },
    label: {
      type: [Boolean],
      default: true
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
