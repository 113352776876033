<template>
  <span class="ta-lot-increment">
    <label v-if="label === true" class="ta-lot-view-increment-label">{{ $t('Increment') }} &nbsp;</label>
    <label v-else-if="label"  class="ta-lot-view-increment-label">{{ label }}</label>
    <span class="ta-lot-increment-value">{{ this.getIncrementByCurrentBid(this.currentBidOrStartPrice) | asCurrency(lotObj.currency.code) }}</span>
  </span>
</template>

<script>
import {mapState} from "vuex";
import lotListItemBiddingMixin from "@/mixins/lotListItemBiddingMixin";

export default {
  name: "LotCurrentIncrement",
  mixins: [lotListItemBiddingMixin],
  props: {
    label: {
      type: [Boolean, String],
      default: true
    },
    lot: {
      required: false,
      type: [Object, Boolean],
      default: () => false
    },
  },
  computed: {
    ...mapState({lotVuex: state => state.lotObject.data}),
    lotObj() {
      return this.lot || this.lotVuex
    },
  },
  methods: {
    getLot() {
      return this.lotObj;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

  .ta-lot-increment {
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  .ta-lot-increment-value {
    color: $primary;
    font-weight: bold;
  }
</style>
