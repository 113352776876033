<script>

import templatable from "@/mixins/templatable";
import lotBiddingMixin from "@/mixins/lotBiddingMixin";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";
import StreamBidButton from "@/components/lot-view/sidebar/StreamBidButton.vue";
import BiddingLimit from "../lot-bidding/BiddingLimit.vue";
import WatchlistButton from "@/components/lot-view/sidebar/WatchlistButton.vue";
import BiddingActions from "@/components/configuration/lot-bidding/BiddingActions.vue";
import BiddingMaxBid from "@/components/configuration/lot-bidding/BiddingMaxBid.vue";
import LoginSignupButtons from "@/components/lot-view/sidebar/LoginSignupButtons.vue";
import BiddingAdditionalLinks from "@/components/configuration/lot-bidding/BiddingAdditionalLinks.vue";
import BiddingCommissionBidInput from "@/components/configuration/lot-bidding/BiddingCommissionBidInput.vue";
import BiddingLatestNotification from "@/components/configuration/lot-bidding/BiddingLatestNotification.vue";
import BiddingStartPrice from "@/components/configuration/lot-bidding/BiddingStartPrice.vue";
import BiddingHighestCommissionBid from "@/components/configuration/lot-bidding/BiddingHighestCommissionBid.vue";
import BiddingBuyNowButton from "@/components/configuration/lot-bidding/BiddingBuyNowButton.vue";
import BiddingCurrentPrice from "../lot-bidding/BiddingCurrentPrice";
import {mapMutations} from "vuex";
import LoginButton from "@/components/lot-view/sidebar/LoginButton.vue";
import SignupButton from "@/components/lot-view/sidebar/SignupButton.vue";
import LotHammerTaxRate from "@/components/configuration/lot-list-item/LotHammerTaxRate.vue";
import LotBuyersPremiumTaxRate from "@/components/configuration/lot-list-item/LotBuyersPremiumTaxRate.vue";
import LotCurrentIncrement from "@/components/configuration/lot-list-item/LotCurrentIncrement.vue";
import LotAuctionPremium from "@/components/configuration/lot-list-item/LotAuctionPremium.vue";
import auctionViewMixin from "@/mixins/auctionViewMixin";
import AuctionRegisterAsBidder from "@/views/AuctionViewPage/AuctionRegisterAsBidder.vue";
import AuctionTermsLink from "@/views/AuctionViewPage/AuctionTermsLink.vue";
import AuctionViewingInformationLink from "@/views/AuctionViewPage/AuctionViewingInformationLink.vue";
import AuctionCollectionInformationLink from "@/views/AuctionViewPage/AuctionCollectionInformationLink.vue";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: "LotViewBiddingLive",
  components: {
    StreamBidButton,
    BiddingLimit,
    BiddingCurrentPrice,
    WatchlistButton,
    BiddingActions,
    BiddingMaxBid,
    BiddingCommissionBidInput,
    BiddingHighestCommissionBid,
    LoginSignupButtons,
    LoginButton,
    SignupButton,
    BiddingBuyNowButton,
    BiddingAdditionalLinks,
    BiddingLatestNotification,
    BiddingStartPrice,
    LotCurrentIncrement,
    LotHammerTaxRate,
    LotBuyersPremiumTaxRate,
    LotAuctionPremium,
    AuctionRegisterAsBidder,
    AuctionTermsLink,
    AuctionViewingInformationLink,
    AuctionCollectionInformationLink
  },
  mixins: [auctionViewMixin, lotBiddingMixin, templatable('lotBiddingTemplateLive'), dynamicFieldsMixin],
  data() {
    return {
      newBidAmount: null,
      interval: null,
      template: `<div id="lotViewBidding" class="ta-lot-bidding">
                      [[template]]
                  </div>
    `
    }
  },
  methods: {
    ...mapMutations(['setLotBiddingStartedFinishedFlags']),

    updateBiddingFlags() {
      const currentTime = getCurrentServerTime()

      let started = this.lot.actual_start_date < currentTime;
      let finished = this.lot.actual_end_date < currentTime;
      // console.log(this.lotStartDate.toString(), this.lotEndDate.toString());
      if (this.lot.auction.status === 'completed') {
        finished = true;
      }

      this.setLotBiddingStartedFinishedFlags({started, finished})
    }
  },
  mounted() {
    this.newBidAmount = this.next;
    this.updateBiddingFlags();
    if (!this.biddingStarted || !this.biddingFinished) {
      this.interval = setInterval(() => {
        this.updateBiddingFlags();
        // if (this.biddingFinished) {
        //   clearInterval(this.interval);
        // }
      }, 1000);
    }
  },

  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss">
#artisioTimedAuctions {

  .ta-btn-watchlist {
    position: static !important;
  }
}
</style>

<style scoped lang="scss">
@import "../../../bootstrap/import";


.ta-lot-bidding-content {
  position: relative;
  background-color: $gray-100;
  padding: 1rem;
}

.ta-lot-bidding {
  position: relative;
}

.ta-lot-bidding-paused {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  >span {
    background-color: $gray-900;
    color: white;
    display: inline-block;
    padding: 0.25rem 0.75rem;
  }
}
</style>
