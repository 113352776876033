import { render, staticRenderFns } from "./BankAccountAddress3.vue?vue&type=template&id=ce7b36b0&scoped=true&"
import script from "./BankAccountAddress3.vue?vue&type=script&lang=js&"
export * from "./BankAccountAddress3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce7b36b0",
  null
  
)

export default component.exports