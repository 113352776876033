<template>
  <div class="ta-filters" :class="{ 'ta-filters-open': filtersMenuOpenMobile }">
    <div class="ta-filters-header">
      <h3 class="ta-filters-title m-0">{{ $t("Filters") }}</h3>
      <button
        class="ta-btn-icon ta-filter-close-btn"
        @click="$emit('menuClose')"
        style="margin-left: -0.75rem"
      >
        <svg
          style="width: 28px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <button
        v-if="filtersApplied"
        class="btn btn-sm btn-link btn-reset-filters"
        @click="clearFilters"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style="width: 18px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        {{ $t("Reset") }}
      </button>
    </div>
    <div class="ta-lot-filter-list">
      <lot-filter-list ref="lotFilterList"/>
    </div>
    <div ref="filtersFooter" class="ta-filters-footer">
      <div class="ta-footer-buttons">
        <button
          v-if="filtersApplied"
          class="btn btn-sm btn-secondary btn-reset-filters d-flex align-items-center"
          @click="clearFilters"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="btn-icon"
            style="width: 18px"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          {{ $t("Reset Filters") }}
        </button>
        <button
          v-if="!mainConfig.autoApplyFilters"
          class="btn btn-sm btn-primary btn-apply-filters d-flex align-items-center"
          @click="applyFilters"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="btn-icon" style="width: 16px" fill="none" viewBox="0 0 24 24"
               stroke="currentColor"
               stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"/>
          </svg>
          {{ $t("Apply Filters") }}
        </button>
      </div>
      <div class="ta-filter-footer-close-btn">
        <a href="javascript:void(0)" @click="$emit('menuClose')">{{ $t('Close') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {LOT_DEFAULT_STATUS, LOT_LIST_DEFAULT_PAGE} from "../../constants";
import {updateLotsRoute} from "../../helpers";
import LotFilterList from "../lot-filters/LotFilterList";

export default {
  name: "LotFilter",
  components: {
    LotFilterList,
  },

  data() {
    return {
      dynamic_fields: {},
      filtersFooterWidth: 280
    };
  },

  computed: {
    ...mapState(["lotQueryParams", "departments", "categories", "mainConfig", "filtersMenuOpenMobile"]),
    ...mapGetters(["currentLocale", "lotFilterDetails", "selectedDepartment", "filterableDynamicFields"]),

    filtersApplied() {
      for (const key in this.lotQueryParams) {
        if (key === "page" || key === "limit" || key === 'sort' || key === 'sort-by' || key === 'auction_uuid') {
          continue;
        }
        // If it is an empty array
        if (Array.isArray(this.lotQueryParams[key]) && !this.lotQueryParams[key].length || key === 'lot_status' && this.lotQueryParams[key] === LOT_DEFAULT_STATUS) {
          continue;
        }
        if ((Array.isArray(this.lotQueryParams[key]) && this.lotQueryParams[key].length) || this.lotQueryParams[key]) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    lotQueryParams() {
      this.updateFiltersFromVuex();
    },
  },

  methods: {
    ...mapActions(["getLots"]),
    ...mapMutations(["setLotFilters", "setDepartments", "setCategories", "setDynamicFields", 'setFiltersMenuOpenMobile']),

    filtersChanged() {
      this.setLotFilters({
        page: LOT_LIST_DEFAULT_PAGE,
        dynamicFields: this.dynamic_fields
      });
      updateLotsRoute();
    },

    clearFilters() {
      this.setFiltersMenuOpenMobile(false)
      Object.keys(this.lotQueryParams).forEach((field) => {
        if (field === "page" || field === "limit" || field === 'sort' || field === 'sort-by') {
          return;
        }
        if (Array.isArray(this.lotQueryParams[field])) {
          this.lotQueryParams[field] = [];
        } else {
          switch (field) {
            case "lot_status":
              this.lotQueryParams[field] = LOT_DEFAULT_STATUS;
              break;
            default:
              this.lotQueryParams[field] = null;
          }
        }
      });

      Object.keys(this.dynamic_fields).forEach((field) => {
        if (Array.isArray(this.dynamic_fields[field])) {
          this.dynamic_fields[field] = [];
        } else {
          this.dynamic_fields[field] = null;
        }
      });

      this.filtersChanged();
      this.setDepartments([])
      this.setCategories([])
      this.setDynamicFields([])
      this.$emit('clearFilters')
    },
    applyFilters() {
      updateLotsRoute();
      this.setFiltersMenuOpenMobile(false)
      this.$emit('applyFilters')
    },
    updateFiltersFromVuex() {
      const query = this.lotQueryParams;

      this.dynamic_fields = {};
      for (let key in query) {
        // If query parameter starts with `dynamic_fields`
        if (key.indexOf('dynamic_fields-') === 0) {
          this.dynamic_fields[key.replace('dynamic_fields-', '')] = query[key];
        }
      }
      this.dynamic_fields = {...this.dynamic_fields};
    },
  },
  mounted() {
    this.updateFiltersFromVuex();
    this.filtersFooterWidth = this.$refs.lotFilterList.$el.offsetWidth
  },
};
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";
@import "../../scss/mixins";

.ta-filters {
  margin-right: 1.5rem;
  width: 280px;
  min-width: 280px;
  overflow: hidden;
  //padding-bottom: 70px;
  display: flex;
  flex-direction: column;

  .ta-filters-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .ta-filters-footer {
    padding: 0.5rem;
    z-index: 100;
    margin-top: 1rem;

    .ta-footer-buttons {
      gap: 1rem;
      display: flex;
      align-items: center;
    }

    .btn-reset-filters,
    .btn-apply-filters {
      flex: 1;
      justify-content: center;
    }

    .btn-reset-filters {
      display: flex;
      align-items: center;
    }
  }

  .ta-lot-filter-list {
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbars();
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    padding: 1rem;
    top: 0;
    left: 100%;
    right: auto;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: $white;
    z-index: 2000;
    transition: all 0.2s ease-in-out;
    .ta-filters-header {
      text-align: right;
    }

    &.ta-filters-open {
      right: 0;
      left: 0;
      box-shadow: -50px 0 50px 20px rgba(0, 0, 0, 0.2);
    }
  }
}

#artisioTimedAuctions .ta-filters {
  .ta-filter-footer-close-btn {
    margin-top: 1rem;
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  #artisioTimedAuctions .ta-filters {
    .ta-filter-close-btn {
      display: none;
    }
  }
  .ta-filter-footer-close-btn {
    display: none;
  }
}
</style>

<style lang="scss">
@import "../../bootstrap/import";
@import "../../scss/mixins";

#artisioTimedAuctions {
  .ta-filters {
    @include media-breakpoint-up(lg) {
      .ta-filter-close-btn {
        display: none;
      }
    }
  }
}
</style>
