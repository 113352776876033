<template>
  <div>
    <div class="ta-buy-now-button">
<!--      <div class="ta-horizontal-or" v-if="!isAfterSale">-->
<!--        {{ $t('or') }}-->
<!--      </div>-->
      <div :id="`${idPrefix}buy-now-button-wrapper`" v-b-tooltip.hover>
        <button
          :id="`${idPrefix}buy-now-button`"
          :disabled="disabled"
          @click="onBuyNowButtonClick($event)"
          class="btn btn-primary btn-block d-flex justify-content-between align-items-center mt-2">
          {{ $t('Buy now') }}
          <span>
            {{ lot.buy_now_price | asCurrency(lot.currency.code) }}
          </span>
        </button>
      </div>
      <b-tooltip v-if="disabled" container="artisioTimedAuctions" :target="`${idPrefix}buy-now-button-wrapper`">
        <template v-if="!is_authorized">
          {{ $t('Please login to buy this item') }}
        </template>
        <template v-else-if="!lot.auction.is_bidder">
          {{ $t('You are not registered as bidder') }}
        </template>
        <template v-else-if="!biddingStarted">
          {{ $t('Lot is not open yet') }}
        </template>
        <template v-else-if="biddingFinished">
          {{ $t('Lot has ended') }}
        </template>
      </b-tooltip>
    </div>

    <!-- Buy now Confirmation -->
    <buy-now-confirmation :show.sync="showBuyNowConfirmation"
                          :target="`${idPrefix}buy-now-button`"
                          :bid-amount="parseFloat(lot.buy_now_price)"
                          :for-mobile="forMobile"
                          :lot="lot"
                          :container="`${idPrefix}lotViewBidding`"
                          @confirm="$emit('confirm', true)"/>
    <!--/ Buy now Confirmation -->
  </div>
</template>

<script>
import BuyNowConfirmation from "../BuyNowConfirmation";
import {mapState} from "vuex";
import {buyNowClick} from "@/triggers";
import lotBiddingMixin from "@/mixins/lotBiddingMixin";
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  name: "BuyNowButton",
  components: {BuyNowConfirmation},
  mixins: [lotBiddingMixin, lotHelperMixin],
  props: {
    disabled: Boolean,
    forMobile: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    showBuyNowConfirmation: false
  }),
  computed: {
    ...mapState(['is_authorized']),
    idPrefix() {
      return this.forMobile ? 'mobile_' : ''
    }
  },
  methods: {
    onBuyNowButtonClick() {
      buyNowClick(this.title)
      this.showBuyNowConfirmation = !this.showBuyNowConfirmation;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

#artisioTimedAuctions {
  .ta-buy-now-button {
    .btn {
      margin-top: 2rem;
      font-size: 1.25rem;

      > span {
        font-size: 1.5rem;
      }
    }
  }


  .ta-horizontal-or {
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: $gray-700;
    margin-top: 0;
    //font-size: $font-size-lg;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: 2px;
      height: 2px;
      width: calc((#{100%} - #{40px}) / 2);
      background-color: white;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}
</style>
