import axios from 'axios';
import Vue from "vue";
import i18n from "../i18n";
import Store from '../store/index';
import CONFIG from '../user-config'

let config = {
  baseURL: process.env.VUE_APP_API_URL,
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(config => {
  const auth_cookie = Vue.cookie.get(CONFIG.artisioCookieName);

  config.headers['ARTISIO-CLIENT-ID'] = CONFIG.clientId;
  config.headers['ARTISIO-LANGUAGE'] = Store.getters.currentLocale;
  if (CONFIG.branchUuid) {
    config.headers['ARTISIO-BRANCH-UUID'] = CONFIG.branchUuid;
  }

  if(auth_cookie) {
    config.headers.Authorization = `Encrypted-Token ${auth_cookie}`;
    try {
      config.headers['ARTISIO-TZ'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      console.log(e);
    }
  }
  return config;
});

axiosClient.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      switch (error.response.status) {
        case 401:
          Store.commit('logoutUser')
          break;
        case 404:
          // Store.commit('setError', {
          //   code: 404,
          //   message: error.response.data.message
          // })
          // TODO handle 404 errors
          // router.push({name: 'NotFound'})
          break;
        case 403:
          Store.commit('showNotification', {
            title: i18n.t('Error'),
            text: i18n.t("You don't have permission to perform this action"),
            variant: 'danger'
          });
          break;
      }
      return Promise.reject(error);

    }
);

export default axiosClient;

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axiosClient;
  }
});
