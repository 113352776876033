<template>
  <input-widget
    id="idSellAnItemQuantityInput"
    type="number"
    v-model="model"
    :rules="(required ? 'required' : '') + '|min_value:1'"
    :label="$t('Quantity')"
    :error="sellAnItemModal.errors.quantity"
    @change="onChange"
  />
</template>

<script>
import InputWidget from "@/components/core/InputWidget.vue";
import {mapMutations, mapState} from "vuex";
import baseInputMixin from "@/components/sell-an-item-modal/baseInputMixin";

export default {
  name: "QuantityInput",
  components: {InputWidget},
  mixins: [baseInputMixin('', 'quantity')],
  computed: {
    ...mapState(['sellAnItemModal'])
  },
  methods: {
    ...mapMutations(['setSellAnItemModalFieldInput', 'resetSellAnItemErrors']),
    onChange(val) {
      if (val)
        this.resetSellAnItemErrors('quantity')
      this.setSellAnItemModalFieldInput({field: 'quantity', value: val})
    }
  }
}
</script>

<style scoped>

</style>
