<template>
  <div v-if="locationInfo" class="ta-auction-location">
    <slot name="icon">
      <b-icon-geo-alt/>
    </slot>
    <b v-if="label" class="ta-auction-location-label">{{ label }}: </b>
    <div class="ta-auction-location-value" v-html="locationInfo"></div>
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionLocation",
  mixins: [auctionViewMixin],
  props: {
    label: {
      type: [Boolean, String],
      default() {
        return this.$t('Location')
      }
    },
  },
}
</script>

<style scoped>

</style>
