<template>
  <div @click="onClick" class="ta-auction-list-item-carousel">
    <vueper-slides v-if="images.length" class="no-shadow"
                   :visible-slides="4"
                   slide-multiple
                   :gap="2"
                   :slide-ratio="1 / 4"
                   :dragging-distance="200"
                   :breakpoints="{
                     800: { visibleSlides: 2, slideMultiple: 2 },
                     480: { visibleSlides: 1, slideMultiple: 1 },
                   }"
                   fixed-height="200px">
      <vueper-slide
        v-for="(slide, i) in images"
        :key="i"
        :image="slide.image"
        :link="slide.link">
      </vueper-slide>
    </vueper-slides>
    <router-link v-else :to="auctionViewRoute">
      <img src="https://cdn.artisio.co/no_image.jpg">
    </router-link>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import auctionViewRoute from "@/mixins/auctionHelperMixin.js";
import {auctionView} from "@/triggers";

export default {
  name: "AuctionCarousel",
  components: {VueperSlides, VueperSlide},
  mixins: [auctionViewRoute],
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
  computed: {
    images() {
      return this.auction.images.map(im => ({
        image: im.sm.url,
        link: '#' + this.auctionViewRoute
      }))
    }
  },
  methods: {
    onClick() {
      auctionView(this.auction)
    }
  }
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions {
  .ta-auction-list-item-carousel {
    width: 240px;
    height: 100%;

    > a {
      display: inline-block;
      width: 100%;
      height: 100%;

      > img {
        max-width: 100%;
      }
    }
  }
}
</style>
