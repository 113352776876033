<template>
  <div class="ta-auction-viewing-dates" v-if="auction.viewing_start_date && auction.viewing_end_date">
    <b v-if="label" class="ta-auction-viewing-dates-label">{{ label }}:</b>
    <span class="ta-auction-viewing-dates-value">{{ viewingDates }}</span>
  </div>
</template>

<script>

import auctionViewMixin from "@/mixins/auctionViewMixin";

export default {
  name: "AuctionViewingDates",
  mixins: [auctionViewMixin],
  props: {
    label: {
      type: [Boolean, String],
      default() {
        return this.$t('Viewing Dates')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
