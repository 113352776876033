<template>
  <a :href="'tel:' + lotObj.branch_phone"
    v-if="isCurrentLotExpiresSoon && lotObj.branch_phone && is_authorized && parseInt(settings.show_inquiry_button)"
    class="btn btn-primary btn-sm ta-lot-inquiry-button">
    {{ lotObj.branch_phone }}
  </a>
  <div v-else-if="!isCurrentLotExpiresSoon && parseInt(settings.show_inquiry_button)" :id="`send-inquiry-button-${lot.uuid}`" v-b-tooltip.hover
       class="ta-lot-inquiry-button">
    <b-btn
      variant="primary"
      size="sm"
      v-b-modal.lots_inquiry_modal
      :disabled="!is_authorized"
      @click="showInquiryModal"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           style="width: 16px" class="mr-2">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
      </svg>
      {{ $t('Send Inquiry') }}
    </b-btn>
    <b-tooltip v-if="!is_authorized" container="artisioTimedAuctions" :target="`send-inquiry-button-${lot.uuid}`">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>


</template>

<script>
import {mapGetters, mapState, mapMutations} from "vuex";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: "LotInquiryButton",
  props: {
    lot: {
      required: false,
      type: [Object, Boolean],
      default: () => false
    },
  },
  computed: {
    ...mapState({lotVuex: state => state.lotObject.data}),
    ...mapState(['mainConfig', 'lots', 'lotListViewType']),
    ...mapGetters(['is_authorized', 'settings']),
    lotObj() {
      return this.lot || this.lotVuex
    },
    isCurrentLotExpiresSoon() {
      const expiresDate = new Date(this.lotObj.end_date)
      const dateDifference = Math.floor((expiresDate.getTime() - getCurrentServerTime()) / (1000 * 60))
      return dateDifference < this.mainConfig.inquiryTimeAnchor
    }
  },
  data() {
    return {
      uuid: null,
      endDate: null,
    }
  },
  methods: {
    ...mapMutations(['showLotInquiryModal']),
    showInquiryModal() {
      this.showLotInquiryModal({
        lot: this.lotObj,
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ta-lot-inquiry-button {

}
</style>
<style lang="scss">
.ta-lot-list-item {
  .ta-lot-inquiry-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>
