<template>
  <div class="text-center d-flex justify-content-center">
    <div v-if="loading">
      <b-spinner style="width: 5rem; height: 5rem;" variant="info" class="mb-3"></b-spinner>
      <h4>{{ $t('Verifying Email') }}...</h4>
    </div>
    <FormAlert :success-message="successMessage" :error-message="errorMessage" v-else>
      <template #successMessageSlot>
        <LinkToLogin>{{ $t('Click here to login') }}</LinkToLogin>
      </template>
    </FormAlert>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import FormAlert from "../../components/core/FormAlert";
import LinkToLogin from "@/components/configuration/signup/LinkToLogin.vue";

export default {
  name: "VerifyEmail",
  components: {LinkToLogin, FormAlert},
  data() {
    return {
      loading: true,
      successMessage: '',
      errorMessage: ''
    }
  },
  methods: {
    ...mapActions(['verifyEmailAddress'])
  },
  beforeMount() {
    const token = this.$route.params.token
    this.verifyEmailAddress(token)
      .then((msg) => {
        this.loading = false;
        this.successMessage = msg;

      })
      .catch((msg) => {
        this.loading = false;
        this.errorMessage = msg;

      });
  }
}
</script>

<style scoped>

</style>
