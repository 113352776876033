<template>
  <div v-if="mainConfig.showAuctionType" class="ta-auction-view-type">
    <slot v-if="auction.type === 'live'" name="iconForLive">
      <b-icon-hammer/>
    </slot>
    <slot v-else name="iconForTimed">
      <b-icon-mouse/>
    </slot>
    <AuctionType :auction="auction"/>
  </div>
</template>

<script>
import auctionViewMixin from "@/mixins/auctionViewMixin";
import AuctionType from "@/views/AuctionListPage/list-item/AuctionType.vue"

export default {
  name: "AuctionViewType",
  components: {AuctionType},
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>

</style>
