<template>
  <div class="col-sm-12 col-md">
    <abstract-profile-text-input
      id="input-middle_name"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="required ? 'required' : ''"
    />
  </div>
</template>

<script>
import AbstractProfileTextInput from "../core/AbstractProfileTextInput";
import BaseInputMixin from "../../../../mixins/profileInputMixin";

export default {
  name: "MiddleName",
  components: {AbstractProfileTextInput},
  mixins: [BaseInputMixin('middle_name')],

  data() {
    return {
      defaultLabel: this.$t("Middle Name")
    }
  }
}
</script>

<style scoped>

</style>
