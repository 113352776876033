<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-bank_account_address_3"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="required ? 'required' : ''"
    />
  </div>
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseInputMixin from "@/mixins/profileInputMixin";

export default {
  name: "BankAccountAddress3",
  components: {InputWidget},
  mixins: [BaseInputMixin('bank_account.address_3')],

  data() {
    return {
      name: 'address_3',
      defaultLabel: this.$t("Address 3")
    }
  }
}
</script>

<style scoped>

</style>
