<template>
  <div v-if="auctionObj.is_paused" class="alert alert-warning ta-auction-pause" v-html="pauseMessage">
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AuctionPauseMessage",
  mixins: [],
  props: {
    label: {
      type: [Boolean, String],
      default: true
    },
    auction: {
      required: false,
      type: [Object, Boolean],
      default: () => false
    },
  },
  computed: {
    ...mapState({auctionVuex: state => state.auctionObject.data}),
    auctionObj() {
      return this.auction || this.auctionVuex
    },
    pauseMessage() {
      const pauseMessage = this.$options.filters.currentLocale(this.auctionObj.pause_message)
      if (pauseMessage) return pauseMessage;

      return this.$t(`Auction has been paused. You can't place bids until the auction is paused.`)
    }
  },
}
</script>

<style lang="scss" scoped>
.ta-auction-pause {
  width: 100%;
  text-align: center;
  > :last-child {
    margin-bottom: 0;
  }
}

</style>
