<template>
  <div v-if="lot.last_bid_amount" class="ta-lot-highest-commission-bid">
    <label class="ta-lot-highest-commission-bid-label">{{ $t('Highest Commission Bid') }}</label>
    <span class="ta-lot-highest-commission-bid-value">
      {{ lot.last_bid_amount | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BiddingHighestCommissionBid",
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style lang="scss" scoped>
  .ta-lot-highest-commission-bid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

<style>
#artisioTimedAuctions {
  .ta-lot-highest-commission-bid-label {
    margin-bottom: 0;
  }
}
</style>
