// provides functions to talk to the JSON api (paystation_ajax.php)
import axios from "@/services/axios";

function hex(x) {
  return ("0" + parseInt(x).toString(16)).slice(-2);
}

function rgb2hex(rgb) {
  if (!rgb || typeof rgb != 'string') {
    return '';
  }
  if (rgb.startsWith('#')) {
    return rgb.substr(1, 6);
  }
  rgb = rgb.match(/^\w*\((\d+),\s?(\d+),\s?(\d+)(.)*?\)$/);
  return hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
}

function tryToFindABackgroundColour(element) {
  if (!element) {
    return null;
  }
  let colour = '000000';
  for (let i = 0; element && i < 10 && colour == '000000'; i++) {
    colour = rgb2hex(window.getComputedStyle(element, null).backgroundColor);
    element = element.parentElement;
  }
  if (colour == '000000') {
    colour = rgb2hex(window.getComputedStyle(document.body, null).backgroundColor);
  }
  return colour == '000000' ? null : colour;
}

const Paystation = {
  _ajaxUrl: '/website/auth/paystation/ajax', // See createTransaction() and getTransaction() to change how fields are posted
  _pollingInterval: null,
  // ajax e.g. post('your/url', 'transactionId=123&userid=123', function (err, data) { /*check for error and handle data*/ });
  post: function (url, postData, callback) {
    axios.post(url, postData)
      .then(response => {
        callback(null, response.data)
      })
      .catch(err => {
        callback(new Error("Request failed. " + err.response.status));
      })
  },

  // callback(error, newTransaction)
  // useful fields on trasaction:
  // trasaction.digitalOrderUrl = url for the iframe
  // trasaction.transactionId = id used for future lookups to see if the transaction has been completed
  createTransaction: function (amount, callback) {
    this.post(
      this._ajaxUrl,
      {
        'method': 'make_transaction',
        'amount': amount
      },
      function (err, txn) {
        if (err || txn.hasError || txn.errorCode > 0 || !txn.digitalOrderUrl) {
          return callback(new Error("Error occurred while making transaction: " + (err || txn.errorMessage)));
        }

        callback(null, txn);
      }
    )
  },

  // callback(error, transaction)
  // transaction.hasError is for all errors regardless if they come from paystation or us, which could happen before the transaction completes.
  // transaction.errorCode is a paystation response which is set after a transaction is complete. A negative error code means no error code has been returned.
  getTransaction: function (transactionId, callback) {
    this.post(
      this._ajaxUrl,
      {
        'method': 'get_transaction',
        'transaction_id': transactionId
      },
      function (err, transaction) {
        if (err) {
          return callback(err);
        }

        callback(null, transaction);
      }
    );
  },

  // Since we don't know when the transaction is complete we can use this to constantly check its status.
  pollTransactionDetails: function (transactionId, callback) {
    this.stopPolling();

    this._pollingInterval = window.setInterval(() => {
      this.getTransaction(transactionId, callback);
    }, 1337);
  },

  // This is only designed to poll for one transaction at a time. It will need to be reworked if you want the user to be making multiple payments on the same screen.
  stopPolling: function () {
    if (this._pollingInterval) {
      window.clearInterval(this._pollingInterval);
    }
  },

  createPaymentFrame: function (parentElement, paymentURL, onLoad, changeBackgroundColour) {
    let paymentFrame = document.createElement('iframe');
    paymentFrame.style.width = '100%'
    paymentFrame.style.height = '480px'
    paymentFrame.style.border = 'none'
    if (changeBackgroundColour) {
      let colour = tryToFindABackgroundColour(parentElement);
      if (colour) {
        paymentURL += '&b=' + colour;
      }
    }
    paymentFrame.src = paymentURL;
    parentElement.appendChild(paymentFrame);
    this.bindFrameLoadEvent(paymentFrame, onLoad);
    return paymentFrame;
  },

  closePaymentFrame: function (paymentFrame) {
    if (paymentFrame) {
      paymentFrame.parentNode.removeChild(paymentFrame);
    }
  },

  // This is used to detect when the frame is redirected. If you are not using redirects then you wont need to use this.
  bindFrameLoadEvent: function (frame, onLoad) {
    if (navigator.userAgent.indexOf("MSIE") > -1 && !window.opera) {
      frame.addEventListener('readystatechange', function () {
        if (frame.readyState == "complete") {
          onLoad(frame);
        }
      });
    } else {
      frame.addEventListener('load', function () {
        onLoad(frame);
      });
    }
  },

  // Most browsers do not allow manipulating the contents of an iframe if it is in a different domain.
  // This can be used to test if the client has been redirected back to your website after making the payment in paystation.
  canAccessIFrame: function (iframe) {
    let html = null;
    try {
      let doc = iframe.contentDocument || iframe.contentWindow.document;
      html = doc.body.innerHTML;
    } catch (err) {
      console.error(err);
    }
    return (html !== null);
  },
};

export default Paystation
