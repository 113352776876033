<template>
  <div v-if="mainConfig.enableNotifyMeOnLotStart && !started" :id="`subscribe-to-lot-start-${lot.uuid}`"
       v-b-tooltip.hover class="ta-subscribe-to-lot-start">
    <b-button
      :disabled="disabled"
      @click="onBtnClick($event)"
      :variant="isSubscribedToLotStart() ? 'primary' : 'outline-primary'">
      <span v-if="!isSubscribedToLotStart()" v-html="subscribeText"></span>
      <span v-else v-html="unsubscribeText"></span>
    </b-button>
    <b-tooltip v-if="!is_authorized" container="artisioTimedAuctions" :target="`subscribe-to-lot-start-${lot.uuid}`">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment/moment";
import {getCurrentServerTime, openLoginAndPreserveUrl} from "@/helpers";

export default {
  name: "LotNotifyStartButton",
  props: {
    lot: {
      type: Object,
      required: true
    },
    subscribeText: {
      type: String,
      default() {
        return this.$t(`Notify me`)
      }
    },
    unsubscribeText: {
      type: String,
      default() {
        return this.$t(`Don't notify`)
      }
    }
  },
  data: () => ({
    disabled: false,
    interval: null,
    started: true
  }),
  computed: {
    ...mapState(['is_authorized', 'mainConfig', 'timeDiff']),

    startOfEventTime() {
      let startEventTime =
        typeof this.lot.start_date === "string"
          ? moment(this.lot.start_date).toDate().getTime()
          : this.lot.start_date.getTime();
      return moment(startEventTime).utc();
    },
  },
  methods: {
    ...mapActions(['toggleLotStartSubscription', 'show401Notification']),
    isSubscribedToLotStart() {
      return (this.lot.subscriptions || []).includes('start')
    },
    async onBtnClick(ev) {
      if (!this.is_authorized) {
        openLoginAndPreserveUrl(ev);
        return;
      }
      this.disabled = true;
      try {
        await this.toggleLotStartSubscription({uuid: this.lot.uuid})
      } catch (e) {
        console.error(e)
      }
      this.disabled = false;
    }
  },
  mounted() {
    if (!this.lot.start_date || !this.lot.end_date) {
      return;
    }
    const interval = 1000;
    this.interval = setInterval(() => {
      const currentTime = getCurrentServerTime()
      this.started = this.startOfEventTime <= currentTime;
      if (this.started) {
        clearInterval(this.interval);
      }
    }, interval);
  },

  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss" scoped>
.ta-subscribe-to-lot-start {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
</style>
