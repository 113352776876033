<template>
  <button class="ta-filter-menu-btn" @click="filtersMenuClick">
    <span v-if="label" class="ta-filter-menu-btn-label">{{label}}</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         style="width: 24px;">
      <path stroke-linecap="round" stroke-linejoin="round"
            d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"/>
    </svg>

  </button>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {createOverlay} from "@/helpers";

export default {
  name: "LotListFilterMobileButton",
  props: {
    label: {
      type: String,
      default() {
        return  this.$t('Filters')
      }
    }
  },
  methods: {
    ...mapMutations(['setFiltersMenuOpenMobile']),
    filtersMenuClick() {
      createOverlay('ta-filters-overlay', 'artisio-webapp-has-filters-backdrop')
      this.setFiltersMenuOpenMobile(true);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";
@import "../../../scss/mixins";

@include media-breakpoint-up(lg) {
  #artisioTimedAuctions {
    .ta-filter-menu-btn {
      display: none;
    }
  }
}
.ta-filter-menu-btn-label {
  display: none;
}
</style>
