<template>
  <div class="col-sm-12 col-md">
    <abstract-profile-text-input
      :id="`input-shipping_address_zip_code`"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="required ? 'required' : ''"
    />
  </div>
</template>

<script>
import AbstractProfileTextInput from "../core/AbstractProfileTextInput";
import BaseInputMixin from "@/mixins/profileInputMixin";

export default {
  name: "ShippingZipCode",
  components: {AbstractProfileTextInput},
  mixins: [BaseInputMixin('shipping_address.zip_code')],
  props: {
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: 'shipping_address.zip_code',
      defaultLabel: this.$t("Zip Code")
    }
  }
}
</script>

<style scoped>

</style>
