<template>
  <div class="ta-lots-and-filters">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LotListAndFilters"
}
</script>


<style lang="scss">
@import "../bootstrap/import.scss";
#artisioTimedAuctions {

  .ta-lots-and-filters {
    display: flex;
    height: 100%;
    overflow: hidden;
  }

  .ta-lot-section {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }


  .ta-lot-search-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .ta-lot-list-header-top {
    display: flex;
    align-items: center;
  }

  .ta-lot-list-header,
  .ta-lot-list-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-per-page-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .ta-lot-list-footer {
    margin-top: 1rem;
  }

  .btn-switch-view {
    outline: none;

    &:hover,
    &:focus {
      box-shadow: none;
      border: 1px solid gray
    }
  }

  @include media-breakpoint-down(xs) {
    .ta-lot-list {
      column-gap: 1rem;
      row-gap: 1rem;
    }

    .ta-lot-list-header-top,
    .ta-lot-list-header {
      flex-direction: column;
      align-items: stretch;
    }
  }
}

</style>


