<template>
  <span  v-if="mainConfig.showAuctionType" class="ta-auction-type">
    {{ auction.type | ucFirst }}
  </span>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AuctionType",
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style lang="scss" scoped>

</style>
