<template>
  <div class="ta-lot-view-images" v-if="images.carousel.length > 0">
    <carousel :lot="lot" :images="images.carousel" @showGallery="openGallery"/>
    <GallerySlideshow2 :images="images.gallery" :index="gallery_idx" @close="closeGallery"/>
<!--    <GallerySlideshow :images="images.gallery" :index="gallery_idx" @close="closeGallery"/>-->
  </div>
  <div v-else class="ta-lot-view-images">
    <img class="d-block img-fluid mx-auto"
         src="https://cdn.artisio.co/no_image.jpg">
  </div>
</template>

<script>
import Carousel from "../../Carousel";
import {mapState} from "vuex";
import GallerySlideshow2 from "@/plugins/vue-gallery-slideshow/GallerySlideshow2.vue";

export default {
  name: "LotViewImages",
  components: {GallerySlideshow2, Carousel},
  data: () => ({
    gallery_idx: null
  }),
  computed: {
    ...mapState({
      lot: state => state.lotObject.data
    }),
    images() {
      const lot_images = this.lot.images || [];

      let carousel = [];
      let gallery = [];

      let id = 0;
      for (const image of lot_images) {
        // If image is broken we need to skip that image
        if (!image.original) {
          continue;
        }
        carousel.push({
          id: ++id,
          original: image.original.url,
          big: (image.lg || image.original).url,
          small: (image.sm || image.original).url,
          thumb: (image.thumb || image.original).url
        });
        gallery.push(image.original.url);
      }

      return {
        carousel,
        gallery
      };
    },
  },
  methods: {
    openGallery(idx) {
      console.log("Open gallery");
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
      document.body.classList.add('artisio-webapp-image-on-full-screen')
      this.gallery_idx = idx;
    },
    closeGallery() {
      console.log("closed");
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
      document.body.classList.remove('artisio-webapp-image-on-full-screen')
      this.gallery_idx = null
    }
  }
}
</script>

<style scoped>

</style>
