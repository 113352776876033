<template>
  <form ref="maxBidForm" @submit.stop.prevent="$emit('submit')">

    <tax-rate-and-premiums :lot="lot"/>

    <template v-if="mainConfig.showBidAmountsInConfirmation">
      <BidAmount :lot="lot" :bid-amount="parseFloat(value || 0)"/>
      <CalculatedPremium :lot="lot" :bid-amount="parseFloat(value || 0)"/>
      <BidTotalAmount :lot="lot" :bid-amount="parseFloat(value || 0)"/>
    </template>
    <hr>

    <b-form-group
        label-for="max-bid-amount"
        :invalid-feedback="errorMessage"
        :state="maxBidState"
        class="ta-lot-bidding-input"
    >
      <b-input-group :prepend="lot.currency.symbol">
        <b-form-input id="max-bid-amount"
                      type="number"
                      :state="maxBidState"
                      :value="value"
                      @input="onInputChange"
                      :placeholder="$t('Your Max Bid')"
                      required
        />
      </b-input-group>
    </b-form-group>
    <upcoming-amount-popover
        target="max-bid-amount"
        :state-object="upcomingMaxBidPopover"
        :text="$t('Current Max bid amount is invalid. Please submit one of the following amounts') + ':'"
        :currency-code="lot.currency.code"
        :as-popover="false"
        @hidePopover="hideUpcomingAmountPopover('upcomingMaxBidPopover')"
        @setNewAmount="saveUpcomingMaxBidAmount"
    />
  </form>
</template>
<script>
import BidAmount from "@/components/lot-view/BidAmount.vue"
import BidTotalAmount from "@/components/lot-view/BidTotalAmount.vue"
import CalculatedPremium from "@/components/lot-view/CalculatedPremium.vue"
import TaxRateAndPremiums from "@/components/TaxRateAndPremiums"
import UpcomingAmountPopover from "../../lot-view/UpcomingAmountPopover"
import {mapMutations, mapState} from "vuex";
import logger from "@/logger";

export default {
  name: 'MaxBidForm',
  components: {BidAmount, BidTotalAmount, CalculatedPremium, TaxRateAndPremiums, UpcomingAmountPopover},
  mixins: [],
  props: {
    lot: {
      required: true,
      type: Object
    },
    value: {
      type: [String, Number],
      required: false
    },
    errorMessage: {},
    maxBidState: {},
    saveUpcomingMaxBidAmount: {}
  },
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      upcomingMaxBidPopover: state => state.lotObject.upcomingMaxBidPopover,
    }),
  },
  methods: {
    ...mapMutations(['hideUpcomingAmountPopover']),
    onInputChange(value) {
      this.$emit('input', value)
      this.$emit('input', value)
    }
  }
}
</script>
