import {mapGetters} from "vuex";
import logger from "@/logger";

export default {
  computed: {
    ...mapGetters(['currentLocale', 'fallbackLocale',]),
    dynamicFieldValues() {
      if (!this.lot.dynamic_fields) return '';

      return this.lot.dynamic_fields[this.currentLocale] || this.lot.dynamic_fields[this.fallbackLocale]
    },
    dynamicFields() {
      let entries = Object.entries(this.lot.category.dynamic_fields)
        .map(([key, val]) => [key, val.label]);
      return Object.fromEntries(entries)
    }
  },
  methods: {
    getFormattedValueForDynamicField(field, value) {
      try {
        const categoryDynamicFields = this.lot?.category?.dynamic_fields
        if (!categoryDynamicFields) return value;

        const fieldObj = categoryDynamicFields[field];
        if (fieldObj && fieldObj.field_class_name === 'ChoiceField') {
          const choice = fieldObj?.rules?.choices.find(choice => choice.value === value)
          if (choice) return choice.key
        }
      } catch(e) {
        logger.error(`Error in getValueForDynamicField: ${e}`)
      }

      return value
    }
  }
}
